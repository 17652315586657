import Coordinate from "../Coordinates/Coordinate";
import Cutout from "../Main/Cutout";
import Cache from "../Util/Cache";
import Container from "../Main/Container";
import Projection, {ProjectionReactiveProps} from "./Projection";
import {GridSpec} from "../Main/Grid";
import {Serialization} from "../Main/Serializer";
import {throttledParallelEachPromise} from "../Util/functions";
import OlMip from "./OlMip";
import CoordinateConverter from "../Util/CoordinateConverter";
import ErrorSignal from "../Util/ErrorSignal";
import OlTileLoader from "../Util/OlTileLoader";

type _OlProjectionReactiveProps = {
    type: 'OlProjection',
    zoom: number|null,
}
export type OlProjectionReactiveProps = ProjectionReactiveProps & _OlProjectionReactiveProps;

export default class OlProjection<C extends Coordinate> extends Projection<C, OlMip> {

    reactiveProps: OlProjectionReactiveProps;

    private zoom: number|null = null;

    constructor(olMipName: string, scale: number = null) {
        super(Container.olMip(olMipName), scale, <_OlProjectionReactiveProps>{
            type: 'OlProjection',
            zoom: null,
        });
    }

    initialize(): Promise<void> {
        return Promise.resolve().then(() => {
            if(this.scale === null && this.zoom === null) {
                this.reactiveProps.scale = this.scale = 25000;
            }

            if(this.zoom === null) {
                this.reactiveProps.zoom = this.zoom = this.mapImageProvider.getZoomClosestToScale(this.coordinateSystem, this.scale);
            }

            if(this.scale === null) {
                this.reactiveProps.scale = this.scale = this.mapImageProvider.getScaleClosestToZoom(this.coordinateSystem, this.zoom);
            }
        });
    }

    clone(): OlProjection<C> {
        const projection = new OlProjection<C>(
            this.mapImageProvider.name,
            this.getScale(),
        );
        projection.coordinateSystem = this.coordinateSystem;
        return projection;
    }

    serialize(): Serialization {
        return {
            type: 'openlayers',
            mip: this.mapImageProvider.name,
            csCode: this.coordinateSystem.code,
            scale: this.scale,
            zoom: this.zoom,
        };
    }

    static unserialize(serialized: Serialization): OlProjection<Coordinate> {
        const projection = new OlProjection(serialized.mip, serialized.scale);
        projection.coordinateSystem = CoordinateConverter.getCoordinateSystem(serialized.csCode);
        if(serialized.zoom) {
            projection.setZoom(serialized.zoom);
        }
        return projection;
    }

    attach(cutout: Cutout<any, C, any>) {
        super.attach(cutout);

        // Preload capabilities upon attaching
        this.initialize();
    }

    getMipDrawnGrid(): GridSpec | null {
        return null;
    }

    getZoom(): number {
        return this.zoom;
    }

    setZoom(newZoom: number) {
        this.reactiveProps.zoom = this.zoom = newZoom;
    }

    getDpi(): number {
        return this.mapImageProvider.computeDpi(this.coordinateSystem, this.getScale(), this.getZoom());
    }

    protected fetchForProject(
        cache: Cache,
        progressCallback: ((evt) => void)|null,
        minX: number,
        maxX: number,
        minY: number,
        maxY: number,
        callback: (img: HTMLImageElement, coordinate: C, metrics: {pxPerUnit: number, paperMmPerTile: number}) => void
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // real mm: mm in physical world
            // paper mm: mm on paper map
            // render: one OpenLayers map render
            // px: pixels in OpenLayers map render
            // unit: unit of measurement of projection coordinate system (e.g., meters)
            const scale = this.getScale();
            const realMmPerPaperMm = scale;

            const pxDimensionPerTile = 256;

            const realMmPerUnit = 1000;
            const pxPerRender = OlMip.mapPixelWidth;
            const paperMmPerUnit = realMmPerUnit / realMmPerPaperMm;

            const pxPerUnit = this.mapImageProvider.getBasePixelsPerUnit(this.coordinateSystem) * (2 ** this.getZoom());

            const unitsPerRender = pxPerRender / pxPerUnit;
            const paperMmPerRender = unitsPerRender * paperMmPerUnit;

            minX = Math.floor(minX/unitsPerRender)*unitsPerRender;
            maxX = Math.ceil(maxX/unitsPerRender)*unitsPerRender;
            minY = Math.floor(minY/unitsPerRender)*unitsPerRender;
            maxY = Math.ceil(maxY/unitsPerRender)*unitsPerRender;

            let done = 0;
            const totalRenders = Math.floor((maxX - minX) / unitsPerRender) * Math.floor((maxY - minY) / unitsPerRender);
            const self = this;

            const totalTilesApprox = totalRenders * (OlMip.mapPixelWidth / pxDimensionPerTile) ** 2;
            if(totalTilesApprox > 200) {
                if(!confirm('De te downloaden kaart is van een groot formaat. Download niet onnodig grote kaarten, dit kan een hoge belasting geven op de bronservers. Wil je doorgaan?')) {
                    reject();
                    return;
                }
            }

            const tileLoader = this.getTileLoader(cache);

            return throttledParallelEachPromise(1, function*() {

                for(let x=minX; x<maxX; x+= unitsPerRender) {
                    for(let y=minY; y<maxY; y+= unitsPerRender) {
                        const imagePromise: Promise<HTMLImageElement> = self.downloadPrintImage(
                            cache,
                            tileLoader,
                            [
                                self.coordinateSystem.make(x, y+unitsPerRender),
                                self.coordinateSystem.make(x+unitsPerRender, y+unitsPerRender),
                                self.coordinateSystem.make(x+unitsPerRender, y),
                                self.coordinateSystem.make(x, y),
                            ]
                        );

                        yield imagePromise.then((img) => {
                            callback(img, self.coordinateSystem.make(x, y+unitsPerRender), {
                                pxPerUnit,
                                paperMmPerTile: paperMmPerRender,
                            });

                            done++;
                            if(progressCallback) {
                                progressCallback({
                                    done: done,
                                    total: totalRenders,
                                })
                            }
                        });
                    }
                }

            }).then(resolve, reject);

        });
    }

    private downloadPrintImage(cache: Cache, tileLoader: OlTileLoader, coords: C[]): Promise<HTMLImageElement> {
        const center = [
            (coords[1].getX() + coords[3].getX()) / 2,
            (coords[1].getY() + coords[3].getY()) / 2,
        ];

        const cacheKey = [
            'DATA_URL',
            this.mapImageProvider.name,
            this.coordinateSystem.name,
            this.scale,
            this.zoom,
            center[0],
            center[1],
        ].join('_');

        return cache.fetch(cacheKey, () => {
            return this.renderMap(center, tileLoader);
        }).then((result) => {
            return new Promise((resolve, reject) => {
                const img = document.createElement('img');
                img.src = result;
                img.onload = function () {
                    resolve(img);
                };
            });
        });
    }

    private renderMap(center: number[], tileLoader: OlTileLoader): Promise<string> {
        // https://openlayers.org/en/latest/examples/export-map.html

        return new Promise((resolve, reject) => {
            const map = this.mapImageProvider.getOlMap(this.coordinateSystem, this.getZoom(), center, tileLoader);

            let finalized = false;

            map.once('error', (e) => {
                finalized = true;
                reject(e);
            });

            map.once('rendercomplete', () => {
                const mapCanvas = document.createElement('canvas');
                const size = map.getSize();
                mapCanvas.width = size[0];
                mapCanvas.height = size[1];
                const mapContext = mapCanvas.getContext('2d');
                Array.prototype.forEach.call(
                    map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
                    function (canvas) {
                        if (canvas.width > 0) {
                            const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
                            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

                            const backgroundColor = canvas.parentNode.style.backgroundColor;
                            if (backgroundColor) {
                                mapContext.fillStyle = backgroundColor;
                                mapContext.fillRect(0, 0, canvas.width, canvas.height);
                            }

                            let matrix;
                            const transform = canvas.style.transform;
                            if (transform) {
                                // Get the transform parameters from the style's transform matrix
                                matrix = transform
                                    .match(/^matrix\(([^\(]*)\)$/)[1]
                                    .split(',')
                                    .map(Number);
                            } else {
                                matrix = [
                                    parseFloat(canvas.style.width) / canvas.width,
                                    0,
                                    0,
                                    parseFloat(canvas.style.height) / canvas.height,
                                    0,
                                    0,
                                ];
                            }
                            // Apply the transform to the export map context
                            CanvasRenderingContext2D.prototype.setTransform.apply(
                                mapContext,
                                matrix
                            );
                            mapContext.drawImage(canvas, 0, 0);
                        }
                    }
                );
                mapContext.globalAlpha = 1;

                this.mapImageProvider.disposeOlMap(map);

                finalized = true;
                resolve(mapCanvas.toDataURL());
            });
            map.renderSync();

            // When a tile error occurs, neither rendercomplete nor error will fire
            let checkFn = function() {
                if (!finalized) {
                    if (tileLoader.hasTileError()) {
                        reject();
                    } else {
                        setTimeout(checkFn, 500);
                    }
                }
            };
            setTimeout(checkFn);
        });
    }

    private getTileLoader(cache: Cache): OlTileLoader {
        // https://openlayers.org/en/latest/apidoc/module-ol_Tile.html

        let counter = 0;

        const tileLoader = new OlTileLoader(cache);
        tileLoader.setBeforeFetch(() => {
            const hardTileLimit = this.mapImageProvider.getHardTileLimit();
            if (counter === -1) {
                throw new ErrorSignal('DOWNLOAD_LIMIT_REACHED');
            } else if (hardTileLimit && ++counter > hardTileLimit) {
                alert('Deze download overschrijdt de download-limiet van deze kaartbron');
                counter = -1;
                throw new ErrorSignal('DOWNLOAD_LIMIT_REACHED');
            }
        });

        return tileLoader;
    }
}
