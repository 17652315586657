import Coordinate from "../Coordinates/Coordinate";
import Paper, {millimeter} from "../Util/Paper";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import {interpolatePolygonEdges, Point, polygonsOverlap, toTurfPolygon} from "../Util/Math";
import OLConvertibleCoordinate from "../Coordinates/OLConvertibleCoordinate";
import Map from "./Map";
import $ from "jquery";
import OLConvertibleCoordinateSystem from "../Coordinates/OLConvertibleCoordinateSystem";
import Projection, {ProjectionReactiveProps} from "../Projection/Projection";
import UserInterface from "./UserInterface";
import CoordinateConverter from "../Util/CoordinateConverter";
import Grid, {GridReactiveProps, GridSpec} from "./Grid";
import MoveCutoutAction from "../ActionHistory/MoveCutoutAction";
import Printer, {JsPdfGenerator} from "./Printer";
import MapImageProvider from "../Projection/MapImageProvider";
import {Serialization} from "./Serializer";
import Container from "./Container";
import WmsProjection from "../Projection/WmsProjection";
import WmtsProjection from "../Projection/WmtsProjection";
import AbstractCutout, {CutoutOptions, FlexagonOptions} from "./AbstractCutout";
import CutoutTemplate from "./CutoutTemplate";
import intersect from '@turf/intersect';
import centroid from '@turf/centroid';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {featureCollection} from '@turf/helpers';
import UserError from "../Util/UserError";
import {copyObject, isMobile, padLeadingZeros, presentDownloadRaw, unreactive} from "../Util/functions";
import {Polygon, MultiLineString} from "ol/geom";
import {Collection, Feature, MapBrowserEvent} from "ol";
import { Translate} from "ol/interaction";
import {altKeyOnly, platformModifierKeyOnly} from 'ol/events/condition';
import {fromLonLat} from "ol/proj";
import {asArray} from "ol/color";
import {Fill, Stroke, Style, Text} from "ol/style";
import OlProjection from "../Projection/OlProjection";
import EmptyProjection from "../Projection/EmptyProjection";
import OlVectorProjection from "../Projection/OlVectorProjection";
import {reactive} from "vue";
import LuchtfotoProjection from "../Projection/Specialized/LuchtfotoProjection";
import HexaTetraFlexagon from "../Util/Flexagon/HexaTetraFlexagon";

type Color = string;

export type CutoutReactiveProps = {
    type: 'cutout',
    id: number,
    name: string,
    color: Color,
    visibleOnMap: boolean,
    quickEditingName: boolean,
    options: CutoutOptions,
    paperName: string,
    projectionRP: ProjectionReactiveProps,
    gridRP: GridReactiveProps,
};

export default class Cutout<
    WorkspaceCoordinate extends Coordinate & OLConvertibleCoordinate,
    ProjectionCoordinate extends Coordinate,
    WorkspaceCoordinateSystem extends CoordinateSystem<WorkspaceCoordinate> & OLConvertibleCoordinateSystem<WorkspaceCoordinate>
    > extends AbstractCutout<WorkspaceCoordinate, ProjectionCoordinate, WorkspaceCoordinateSystem> {

    reactiveProps: CutoutReactiveProps;

    color: Color;

    mapPolygonWorkspace: WorkspaceCoordinate[];
    mapPolygonProjection: ProjectionCoordinate[];

    openlayersPolygonFeature: Feature<any>;
    openlayersPolygonTranslateInteraction: Translate;
    visibleOnMap: boolean = false;

    static readonly pointsOnEdge = 5;

    isMouseOver: boolean = false;
    private static overallZIndex = 1;
    private zIndex = null;

    constructor(
        readonly userInterface: UserInterface,
        paper: Paper,
        anchorWorkspace: WorkspaceCoordinate,
        workspaceCoordinateSystem: WorkspaceCoordinateSystem,
        projection: Projection<ProjectionCoordinate, MapImageProvider>,
        grid: Grid<Coordinate> = null
    ) {
        super(paper, anchorWorkspace, workspaceCoordinateSystem, projection, grid);

        this.options.advanced_settings_mode = Container.getDefaultAdvancedSettingsMode();

        this.projection.attach(this);
        this.grid.attach(this);

        this.reactiveProps = <CutoutReactiveProps>reactive({
            type: 'cutout',
            id: this.id,
            name: this.name,
            color: this.color,
            visibleOnMap: this.visibleOnMap,
            quickEditingName: false,
            options: this.options,
            paperName: this.paper.name,
            projectionRP: this.projection.reactiveProps,
            gridRP: this.grid.reactiveProps,
        });
    }

    setName(name: string) {
        this.name = name;
        this.reactiveProps.name = name;
        this.openlayersPolygonFeature?.changed();
    }

    setProjection(projection: Projection<ProjectionCoordinate, MapImageProvider>) {
        super.setProjection(projection);
        this.reactiveProps.projectionRP = projection.reactiveProps;
        this.projection.attach(this);
        this.updateMap();
    }

    setGrid(grid: Grid<Coordinate>): void {
        super.setGrid(grid);
        this.reactiveProps.gridRP = grid.reactiveProps;
        this.grid.attach(this);
    }

    setPaper(paper: Paper) {
        this.paper = paper;
        this.reactiveProps.paperName = paper.name;
        this.updateMap();
    }

    setOption(key: keyof CutoutOptions, value) {
        if (key === 'variant' && value === 'flexagon' && this.options.flexagon_options === null) {
            this.options.flexagon_options = copyObject(Cutout.defaultFlexagonOptions);
        }

        this.options[key] = value;

        this.updateReactiveProps();
    }

    setFlexagonOption(key: keyof FlexagonOptions, value) {
        if (this.options.flexagon_options === null) {
            throw new Error('(setFlexagonOption): No flexagon options present');
        }

        this.options.flexagon_options[key] = value;

        this.updateReactiveProps();
    }

    resetAdvancedSettings(): Partial<this> {
        const advancedSettings: Partial<this> = {};

        advancedSettings.options = copyObject(this.options);
        this.options = copyObject(Cutout.defaultCutoutOptions);
        this.options.draw_routes = this.options.draw_route_intermediates = this.options.draw_locations = advancedSettings.options.draw_routes && advancedSettings.options.draw_route_intermediates && advancedSettings.options.draw_locations;

        advancedSettings.projection = this.projection.resetAdvancedSettings();

        advancedSettings.grid = this.grid;
        this.setGrid(new Grid(this.projection.getMapImageProvider().getDefaultCoordinateSystem()));
        advancedSettings.grid.detach();

        this.updateReactiveProps();
        this.updateMap();

        this.setAdvancedSettingsMode(false);

        return advancedSettings;
    }

    reapplyAdvancedSettings(advancedSettings: Partial<this>): void {
        this.options = copyObject(advancedSettings.options);
        this.options.advanced_settings_mode = true;

        this.projection.reapplyAdvancedSettings(advancedSettings.projection);

        this.setGrid(advancedSettings.grid);

        this.updateReactiveProps();
        this.updateMap();
    }

    setAdvancedSettingsMode(advancedSettingsMode: boolean): void {
        this.setOption('advanced_settings_mode', advancedSettingsMode);
    }

    updateReactiveProps(): void {
        this.reactiveProps.options = copyObject(this.options);
    }

    clone(): Cutout<WorkspaceCoordinate, ProjectionCoordinate, WorkspaceCoordinateSystem> {
        const cutout = new Cutout(
            this.userInterface,
            this.getPaper(),
            this.anchorWorkspaceCoordinate.clone(),
            this.workspaceCoordinateSystem,
            this.getProjection().clone(),
            this.getGrid().clone()
        );

        cutout.options = copyObject(cutout.options, this.options);
        cutout.updateReactiveProps();

        return cutout;
    }

    makeTemplate(): CutoutTemplate<WorkspaceCoordinate, ProjectionCoordinate, WorkspaceCoordinateSystem> {
        const template = new CutoutTemplate<any, any, any>(
            this.getPaper(),
            this.anchorWorkspaceCoordinate.clone(),
            this.workspaceCoordinateSystem,
            this.getProjection().clone(),
            this.getGrid().clone(),
            ''
        );

        template.options = copyObject(template.options, this.options);

        return template;
    }

    serialize(): Serialization {
        return {
            name: this.name,
            options: copyObject(this.options),
            anchor: {
                system: this.anchorWorkspaceCoordinate.code,
                x: this.anchorWorkspaceCoordinate.getX(),
                y: this.anchorWorkspaceCoordinate.getY(),
            },
            color: this.color,
            visibleOnMap: this.visibleOnMap, // This is not immediately honored, but it is later enforced in userInterface.setFromUnserialize()
            paper: this.paper.serialize(),
            projection: this.projection.serialize(),
            grid: this.grid.serialize(),
        };
    }

    static unserialize(serialized: Serialization, userInterface: UserInterface): Promise<Cutout<any, any, any>> {
        return new Promise(((resolve, reject) => {
            const coordinateSystem = CoordinateConverter.getCoordinateSystem(serialized.anchor.system);
            const coordinate = coordinateSystem.fromPoint(new Point(serialized.anchor.x, serialized.anchor.y));

            let projection = null;
            if(serialized.projection.type === 'wms') {
                projection = WmsProjection.unserialize(serialized.projection);
            } else if(serialized.projection.type === 'luchtfoto') {
                projection = LuchtfotoProjection.unserialize(serialized.projection);
            } else if(serialized.projection.type === 'wmts') {
                projection = WmtsProjection.unserialize(serialized.projection);
            } else if(serialized.projection.type === 'openlayers') {
                projection = OlProjection.unserialize(serialized.projection);
            } else if(serialized.projection.type === 'openlayers_vector') {
                projection = OlVectorProjection.unserialize(serialized.projection);
            } else if(serialized.projection.type === 'empty') {
                projection = EmptyProjection.unserialize(serialized.projection);
            } else {
                throw new Error('Invalid projection type');
            }

            let paper;
            if(typeof serialized.paper === 'object') {
                paper = Paper.unserialize(serialized.paper);
            } else {
                // Serialization v1
                paper = Container.getPaperList().getPaper(serialized.paper || 'A4L');
            }

            projection.initialize().then(() => {
                const cutout = new Cutout(
                    userInterface,
                    paper,
                    // @ts-ignore
                    coordinate,
                    coordinateSystem,
                    projection,
                    Grid.unserialize(serialized.grid),
                );

                cutout.reactiveProps.name = cutout.name = serialized.name;
                cutout.reactiveProps.color = cutout.color = serialized.color;
                cutout.reactiveProps.visibleOnMap = cutout.visibleOnMap = serialized.visibleOnMap;
                cutout.options = copyObject(cutout.options, serialized.options);
                if (cutout.options.flexagon_options) {
                    cutout.options.flexagon_options = copyObject(Cutout.defaultFlexagonOptions, cutout.options.flexagon_options);
                }
                cutout.updateReactiveProps();

                resolve(cutout);
            })
        }));
    }

    computeFlexagonMaxSize() {
        return {
            width: Math.floor((this.paper.width - 12) / 2),
            height: Math.floor((this.paper.height - 12) / 2),
        };
    }

    computeFlexagonSize() {
        if (this.options.flexagon_options === null) {
            throw new Error('(computeFlexagonSize): No flexagon options present');
        }

        const maxSize = this.computeFlexagonMaxSize();

        if (this.options.flexagon_options.width === null) {
            return maxSize;
        }

        // Math.min() could be relevant whenever paper size has changed
        return {
            width: Math.min(this.options.flexagon_options.width, maxSize.width),
            height: Math.min(this.options.flexagon_options.height, maxSize.height),
        };
    }

    computeFlexagonSublineMultiLineStrings(): number[][][]|null {
        if (this.options.variant !== 'flexagon') {
            return null;
        }

        const [
            topLeft,
            topRight,
            bottomRight,
            bottomLeft,
        ] = this.mapPolygonProjection;

        const realMmPerUnit = 1000;
        const realMmPerPaperMm = this.projection.getScale();
        const paperMmOverlap = this.options.flexagon_options.section_overlap;
        const realMmOverlap = paperMmOverlap * realMmPerPaperMm;
        const unitsOverlap = realMmOverlap / realMmPerUnit;

        const lines = [];
        for (const overlapOffset of realMmOverlap > 0 ? [-unitsOverlap / 2, unitsOverlap / 2] : [unitsOverlap / 2]) {
            if (this.options.flexagon_options.orientation === 'horizontal') {
                // Vertical left
                lines.push([
                    [topLeft.getX() * 2 / 3 + topRight.getX() / 3 + overlapOffset, topLeft.getY()],
                    [bottomLeft.getX() * 2 / 3 + bottomRight.getX() / 3 + overlapOffset, bottomLeft.getY()],
                ]);

                // Vertical right
                lines.push([
                    [topLeft.getX() / 3 + topRight.getX() * 2 / 3 + overlapOffset, topLeft.getY()],
                    [bottomLeft.getX() / 3 + bottomRight.getX() * 2 / 3 + overlapOffset, bottomLeft.getY()],
                ]);

                // Horizontal
                lines.push([
                    [topLeft.getX(), topLeft.getY() / 2 + bottomLeft.getY() / 2 + overlapOffset],
                    [topRight.getX(), topRight.getY() / 2 + bottomRight.getY() / 2 + overlapOffset],
                ]);
            } else {
                // Vertical
                lines.push([
                    [topLeft.getX() / 2 + topRight.getX() / 2 + overlapOffset, topLeft.getY()],
                    [bottomLeft.getX() / 2 + bottomRight.getX() / 2 + overlapOffset, bottomLeft.getY()],
                ]);

                // Horizontal top
                lines.push([
                    [topLeft.getX(), topLeft.getY() * 2 / 3 + bottomLeft.getY() / 3 + overlapOffset],
                    [topRight.getX(), topRight.getY() * 2 / 3 + bottomRight.getY() / 3 + overlapOffset],
                ]);

                // Horizontal bottom
                lines.push([
                    [topLeft.getX(), topLeft.getY() / 3 + bottomLeft.getY() * 2 / 3 + overlapOffset],
                    [topRight.getX(), topRight.getY() / 3 + bottomRight.getY() * 2 / 3 + overlapOffset],
                ]);
            }
        }

        return lines.map(([fromProjection, toProjection]) => {
            const fromWorkspace = CoordinateConverter.convert(
                this.projection.coordinateSystem.make(fromProjection[0], fromProjection[1]),
                this.workspaceCoordinateSystem
            );
            const toWorkspace = CoordinateConverter.convert(
                this.projection.coordinateSystem.make(toProjection[0], toProjection[1]),
                this.workspaceCoordinateSystem
            );

            return this.workspaceCoordsToOpenLayers([fromWorkspace, toWorkspace]);
        });
    }

    async visualizeFlexagon(progressCallback: ((evt) => void) | null) {
        const cache = await Container.getCache();

        const image = await new HexaTetraFlexagon(this).generateVisualisation(
            cache,
            progressCallback,
            this.getProjection().getMapImageProvider().getCopyright()
        );

        presentDownloadRaw(this.getName() + '-flexagon-diagram.png', image.currentSrc);
    }

    computeProjectionPolygon(anchorProjection: ProjectionCoordinate): ProjectionCoordinate[] {
        const {width, height} = this.computePolygonSizeInPaperMm();

        const scale = this.projection.getScale();
        const topRight = this.projection.coordinateSystem.make(anchorProjection.getX() + width*scale/1000, anchorProjection.getY());
        const bottomRight = this.projection.coordinateSystem.make(anchorProjection.getX() + width*scale/1000, anchorProjection.getY() + height*scale/1000);
        const bottomLeft = this.projection.coordinateSystem.make(anchorProjection.getX(), anchorProjection.getY() + height*scale/1000);

        return [
            anchorProjection,
            topRight,
            bottomRight,
            bottomLeft,
        ];
    }

    computeWorkspacePolygon(mapPolygonProjection): WorkspaceCoordinate[] {
        return CoordinateConverter.convertPolygon(
            interpolatePolygonEdges(mapPolygonProjection, Cutout.pointsOnEdge - 2),
            this.workspaceCoordinateSystem
        );
    }

    determineWorkspacePolygon(): void {
        this.mapPolygonProjection = this.computeProjectionPolygon(this.projection.anchor);

        this.mapPolygonWorkspace = this.computeWorkspacePolygon(this.mapPolygonProjection);
    }

    computePolygonSizeInPaperMm(): {width: millimeter, height: millimeter} {
        let width: millimeter;
        let height: millimeter;

        if (this.options.variant === 'flexagon') {
            const flexagonSize = this.computeFlexagonSize();
            const columns = this.options.flexagon_options.orientation === 'vertical' ? 2 : 3;
            const rows = this.options.flexagon_options.orientation === 'vertical' ? 3 : 2;
            width = flexagonSize.width * columns - (columns - 1) * this.options.flexagon_options.section_overlap;
            height = flexagonSize.height * rows - (rows - 1) * this.options.flexagon_options.section_overlap;
        } else {
            width = this.paper.width - this.options.margin_left_printable - this.options.margin_left_nonprintable - this.options.margin_right_printable - this.options.margin_right_nonprintable;
            height = this.paper.height - this.options.margin_top_printable - this.options.margin_top_nonprintable - this.options.margin_bottom_printable - this.options.margin_bottom_nonprintable;
        }

        width = Math.max(width, 0);
        height = Math.max(height, 0);

        return {width, height};
    }

    getZIndex() {
        return this.zIndex;
    }

    moveToFront(): void {
        this.zIndex = Cutout.overallZIndex++;
    }

    private workspaceCoordsToOpenLayers(polygon: WorkspaceCoordinate[]): number[][] {
        const olCoords = [];
        for(const workspaceCoord of polygon) {
            olCoords.push(workspaceCoord.toOpenLayersCoordinate());
        }
        return olCoords;
    }

    addToMap(map: Map) {
        this.determineWorkspacePolygon();

        this.moveToFront();

        this.openlayersPolygonFeature = new Feature({
            geometry: new Polygon([this.workspaceCoordsToOpenLayers(this.mapPolygonWorkspace)]),
            cutout: this,
            custom_style: () => {
                const strokeColor = asArray(this.color).slice(0);
                strokeColor[3] = this.isMouseOver ? 0.7 : 0.5;
                const fillColor = asArray(this.color).slice(0);
                fillColor[3] = this.isMouseOver ? 0.3 : 0.2;
                const width = this.isMouseOver ? 5 : 3;

                const styles = [
                    new Style({
                        stroke: new Stroke({
                            color: strokeColor,
                            width: width,
                        }),
                        fill: new Fill({
                            color: fillColor,
                        }),
                        zIndex: this.zIndex,
                        text: new Text({
                            font: 'bold 10px sans-serif',
                            text: this.name,
                            fill: new Fill({
                                color: '#ffffff',
                            }),
                            stroke: new Stroke({
                                color: this.color,
                                width: width,
                            }),
                        }),
                    }),
                ];

                const flexagonSublineStrings = this.computeFlexagonSublineMultiLineStrings();
                if (flexagonSublineStrings && !translating) {
                    styles.push(new Style({
                        geometry: new MultiLineString(flexagonSublineStrings),
                        stroke: new Stroke({
                            color: strokeColor,
                            width: 2,
                        }),
                    }));
                }

                return styles;
            },
        });

        map.getOpenlayersVectorSource().addFeature(this.openlayersPolygonFeature);
        this.reactiveProps.visibleOnMap = this.visibleOnMap = true;

        this.openlayersPolygonTranslateInteraction = new Translate({
            features: new Collection([this.openlayersPolygonFeature]),
            condition: (mapBrowserEvent: MapBrowserEvent<any>): boolean => {
                // You may not drag in locked mode
                if (this.userInterface.isLocked()) {
                    return false;
                }

                // In normal mode, if you press alt, we don't drag
                // If a route has focus, you may not drag unless you press alt
                if (altKeyOnly(mapBrowserEvent) !== this.userInterface.getRouteCollection().hasFocusedRoute()) {
                    return false;
                }

                // You may not drag if a location marker is hovered
                if (this.userInterface.getMap().hasMouseOverLocation()) {
                    return false;
                }

                // You may drag the uppermost cutout
                const cutout = map.cutoutsAtPixel(mapBrowserEvent.pixel)[0];

                return cutout && cutout.id === this.id;
            }
        });
        //this.openlayersPolygonFeature.on('change', function(e) {
        //    console.log(e);
        //    //console.log('Feature moved to:' + this.getGeometry().getCoordinates());
        //});
        map.getOpenlayersMap().addInteraction(this.openlayersPolygonTranslateInteraction);


        let snapDiff = [0, 0];
        let translating = false;
        this.openlayersPolygonTranslateInteraction.on('translatestart', (e) => {
            snapDiff = [0, 0];
            translating = true;
            document.body.style.cursor = 'grabbing';

            this.projection.getBoundingPolygon().then((imageProviderBoundingPolygon) => {
                if(imageProviderBoundingPolygon === null) {
                    return;
                }

                const imageProviderBoundingPolygonWorkspace = CoordinateConverter.convertPolygon(
                    imageProviderBoundingPolygon,
                    this.workspaceCoordinateSystem
                );

                const coordinates = [
                    [fromLonLat([-180, -90]), fromLonLat([180, -90]), fromLonLat([180, 90]), fromLonLat([-180, 90])],
                    this.workspaceCoordsToOpenLayers(imageProviderBoundingPolygonWorkspace)
                ];

                const boundsPolygonFeature = new Feature({
                    geometry: new Polygon(coordinates),
                    custom_style: () => {
                        const fillColor = asArray('#ffffff').slice(0);
                        fillColor[3] = 0.6;

                        return [
                            new Style({
                                stroke: new Stroke({
                                    color: '#ffffff00',
                                    width: 0,
                                }),
                                fill: new Fill({
                                    color: fillColor,
                                }),
                            }),
                        ];
                    },
                });

                map.getOpenlayersVectorSource().addFeature(boundsPolygonFeature);

                this.openlayersPolygonTranslateInteraction.once('translateend', (e) => {
                    map.getOpenlayersVectorSource().removeFeature(boundsPolygonFeature);
                });
            }).catch(() => {
                console.log('Could not find bounding polygon');
            });
        });

        this.openlayersPolygonTranslateInteraction.on('translating', (e) => {
            if(platformModifierKeyOnly(e.mapBrowserEvent)) {
                return;
            }

            const coordinates = this.openlayersPolygonFeature.getGeometry().getCoordinates();

            const thisCornerLL = CoordinateConverter.convert(
                this.workspaceCoordinateSystem.fromOpenLayersCoordinate([
                    coordinates[0][0][0] - snapDiff[0],
                    coordinates[0][0][1] - snapDiff[1]
                ]),
                this.projection.coordinateSystem
            );

            const {width, height} = this.computePolygonSizeInPaperMm();

            const scale = this.projection.getScale();
            const thisCornerHH = this.projection.coordinateSystem.make(thisCornerLL.getX() + width * scale / 1000, thisCornerLL.getY() + height * scale / 1000);

            const thisLeft = thisCornerLL.getX();
            const thisRight = thisCornerHH.getX();
            const thisBottom = thisCornerLL.getY();
            const thisTop = thisCornerHH.getY();

            const factor = Math.pow(2,map.getOpenlayersMap().getView().getZoom()-12);
            let diffX = 1000/factor;
            let diffY = 1000/factor;
            let maxDiffPerpHor = (thisRight - thisLeft)/2;
            let maxDiffPerpVer = (thisTop - thisBottom)/2;

            const neighbourOverlap = Container.getNeighbourOverlap() * scale / 1000;

            let newCornerX = null;
            let newCornerY = null;

            this.userInterface.getCutouts().forEach((cutout) => {
                if(cutout.id === this.id) {
                    return;
                }

                if(!cutout.visibleOnMap) {
                    return;
                }

                if(!cutout.mapPolygonProjection[0].belongsTo(this.projection.coordinateSystem)) {
                    return;
                }

                const otherLeft = cutout.mapPolygonProjection[0].getX();
                const otherRight = cutout.mapPolygonProjection[2].getX();
                const otherBottom = cutout.mapPolygonProjection[0].getY();
                const otherTop = cutout.mapPolygonProjection[2].getY();

                // Opposite-edge diffs ('outer')
                const outDiffTop = Math.abs(otherBottom - thisTop);
                const outDiffBottom = Math.abs(otherTop - thisBottom);
                const outDiffLeft = Math.abs(otherRight - thisLeft);
                const outDiffRight = Math.abs(otherLeft - thisRight);

                const outOverlapDiffTop = Math.abs(otherBottom + neighbourOverlap - thisTop);
                const outOverlapDiffBottom = Math.abs(otherTop - neighbourOverlap - thisBottom);
                const outOverlapDiffLeft = Math.abs(otherRight - neighbourOverlap - thisLeft);
                const outOverlapDiffRight = Math.abs(otherLeft + neighbourOverlap - thisRight);

                // Same-edge diffs ('inner')
                const inDiffTop = Math.abs(otherTop - thisTop);
                const inDiffBottom = Math.abs(otherBottom - thisBottom);
                const inDiffLeft = Math.abs(otherLeft - thisLeft);
                const inDiffRight = Math.abs(otherRight - thisRight);

                const minDiffVer = Math.min(outDiffTop, outDiffBottom, outOverlapDiffTop, outOverlapDiffBottom, inDiffTop, inDiffBottom);
                const minDiffHor = Math.min(outDiffLeft, outDiffRight, outOverlapDiffLeft, outOverlapDiffRight, inDiffLeft, inDiffRight);

                if(minDiffHor < maxDiffPerpHor) {
                    if(outDiffTop < diffY) {
                        newCornerY = otherBottom - (thisTop - thisBottom);
                        diffY = outDiffTop;
                    }
                    if(outDiffBottom < diffY) {
                        newCornerY = otherTop;
                        diffY = outDiffBottom;
                    }
                    if(outOverlapDiffTop < diffY) {
                        newCornerY = otherBottom - (thisTop - thisBottom) + neighbourOverlap;
                        diffY = outOverlapDiffTop;
                    }
                    if(outOverlapDiffBottom < diffY) {
                        newCornerY = otherTop - neighbourOverlap;
                        diffY = outOverlapDiffBottom;
                    }
                    if(inDiffTop < diffY) {
                        newCornerY = otherTop - (thisTop - thisBottom);
                        diffY = inDiffTop;
                    }
                    if(inDiffBottom < diffY) {
                        newCornerY = otherBottom;
                        diffY = inDiffBottom;
                    }
                }

                if(minDiffVer < maxDiffPerpVer) {
                    if(outDiffLeft < diffX) {
                        newCornerX = otherRight;
                        diffX = outDiffLeft;
                    }
                    if(outDiffRight < diffX) {
                        newCornerX = otherLeft - (thisRight - thisLeft);
                        diffX = outDiffRight;
                    }
                    if(outOverlapDiffLeft < diffX) {
                        newCornerX = otherRight - neighbourOverlap;
                        diffX = outOverlapDiffLeft;
                    }
                    if(outOverlapDiffRight < diffX) {
                        newCornerX = otherLeft - (thisRight - thisLeft) + neighbourOverlap;
                        diffX = outOverlapDiffRight;
                    }
                    if(inDiffLeft < diffX) {
                        newCornerX = otherLeft;
                        diffX = inDiffLeft;
                    }
                    if(inDiffRight < diffX) {
                        newCornerX = otherRight - (thisRight - thisLeft);
                        diffX = inDiffRight;
                    }
                }
            });

            if(newCornerX || newCornerY) {
                newCornerX = newCornerX || thisLeft;
                newCornerY = newCornerY || thisBottom;

                const newCorner = this.projection.coordinateSystem.make(newCornerX, newCornerY);

                const newPolygon = this.computeWorkspacePolygon(this.computeProjectionPolygon(newCorner));
                const newOlPolygon = this.workspaceCoordsToOpenLayers(newPolygon);

                snapDiff[0] += newOlPolygon[0][0] - coordinates[0][0][0];
                snapDiff[1] += newOlPolygon[0][1] - coordinates[0][0][1];

                this.openlayersPolygonFeature.getGeometry().setCoordinates([newOlPolygon]);
            } else if(snapDiff[0] !== 0 && snapDiff[1] !== 0) {
                const newPolygon = this.computeWorkspacePolygon(this.computeProjectionPolygon(thisCornerLL));
                const newOlPolygon = this.workspaceCoordsToOpenLayers(newPolygon);

                this.openlayersPolygonFeature.getGeometry().setCoordinates([newOlPolygon]);

                snapDiff[0] = 0;
                snapDiff[1] = 0;
            }
        });

        this.openlayersPolygonTranslateInteraction.on('translateend', (e) => {
            translating = false;

            document.body.style.cursor = this.isMouseOver ? 'grab' : 'default';

            const coordinates = this.openlayersPolygonFeature.getGeometry().getCoordinates();

            this.userInterface.actionHistory.addAction(new MoveCutoutAction(
                this,
                this.workspaceCoordinateSystem.fromOpenLayersCoordinate(coordinates[0][0])
            ));
        });
    }

    removeFromMap(map: Map, mouseout: boolean = true) {
        if (mouseout) {
            this.mouseout(false);
        }

        if (this.visibleOnMap === false) {
            return;
        }

        if(this.openlayersPolygonFeature !== null) {
            map.getOpenlayersVectorSource().removeFeature(this.openlayersPolygonFeature);
        }

        if(this.openlayersPolygonTranslateInteraction !== null) {
            map.getOpenlayersMap().removeInteraction(this.openlayersPolygonTranslateInteraction);
        }

        this.reactiveProps.visibleOnMap = this.visibleOnMap = false;
    }

    toggleVisibleOnMap(map: Map, visible: boolean = null) {
        if(visible === null) {
            visible = !this.visibleOnMap;
        }

        if(visible && !this.visibleOnMap) {
            map.getOpenlayersVectorSource().addFeature(this.openlayersPolygonFeature);
            map.getOpenlayersMap().addInteraction(this.openlayersPolygonTranslateInteraction);
            this.reactiveProps.visibleOnMap = this.visibleOnMap = true;
        } else if(!visible && this.visibleOnMap) {
            this.removeFromMap(map, false);
        }
    }

    updateMap() {
        this.determineWorkspacePolygon();

        this.openlayersPolygonFeature.getGeometry().setCoordinates([this.workspaceCoordsToOpenLayers(this.mapPolygonWorkspace)]);
    }

    openWorkspaceDropdownMenu(evt) {
        this.userInterface.openCutoutDropdownMenu(this, evt);
    }

    moveToWindowCenter(addHistoryAction: boolean): Promise<boolean> {
        return this.projection.getBoundingPolygon().then((imageProviderBoundingPolygon) => {
            // We used to work with projection coordinates here before; but this leads to invalid results whenever
            // the projection coordinates are UTM and the window covers multiple UTM zones. Hence, we moved to
            // using the workspace coordinate system (currently only WGS84)

            const windowCenter = this.userInterface.getMap().getCenter();

            const convertedWindowCenter = CoordinateConverter.convert(windowCenter, this.workspaceCoordinateSystem);

            let turfImageProviderBoundingPolygon;
            if(imageProviderBoundingPolygon !== null) {
                const convertedImageProviderBoundingPolygon = CoordinateConverter.convertPolygon(
                    interpolatePolygonEdges(imageProviderBoundingPolygon, 11),
                    this.workspaceCoordinateSystem
                );

                turfImageProviderBoundingPolygon = toTurfPolygon(convertedImageProviderBoundingPolygon);

                // Most likely, the window center is in the bounding polygon, so we can just use that
                if(booleanPointInPolygon(
                    [convertedWindowCenter.getX(), convertedWindowCenter.getY()],
                    turfImageProviderBoundingPolygon
                )) {
                    if(addHistoryAction) {
                        this.userInterface.actionHistory.addAction(new MoveCutoutAction(this, convertedWindowCenter));
                    } else {
                        this.setAnchorWorkspaceCoordinate(convertedWindowCenter);
                    }
                    return true;
                }
            }

            // The window center is not in the bounding polygon, but a part of the map still may be.
            // Find any feasible spot to place the cutout
            const windowBoundingPolygon = this.userInterface.getMap().getBoundingPolygon();

            const convertedWindowBoundingPolygon = CoordinateConverter.convertPolygon(
                interpolatePolygonEdges(windowBoundingPolygon, 11),
                this.workspaceCoordinateSystem
            );

            let centerFeature;

            if(imageProviderBoundingPolygon !== null) {
                const intersectionFeature = intersect(featureCollection([
                    turfImageProviderBoundingPolygon,
                    toTurfPolygon(convertedWindowBoundingPolygon),
                ]));

                if(!intersectionFeature || intersectionFeature.geometry.type !== 'Polygon') {
                    return false;
                }

                centerFeature = centroid(intersectionFeature.geometry);
            } else {
                centerFeature = centroid(toTurfPolygon(convertedWindowBoundingPolygon));
            }

            if(!centerFeature || centerFeature.geometry.type !== 'Point') {
                throw new UserError('Invalid intersection center');
            }

            const center = centerFeature.geometry.coordinates;
            const workspaceCenter = this.workspaceCoordinateSystem.make(center[0], center[1]);

            if(addHistoryAction) {
                this.userInterface.actionHistory.addAction(new MoveCutoutAction(this, workspaceCenter));
            } else {
                this.setAnchorWorkspaceCoordinate(workspaceCenter);
            }
            return true;
        });
    }

    isInBoundingBox(): Promise<boolean> {
        return this.projection.getBoundingPolygon().then((imageProviderBoundingPolygon) => {
            if(imageProviderBoundingPolygon === null) {
                return true;
            }

            const imageProviderBoundingPolygonProjection = CoordinateConverter.convertPolygon(
                imageProviderBoundingPolygon,
                this.projection.coordinateSystem
            );

            return polygonsOverlap(imageProviderBoundingPolygonProjection, this.mapPolygonProjection);
        });
    }

    printAndDownload(progressCallback: ((evt) => void)|null = null): Promise<void> {
        const {width, height} = this.computePolygonSizeInPaperMm();

        if (width <= 0 || height <= 0) {
            alert('De kaartuitsnede beslaat geen oppervlak. Pas de marges en/of het papierformaat aan.');
            return Promise.resolve();
        }

        return this.isInBoundingBox().then((isInBoundingBox) => {
            if(!isInBoundingBox) {
                if(!confirm('De kaartuitsnede bevindt zich buiten het definitiegebied van de kaartbron. Verplaats de kaartuitsnede of selecteer een andere kaartbron. Wil je doorgaan met downloaden?')) {
                    return Promise.resolve();
                }
            }

            const jsPdfGenerator = new JsPdfGenerator();
            return this.printOnNewPage(jsPdfGenerator, progressCallback).then(() => {
                let filename = this.name.replace(/[^0-9a-zA-Z]+/g, '-');
                if(filename === '') {
                    filename = 'map-'+this.id;
                }

                let postfix = '';
                if(isMobile()) {
                    // Add a postfix to prevent mobile browsers from needlessly caching the resulting PDF (issue #4)
                    const postfix_date = new Date();
                    postfix = '-' + [
                        postfix_date.getFullYear(),
                        padLeadingZeros(postfix_date.getMonth() + 1, 2),
                        padLeadingZeros(postfix_date.getDate(), 2),
                        padLeadingZeros(postfix_date.getHours(), 2),
                        padLeadingZeros(postfix_date.getMinutes(), 2),
                        padLeadingZeros(postfix_date.getSeconds(), 2),
                    ].join('');
                }

                jsPdfGenerator.getJsPdf().save(filename + postfix + '.pdf');
            });
        });
    }

    printOnNewPage(jsPdfGenerator: JsPdfGenerator, progressCallback: ((evt) => void)|null = null): Promise<void> {
        return this.checkSendPrintStatistics().then(() => {
            return (new Printer(this, progressCallback)).print(jsPdfGenerator);
        });
    }

    checkSendPrintStatistics(): Promise<void> {
        return this.userInterface.checkStatisticsParticipation().then((choice) => {
            if(choice === true) {
                try {
                    const settings = this.serialize();
                    settings.anchor.x = null;
                    settings.anchor.y = null;
                    settings.num_routes = this.userInterface.getRouteCollection().getRoutes().filter(route => route.isVisible()).length;
                    settings.num_locations = this.userInterface.getLocationCollection().getLocations().filter(location => location.isVisible()).length;
                    $.post('server/stats.php?request=cutout_download', {
                        settings: JSON.stringify(settings),
                    });
                } catch(e) {
                    console.log(e);
                }
            }
        });
    }

    mouseover(notifyLayer: boolean = true) {
        this.isMouseOver = true;
        $('#cutout_' + this.id).addClass('hover');

        if (notifyLayer) {
            this.userInterface.getMap().getOpenlayersVectorLayer().changed();
        }
    };

    mouseout(notifyLayer: boolean = true) {
        this.isMouseOver = false;
        $('#cutout_' + this.id).removeClass('hover');

        if (notifyLayer) {
            this.userInterface.getMap().getOpenlayersVectorLayer().changed();
        }
    };


}
