import Action from "./Action";
import Cutout from "../Main/Cutout";
import {Serialization} from "../Main/Serializer";

export default class ChangeCutoutDrawRoutesLocationsAction implements Action {

    private readonly oldValue: boolean;

    constructor(private cutout: Cutout<any, any, any>, private newValue: boolean) {
        this.oldValue = this.cutout.options.draw_routes;
    }

    public apply() {
        this.cutout.setOption('draw_routes', this.newValue);
        this.cutout.setOption('draw_route_intermediates', this.newValue);
        this.cutout.setOption('draw_locations', this.newValue);
    }

    public revert() {
        this.cutout.setOption('draw_routes', this.oldValue);
        this.cutout.setOption('draw_route_intermediates', this.oldValue);
        this.cutout.setOption('draw_locations', this.oldValue);
    }

    merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldValue: this.oldValue,
            newValue: this.newValue,
        };
    }
}
