import Coordinate from "../Coordinates/Coordinate";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Cutout from "../Main/Cutout";
import CoordinateConverter from "../Util/CoordinateConverter";
import Cache from "../Util/Cache";
import {Point, PointSystem} from "../Util/Math";
import {jsPDF} from "jspdf";
import Paper from "../Util/Paper";
import CartesianTransformation from "../Conversion/CartesianTransformation";
import MapImageProvider from "./MapImageProvider";
import {GridSpec} from "../Main/Grid";
import {Serialization} from "../Main/Serializer";
import {reactive} from "vue";
import {canvasToPngImage} from "../Util/functions";

export type ProjectionReactiveProps = {
    mipName: string,
    coordinateSystemCode: string,
    scale: number,
};

export default abstract class Projection<C extends Coordinate, MIP extends MapImageProvider> {

    reactiveProps: ProjectionReactiveProps;

    protected cutout: Cutout<any, C, any> = null;
    coordinateSystem: CoordinateSystem<C>;
    anchor: C;

    protected constructor(
        readonly mapImageProvider: MIP,
        protected scale: number = null,
        extraReactiveProps,
    ) {
        this.coordinateSystem = this.mapImageProvider.getDefaultCoordinateSystem();

        this.reactiveProps = reactive(extraReactiveProps);
        this.reactiveProps.mipName = mapImageProvider.name;
        this.reactiveProps.coordinateSystemCode = this.coordinateSystem.code;
        this.reactiveProps.scale = scale;
    }

    abstract clone(): Projection<C, MIP>;

    abstract serialize(): Serialization;

    abstract initialize(): Promise<void>;

    abstract getMipDrawnGrid(): GridSpec|null;

    resetAdvancedSettings(): Partial<this> {
        const advancedSettings: Partial<this> = {};

        advancedSettings.coordinateSystem = this.coordinateSystem;
        this.setCoordinateSystem(this.mapImageProvider.getDefaultCoordinateSystem());

        return advancedSettings;
    }

    reapplyAdvancedSettings(advancedSettings: Partial<this>): void {
        this.setCoordinateSystem(advancedSettings.coordinateSystem);
    }

    getBoundingPolygon(): Promise<Coordinate[]|null> {
        return this.mapImageProvider.getBoundingPolygon(this.coordinateSystem);
    }

    detach() {
        if(this.cutout === null) {
            throw new Error('Already detached');
        }

        this.cutout = null;
    }

    attach(cutout: Cutout<any, C, any>) {
        if(this.cutout !== null) {
            throw new Error('Already attached');
        }

        this.cutout = cutout;
    }

    setAnchor(coordinate: Coordinate) {
        this.anchor = CoordinateConverter.convert(coordinate, this.coordinateSystem);
        this.coordinateSystem = this.coordinateSystem.rebase(this.anchor);
        this.reactiveProps.coordinateSystemCode = this.coordinateSystem.code;
    }

    setCoordinateSystem(coordinateSystem: CoordinateSystem<Coordinate>) {
        // @ts-ignore
        this.coordinateSystem = coordinateSystem;
        this.reactiveProps.coordinateSystemCode = this.coordinateSystem.code;
        this.setAnchor(CoordinateConverter.convert(this.anchor, coordinateSystem));
        this.cutout.updateMap();
    }

    getMapImageProvider(): MapImageProvider {
        return this.mapImageProvider;
    }

    getScale(): number {
        return this.scale;
    }

    setScale(newScale: number) {
        this.reactiveProps.scale = this.scale = newScale;
        if(this.cutout) {
            this.cutout.updateMap();
        }
    }

    abstract getDpi();

    paperCoordinateConversion(): CartesianTransformation<C, Point> {
        const realMmPerPaperMm = this.getScale();
        const realMmPerUnit = 1000;
        const paperMmPerUnit = realMmPerUnit / realMmPerPaperMm;

        return CartesianTransformation
            .build(this.coordinateSystem, new PointSystem())

            // Incoming is a projection coordinate; we move the anchor to the origin
            .translate(new Point(-this.anchor.getX(), -this.anchor.getY()))

            // We scale from real distances to paper distances
            .scale(paperMmPerUnit)

            // On paper, the y-axis points down
            .mulMatrix([[1, 0], [0, -1]])

            // Move by margin
            .translate(new Point(
                this.cutout.options.margin_left_printable + this.cutout.options.margin_left_nonprintable,
                this.cutout.getPaper().height - this.cutout.options.margin_bottom_printable - this.cutout.options.margin_bottom_nonprintable
            ))

            .make();
    }

    /**
     * @param cache
     * @param progressCallback
     * @param minX Min x in projection coordinates
     * @param maxX Max x in projection coordinates
     * @param minY Min y in projection coordinates
     * @param maxY Max y in projection coordinates
     * @param callback Called for each download/rendered tile
     */
    protected abstract fetchForProject(
        cache: Cache,
        progressCallback: ((evt) => void)|null,
        minX: number,
        maxX: number,
        minY: number,
        maxY: number,
        callback: (img: HTMLImageElement, coordinate: C, metrics: {pxPerUnit: number, paperMmPerTile: number}) => void
    ): Promise<void>;

    projectToPdf(doc: jsPDF, paper: Paper, cache: Cache, progressCallback: ((evt) => void)|null): Promise<void> {
        const p = this.cutout.mapPolygonProjection;
        const toPaperCoord = this.paperCoordinateConversion();

        return this.fetchForProject(
            cache,
            progressCallback,
            Math.min(p[0].getX(), p[1].getX(), p[2].getX(), p[3].getX()),
            Math.max(p[0].getX(), p[1].getX(), p[2].getX(), p[3].getX()),
            Math.min(p[0].getY(), p[1].getY(), p[2].getY(), p[3].getY()),
            Math.max(p[0].getY(), p[1].getY(), p[2].getY(), p[3].getY()),
            (img, projectionCoordTopLeft, metrics) => {
                const paperCoord = toPaperCoord.convert(projectionCoordTopLeft);

                doc.addImage(img, 'PNG', paperCoord.getX(), paperCoord.getY(), metrics.paperMmPerTile, metrics.paperMmPerTile);
            }
        );
    }

    /**
     * @param cache
     * @param progressCallback
     * @param minX Min x in projection coordinates
     * @param maxX Max x in projection coordinates
     * @param minY Min y in projection coordinates
     * @param maxY Max y in projection coordinates
     */
    projectToImage(cache: Cache, progressCallback: ((evt) => void)|null, minX: number, maxX: number, minY: number, maxY: number): Promise<HTMLImageElement> {
        let canvas = null;
        let ctx: CanvasRenderingContext2D = null;

        return this.fetchForProject(cache, progressCallback, minX, maxX, minY, maxY, (img, projectionCoordTopLeft, metrics) => {
            if (canvas === null) {
                canvas = document.createElement('canvas');
                canvas.width = (maxX - minX) * metrics.pxPerUnit;
                canvas.height = (maxY - minY) * metrics.pxPerUnit;
                ctx = canvas.getContext("2d");
            }

            const dxUnits = projectionCoordTopLeft.getX() - minX;
            const dyUnits = maxY - projectionCoordTopLeft.getY();

            ctx.drawImage(img, dxUnits * metrics.pxPerUnit, dyUnits * metrics.pxPerUnit);
        }).then(() => {
            return canvasToPngImage(canvas ?? document.createElement('canvas')); // canvas may be null for EmptyProjection
        });
    }
}
