import {JsPdfGenerator} from "../../Main/Printer";
import Paper from "../Paper";
import Cache from "../Cache";
import UserError from "../UserError";
import hexatetraflexagonInstruction from "../../img/hexatetraflexagon.png";
import hexatetraflexagonDiagram from "../../img/hexatetraflexagon-diagram.png";
import Cutout from "../../Main/Cutout";
import {canvasToPngImage, downloadImage} from "../functions";

type FlexigonSubsectionAssignment = {appearance: number, side: 'f'|'b', squareCoord: [number, number], section: number, subsection: 'tl'|'tr'|'bl'|'br', rotate: boolean};
type FlexigonAssignment = FlexigonSubsectionAssignment[];

/*
 * Draw a hexatetraflexagon, and number its subsections 1-12 from top-left to bottom-right:
 *  1  2  3  4
 *  5        6
 *  7        8
 *  9 10 11 12
 *
 * On the backside, number 1'-12' from the top-right to bottom-left, such that each subsection holds the same
 * numeric value on both sides:
 *  1'  2'  3'  4'
 *  5'          6'
 *  7'          8'
 *  9' 10' 11' 12'
 *
 * Note that there are 24 subsections. Each section consists of 2*2=4 subsections, hence the hexatetraflexagon
 * can represent 6 independent sections.
 * Fold the hexatetraflexagon inwards in clockwise order (left, top, right, bottom). Take note of the various
 * sections that materialize based on each of the primary actions, defined as follows.
 *
 * Denote primary actions as:
 *  - HO (horizontal outward)
 *  - HI (horizontal inward)
 *  - VO (vertical outward)
 *  - VI (vertical inward)
 *
 * Denote the appearance of a subsection as: App = N['][r]:
 *  - N indicates the subsection number 1-12
 *  - ' indicates backside
 *  - r indicates upside-down appearance
 *
 * Denote the appearance of a hexatetraflexagon as [App, App, App, App] for the top-left, top-right, bottom-left
 * and bottom-right subsection respectively.
 * If you have folded the hexatetraflexagon, you will see appearance (1), with (2) on the backside:
 *   1) [3'r, 5', 8', 10'r]
 *   2) [3r, 5, 8, 10r]
 *
 * The following appearances show up after primary actions on (1):
 *   3) HI: [5, 3r, 10r, 8]
 *   9) HO: [2'r, 1'r, 12'r, 11'r]
 *   7) VO: [4', 7', 6', 9']
 *  13) VI: [5r, 3, 10, 8r]
 * The following appearances show up after primary actions on (2); note that for each of the following,
 * appearance M is the backside of appearance M-4 above.
 *   4) HO: [2r, 1r, 12r, 11r]
 *  10) HI: [5', 3'r, 10'r, 8']
 *   8) VI: [5'r, 3', 10', 8'r]
 *  14) VO: [4, 7, 6, 9]
 *
 * The following appearances show up after the respective secondary actions, where 6 and 12 are the backsides
 * of 5 and 11, respectively:
 *   5) (3)+VI, (7)+HO: [1, 2, 11, 12]
 *  11) (9)+VO, (13)+HI: [7, 4, 9, 6]
 *   6) (4)+VO, (8)+HI: [7', 4', 9', 6']
 *  12) (10)+VI, (14)+HO: [1', 2', 11', 12']
 *
 * Note that the following sets are distinct permutations of each other:
 *  - (2), (3), (13)
 *  - (1), (8), (10)
 *  - (9), (12)
 *  - (6), (7)
 *  - (4), (5)
 *  - (11), (14)
 */
export default class HexaTetraFlexagon {

    constructor(private readonly cutout: Cutout<any, any, any>) {
    }

    private SQUARE_COORDINATES = {
        f1: [0, 0],
        f2: [1, 0],
        f3: [2, 0],
        f4: [3, 0],
        f5: [0, 1],
        f6: [3, 1],
        f7: [0, 2],
        f8: [3, 2],
        f9: [0, 3],
        f10: [1, 3],
        f11: [2, 3],
        f12: [3, 3],

        b1: [0, 0],
        b2: [1, 0],
        b3: [2, 0],
        b4: [3, 0],
        b5: [0, 1],
        b6: [3, 1],
        b7: [0, 2],
        b8: [3, 2],
        b9: [0, 3],
        b10: [1, 3],
        b11: [2, 3],
        b12: [3, 3],
    };

    private getSections(): [number, number][] {
        if (this.cutout.options.flexagon_options.orientation === 'vertical') {
            return [
                [0, 0],
                [0, 1],
                [0, 2],
                [1, 0],
                [1, 1],
                [1, 2],
            ];
        } else {
            return [
                [0, 0],
                [1, 0],
                [2, 0],
                [0, 1],
                [1, 1],
                [2, 1],
            ];
        }
    }

    private computeProjectionBox(assignment:  FlexigonSubsectionAssignment) {
        const flexagonSize = this.cutout.computeFlexagonSize();
        const mmSectionWidth = flexagonSize.width;
        const mmSectionHeight = flexagonSize.height;

        const mmSubSectionWidth = mmSectionWidth / 2;
        const mmSubSectionHeight = mmSectionHeight / 2;

        const realMmPerUnit = 1000;
        const realMmPerPaperMm = this.cutout.getProjection().getScale();
        const paperMmOverlap = this.cutout.options.flexagon_options.section_overlap;
        const realMmOverlap = paperMmOverlap * realMmPerPaperMm;
        const realMmWidthPerSubSection = mmSubSectionWidth * realMmPerPaperMm;
        const realMmHeightPerSubSection = mmSubSectionHeight * realMmPerPaperMm;
        const unitsWidthPerSubSection = realMmWidthPerSubSection / realMmPerUnit;
        const unitsHeightPerSubSection = realMmHeightPerSubSection / realMmPerUnit;
        const unitsOverlap = realMmOverlap / realMmPerUnit;

        const sections = this.getSections();

        const p = this.cutout.mapPolygonProjection;
        const pMinX = Math.min(p[0].getX(), p[1].getX(), p[2].getX(), p[3].getX());
        // const pMaxX = Math.max(p[0].getX(), p[1].getX(), p[2].getX(), p[3].getX());
        const pMinY = Math.min(p[0].getY(), p[1].getY(), p[2].getY(), p[3].getY());
        // const pMaxY = Math.max(p[0].getY(), p[1].getY(), p[2].getY(), p[3].getY());

        const {section, subsection} = assignment;

        const [mapSectionX, mapSectionY] = sections[section];

        const xmin = pMinX + mapSectionX * (unitsWidthPerSubSection * 2 - unitsOverlap) + ((subsection === 'tr' || subsection === 'br') ? unitsWidthPerSubSection : 0);
        const ymin = pMinY + mapSectionY * (unitsHeightPerSubSection * 2 - unitsOverlap) + ((subsection === 'tl' || subsection === 'tr') ? unitsHeightPerSubSection : 0);
        const xmax = xmin + unitsWidthPerSubSection;
        const ymax = ymin + unitsHeightPerSubSection;

        return [xmin, xmax, ymin, ymax];
    }

    public async projectToPdf(
        jsPdfGenerator: JsPdfGenerator,
        paper: Paper,
        cache: Cache,
        progressCallback: ((evt) => void) | null,
        copyrightCallback: (x, y) => void
    ) {
        if (paper.height * paper.width * 2 > 1010000) {
            // The general limit is 1 square meter, here we halve the limit because the flexagon takes up 2 pages
            throw new UserError('Gebruik een papierformaat kleiner dan een halve vierkante meter');
        }

        this.cutout.userInterface.toasts.addCategoricalToast(
            'hexatetraflexagonDownload',
            'Hexatetraflexagon download',
            'Tip: print de flexagon dubbelzijdig (op ware grootte/100%), gedraaid over de <b>zijkant</b>. Dan zou de flexagon precies goed afgedrukt moeten worden zonder te hoeven plakken. '
            + (paper.width > paper.height
                    ? 'De PDF is in landschapstand, dus druk dubbelzijdig af over de <b>korte</b> zijde.'
                    : 'De PDF is in portretstand, dus druk dubbelzijdig af over de <b>lange</b> zijde.'
            )
        );

        let doc = jsPdfGenerator.getJsPdf();

        const flexagonSize = this.cutout.computeFlexagonSize();
        const mmSectionWidth = flexagonSize.width;
        const mmSectionHeight = flexagonSize.height;

        const mmFullWidth = mmSectionWidth * 2; // mm
        const mmFullHeight = mmSectionHeight * 2; // mm
        const mmSubSectionWidth = mmSectionWidth / 2;
        const mmSubSectionHeight = mmSectionHeight / 2;
        const topLeftX = (paper.width - mmFullWidth) / 2;
        const topLeftY = (paper.height - mmFullHeight) / 2;

        const mmInstructionSize = Math.min(mmSectionWidth, mmSectionHeight);
        doc.addImage(
            hexatetraflexagonInstruction,
            'PNG',
            (paper.width - mmInstructionSize) / 2,
            (paper.height - mmInstructionSize) / 2,
            mmInstructionSize,
            mmInstructionSize
        );

        const flexagonAssignment = this.getFlexagonAssignment();

        let sidesDone = 0;
        const newProgressCallback = progressCallback ? ({done, total}) => {
            progressCallback({
                done: sidesDone + done / total,
                total: flexagonAssignment.length,
            });
        } : null;

        for (const side of ['f', 'b']) {
            if (side === 'b') {
                doc = await jsPdfGenerator.addPage(this.cutout.getPaper());
            }

            const flexagonSide = flexagonAssignment.filter(a => a.side === side);

            for (const assignment of flexagonSide) {
                const {squareCoord: [col, row], rotate} = assignment;

                const paperCoordX = topLeftX + col * mmSubSectionWidth;
                const paperCoordY = topLeftY + row * mmSubSectionHeight;

                const [xmin, xmax, ymin, ymax] = this.computeProjectionBox(assignment);

                await this.cutout.getProjection().projectToImage(cache, newProgressCallback, xmin, xmax, ymin, ymax).then(img => {
                    doc.addImage(
                        img,
                        'PNG',
                        paperCoordX + (rotate ? mmSubSectionWidth : 0),
                        paperCoordY - (rotate ? mmSubSectionHeight : 0),
                        mmSubSectionWidth,
                        mmSubSectionHeight,
                        null,
                        null,
                        rotate ? 180 : 0
                    );
                });

                sidesDone++;
            }

            copyrightCallback(paper.width / 2 - mmSubSectionWidth + 1, paper.height / 2 + mmSubSectionHeight - 1);
        }
    }

    /**
     * Return assignment of each of the subsections to their associated section number
     */
    private getFlexagonAssignment(): FlexigonAssignment {
        if (this.cutout.options.flexagon_options.assignment_version === 'htf_2b4p') {
            const flipSideRotation = this.cutout.options.flexagon_options.orientation === 'horizontal'
                ? this.rotateSection
                : x => x;

            return [
                ...this.computeSectionAssignment(0, 7),
                ...flipSideRotation(this.computeSectionAssignment(3, 14)),

                ...this.computeSectionAssignment(1, 1),
                ...flipSideRotation(this.computeSectionAssignment(4, 2)),

                ...this.computeSectionAssignment(2, 9),
                ...flipSideRotation(this.computeSectionAssignment(5, 4)),
            ];
        } else if (this.cutout.options.flexagon_options.assignment_version === 'htf_2b2s') {
            if (this.cutout.options.flexagon_options.orientation === 'horizontal') {
                return [
                    ...this.computeSectionAssignment(0, 5),
                    ...this.rotateSection(this.computeSectionAssignment(3, 6)),

                    ...this.computeSectionAssignment(1, 1),
                    ...this.rotateSection(this.computeSectionAssignment(4, 2)),

                    ...this.computeSectionAssignment(2, 11),
                    ...this.rotateSection(this.computeSectionAssignment(5, 12)),
                ];
            } else {
                return [
                    ...this.computeSectionAssignment(0, 11),
                    ...this.computeSectionAssignment(3, 12),

                    ...this.computeSectionAssignment(1, 1),
                    ...this.computeSectionAssignment(4, 2),

                    ...this.computeSectionAssignment(2, 5),
                    ...this.computeSectionAssignment(5, 6),
                ];
            }
        } else if (this.cutout.options.flexagon_options.assignment_version === 'htf_1b3p2s') {
            return [
                ...this.computeSectionAssignment(0, 3),
                ...this.computeSectionAssignment(1, 1),
                ...this.computeSectionAssignment(2, 9),

                ...this.computeSectionAssignment(3, 5),
                ...this.computeSectionAssignment(4, 7),
                ...this.computeSectionAssignment(5, 11),
            ];
        } else if (this.cutout.options.flexagon_options.assignment_version === 'htf_6p') {
            return [
                ...this.computeSectionAssignment(0, 4),
                ...this.rotateSection(this.computeSectionAssignment(3, 9)),

                ...this.computeSectionAssignment(1, 14),
                ...this.rotateSection(this.computeSectionAssignment(4, 7)),

                ...this.computeSectionAssignment(2, 8),
                ...this.rotateSection(this.computeSectionAssignment(5, 3)),
            ];
        } else if (this.cutout.options.flexagon_options.assignment_version === 'htf_2p4s') {
            return [
                ...this.computeSectionAssignment(0, 5),
                ...this.computeSectionAssignment(1, 10),
                ...this.computeSectionAssignment(2, 11),

                ...this.computeSectionAssignment(3, 12),
                ...this.computeSectionAssignment(4, 13),
                ...this.computeSectionAssignment(5, 6),
            ];
        } else if (this.cutout.options.flexagon_options.assignment_version === 'htf_4p2s') {
            return [
                ...this.computeSectionAssignment(0, 3),
                ...this.computeSectionAssignment(1, 12),
                ...this.computeSectionAssignment(2, 7),

                ...this.computeSectionAssignment(3, 14),
                ...this.computeSectionAssignment(4, 5),
                ...this.computeSectionAssignment(5, 10),
            ];
        }

        throw new Error('Invalid assignment_version');
    }

    private rotateSection(assignments: FlexigonSubsectionAssignment[]): FlexigonSubsectionAssignment[] {
        const destinationMap = {
            'tl' : 'br',
            'tr' : 'bl',
            'bl' : 'tr',
            'br' : 'tl',
        };

        return assignments.map((assignment: FlexigonSubsectionAssignment) => {
            const destination = assignments.filter(
                a => a.subsection === destinationMap[assignment.subsection]
            )[0];

            return {
                appearance: assignment.appearance,
                side: assignment.side,
                squareCoord: destination.squareCoord,
                section: assignment.section,
                subsection: assignment.subsection,
                rotate: !assignment.rotate,
                sectionRotated: !assignment.sectionRotated,
            };
        });
    }

    private computeSectionAssignment(section: number, appearance: number): FlexigonSubsectionAssignment[] {
        if (appearance === 1) {
            return [
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b3, section: section, subsection: 'tl', rotate: true},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b5, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b8, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b10, section: section, subsection: 'br', rotate: true},
            ];
        } else if (appearance === 2) {
            return [
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f3, section: section, subsection: 'tl', rotate: true},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f5, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f8, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f10, section: section, subsection: 'br', rotate: true},
            ];
        } else if (appearance === 3) {
            return [
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f5, section: section, subsection: 'tl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f3, section: section, subsection: 'tr', rotate: true},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f10, section: section, subsection: 'bl', rotate: true},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f8, section: section, subsection: 'br', rotate: false},
            ];
        } else if (appearance === 4) {
            return [
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f2, section: section, subsection: 'tl', rotate: true},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f1, section: section, subsection: 'tr', rotate: true},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f12, section: section, subsection: 'bl', rotate: true},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f11, section: section, subsection: 'br', rotate: true},
            ];
        } else if (appearance === 5) {
            return [
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f1, section: section, subsection: 'tl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f2, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f11, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f12, section: section, subsection: 'br', rotate: false},
            ];
        } else if (appearance === 6) {
            return [
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b7, section: section, subsection: 'tl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b4, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b9, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b6, section: section, subsection: 'br', rotate: false},
            ];
        } else if (appearance === 7) {
            return [
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b4, section: section, subsection: 'tl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b7, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b6, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b9, section: section, subsection: 'br', rotate: false},
            ];
        } else if (appearance === 8) {
            return [
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b5, section: section, subsection: 'tl', rotate: true},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b3, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b10, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b8, section: section, subsection: 'br', rotate: true},
            ];
        } else if (appearance === 9) {
            return [
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b2, section: section, subsection: 'tl', rotate: true},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b1, section: section, subsection: 'tr', rotate: true},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b12, section: section, subsection: 'bl', rotate: true},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b11, section: section, subsection: 'br', rotate: true},
            ];
        } else if (appearance === 10) {
            return [
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b5, section: section, subsection: 'tl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b3, section: section, subsection: 'tr', rotate: true},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b10, section: section, subsection: 'bl', rotate: true},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b8, section: section, subsection: 'br', rotate: false},
            ];
        } else if (appearance === 11) {
            return [
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f7, section: section, subsection: 'tl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f4, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f9, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f6, section: section, subsection: 'br', rotate: false},
            ];
        } else if (appearance === 12) {
            return [
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b1, section: section, subsection: 'tl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b2, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b11, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'b', squareCoord: this.SQUARE_COORDINATES.b12, section: section, subsection: 'br', rotate: false},
            ];
        } else if (appearance === 13) {
            return [
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f5, section: section, subsection: 'tl', rotate: true},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f3, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f10, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f8, section: section, subsection: 'br', rotate: true},
            ];
        } else if (appearance === 14) {
            return [
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f4, section: section, subsection: 'tl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f7, section: section, subsection: 'tr', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f6, section: section, subsection: 'bl', rotate: false},
                {appearance, side: 'f', squareCoord: this.SQUARE_COORDINATES.f9, section: section, subsection: 'br', rotate: false},
            ];
        } else {
            throw new Error('Invalid appearance value');
        }
    }

    public async generateVisualisation(
        cache: Cache,
        progressCallback: ((evt) => void) | null,
        copyrightText: string,
    ): Promise<HTMLImageElement> {
        const scale = 2;

        const diagram = await downloadImage(hexatetraflexagonDiagram);
        const w = diagram.width;
        const h = diagram.height;

        const canvas = document.createElement('canvas');
        canvas.width = scale * 2 * w;
        canvas.height = scale * h;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(diagram, 0, 0, scale * w, scale * h); // Draw left
        ctx.drawImage(diagram, scale * w, 0, scale * w, scale * h); // Draw right

        // Hide transitions copy
        ctx.save();
        ctx.fillStyle = '#ffffff';
        ctx.rect(scale * w, scale * (h - 200), scale * w / 2, scale * 200);
        ctx.fill();
        ctx.restore();
        ctx.fillText(copyrightText, scale * w, scale * (h - 150), scale * w / 2);

        const appearanceAnchors = {
            1: [248, 415],
            2: [380, 415],
            3: [59, 233],
            4: [191, 233],
            5: [274, 36],
            6: [407, 36],
            7: [463, 222],
            8: [596, 222],
            9: [460, 612],
            10: [593, 612],
            11: [258, 803],
            12: [390, 803],
            13: [81, 607],
            14: [213, 607],
        };
        const appearanceSubsectionSize = [53, 53];

        const sideAnchors = {
            'f': [435, 952],
            'b': [611, 952],
        };
        const sideSize = [37, 37];

        const flexagonAssignment = this.getFlexagonAssignment();

        let sidesDone = 0;
        const newProgressCallback = progressCallback ? ({done, total}) => {
            progressCallback({
                done: sidesDone + done / total,
                total: flexagonAssignment.length,
            });
        } : null;

        for (const assignment of flexagonAssignment) {
            const {appearance, side, squareCoord: [col, row], subsection, rotate} = assignment;

            const [xmin, xmax, ymin, ymax] = this.computeProjectionBox(assignment);

            const img = await this.cutout.getProjection().projectToImage(cache, newProgressCallback, xmin, xmax, ymin, ymax);

            const [dx, dy] = appearanceAnchors[appearance];

            // Appearance
            ctx.save();
            ctx.translate(
                scale * (w + dx + appearanceSubsectionSize[0]),
                scale * (dy + appearanceSubsectionSize[1]),
            );
            if (assignment.sectionRotated) {
                ctx.rotate(Math.PI);
            }
            ctx.drawImage(
                img,
                scale * (-appearanceSubsectionSize[0] + (subsection === 'tr' || subsection === 'br' ? appearanceSubsectionSize[0] + 1 : 0)),
                scale * (-appearanceSubsectionSize[1] + (subsection === 'bl' || subsection === 'br' ? appearanceSubsectionSize[1] + 1 : 0)),
                scale * appearanceSubsectionSize[0],
                scale * appearanceSubsectionSize[1],
            );
            ctx.restore();

            // Side
            const sideCoordX = sideAnchors[side][0] + col * sideSize[0];
            const sideCoordY = sideAnchors[side][1] + row * sideSize[1];
            ctx.save();
            ctx.translate(
                scale * (w + sideCoordX + sideSize[0] / 2),
                scale * (sideCoordY + sideSize[1] / 2),
            );
            if (rotate) {
                ctx.rotate(Math.PI);
            }
            ctx.drawImage(
                img,
                -scale * sideSize[0] / 2,
                -scale * sideSize[1] / 2,
                scale * sideSize[0],
                scale * sideSize[1],
            );
            ctx.restore();
            sidesDone++;
        }

        return canvasToPngImage(canvas);
    }
}
