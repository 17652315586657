import CoordinateSystem from "../Coordinates/CoordinateSystem";
import CoordinateConverter from "../Util/CoordinateConverter";
import Coordinate from "../Coordinates/Coordinate";
import Container from "../Main/Container";
import MapImageProvider from "./MapImageProvider";
import {GridSpec} from "../Main/Grid";
import UserError from "../Util/UserError";
import {copyObject, findChildNode} from "../Util/functions";

import $ from "jquery";
import WGS84 from "../Coordinates/WGS84";

export type WmsParams = {
    version?: string,
    service?: string,
    request?: string,
    CRS?: string,
    styles?: string,
    layers?: string,
    format?: string,
    bbox?: string,
    width?: string,
    height?: string,
};

export type ScaleRange = { min: number|null, max: number|null };

export default class Wms implements MapImageProvider {

    private capabilities : Document = null;

    readonly params: WmsParams;

    private mipDrawnGrid: GridSpec = null;

    private customBoundingPolygon: Coordinate[] = null;

    private defaultDpi: number|null = null;

    constructor(
        readonly name: string,
        readonly title: string,
        readonly url: string,
        readonly copyright: string,
        private compatibleCoordinateSystems: CoordinateSystem<Coordinate>[],
        private defaultScale: number,
        params: WmsParams = {}
    ) {
        this.params = copyObject({
            version: '1.3.0',
            service: 'WMS',
        }, params);
    }

    includesDrawnGrid(base_x: number, delta_x: number, base_y: number, delta_y: number) {
        this.mipDrawnGrid = {type: null, base_x, delta_x, mul_x: 1, add_x: 0, base_y, delta_y, mul_y: 1, add_y: 0};
        return this;
    }

    getDrawnGrid(): GridSpec {
        return this.mipDrawnGrid;
    }

    hasDrawnGrid(): boolean {
        return this.mipDrawnGrid !== null;
    }

    getCopyright(): string {
        return this.copyright;
    }

    isCompatibleWithCoordinateSystem(coordinateSystem: CoordinateSystem<Coordinate>): boolean {
        for (const cs of this.compatibleCoordinateSystems) {
            if (cs.code === coordinateSystem.code) {
                return true;
            }
        }
        return false;
    }

    getCompatibleCoordinateSystems(): CoordinateSystem<Coordinate>[] {
        return this.compatibleCoordinateSystems;
    }

    getDefaultCoordinateSystem(): CoordinateSystem<any> {
        if(!this.params.hasOwnProperty('CRS') || this.params['CRS'].length === 0) {
            throw new Error('No default coordinate system set');
        }
        return CoordinateConverter.getCoordinateSystem(this.params['CRS']);
    }

    getDefaultScale(): number {
        return this.defaultScale;
    }

    buildUrl(params: WmsParams) {
        params = copyObject(this.params, params);

        return this.url + '?' + $.param(params);
    }

    mapUrl(params: WmsParams) {
        params = copyObject({
            request: 'GetMap',
            styles: 'default',
            format: 'image/png',
            width: '2000',
            height: '2000',
        }, params);

        return this.buildUrl(params);
    }

    getCapabilitiesOrThrow(): Document {
        if(this.capabilities === null) {
            this.fetchCapabilities();
            throw new UserError('De configuratie wordt nog ingeladen, probeer het over een paar seconden opnieuw');
        }

        return this.capabilities;
    }

    fetchCapabilities(): Promise<Document> {
        if(this.capabilities !== null) {
            return Promise.resolve(this.capabilities);
        }

        const url = this.buildUrl({ request: 'GetCapabilities' });

        return Container.getCache().then((cache) => {
            return cache.fetch(url, () => {
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.responseType = 'text';
                    xhr.onload = function (e) {
                        resolve(xhr.responseText);
                    };
                    xhr.addEventListener('error', () => {
                        alert('De bronserver kon niet bereikt worden');
                        reject('De bronserver kon niet bereikt worden');
                    });
                    xhr.send(null);
                });
            }).then((capabilitiesString) => {
                const parser = new DOMParser();
                const xml = parser.parseFromString(capabilitiesString, 'application/xml');

                if(xml.documentElement.nodeName == "parsererror") {
                    throw new Error('Xml parser error');
                } else {
                    this.capabilities = xml;
                    return xml;
                }
            });
        });
    }

    private getLayerNode(): Node {
        const xmlDoc = this.getCapabilitiesOrThrow();

        const capabilitiesNode = findChildNode(xmlDoc.getRootNode(), (node: Node) => {
            return node instanceof Element && node.tagName === 'WMS_Capabilities';
        });
        if(capabilitiesNode === null) {
            throw new UserError('Could not find layer info (wms_capabilities)');
        }

        const capabilityNode = findChildNode(capabilitiesNode, (node: Node) => {
            return node instanceof Element && node.tagName === 'Capability';
        });
        if(capabilityNode === null) {
            throw new UserError('Could not find layer info (capability)');
        }

        const layersNode = findChildNode(capabilityNode, (node: Node) => {
            return node instanceof Element && node.tagName === 'Layer';
        });
        if(layersNode === null) {
            throw new UserError('Could not find layer info (layers)');
        }

        const layerNode = findChildNode(layersNode, (node: Node) => {
            if(!(node instanceof Element && node.tagName === 'Layer')) {
                return false;
            }
            const nameNode = findChildNode(node, (childNode: Node) => {
                return childNode instanceof Element && childNode.tagName === 'Name';
            });
            return nameNode !== null && nameNode.textContent === this.params.layers;
        });

        if(layerNode !== null) {
            return layerNode;
        }

        // Search 2 deep
        let layerNode2Deep = null;
        findChildNode(layersNode, (node1: Node) => {
            if(!(node1 instanceof Element && node1.tagName === 'Layer')) {
                return false;
            }

            return !!findChildNode(node1, (node2: Node) => {
                if(!(node2 instanceof Element && node2.tagName === 'Layer')) {
                    return false;
                }
                const nameNode = findChildNode(node2, (childNode: Node) => {
                    return childNode instanceof Element && childNode.tagName === 'Name';
                });
                if (nameNode !== null && nameNode.textContent === this.params.layers) {
                    layerNode2Deep = node2;
                    return true;
                }

                return false;
            });
        });
        if (layerNode2Deep) {
            return layerNode2Deep;
        }

        throw new UserError('Could not find layer info (layer)');
    }

    getSuggestedScaleRange(): Promise<ScaleRange> {
        return this.fetchCapabilities().then((xmlDoc) => {
            const layerNode = this.getLayerNode();

            let minNode = findChildNode(layerNode, (childNode: Node) => {
                return childNode instanceof Element && childNode.tagName === 'MinScaleDenominator';
            });
            if(minNode === null) {
                minNode = findChildNode(layerNode.parentNode, (childNode: Node) => {
                    return childNode instanceof Element && childNode.tagName === 'MinScaleDenominator';
                });
            }

            let maxNode = findChildNode(layerNode, (childNode: Node) => {
                return childNode instanceof Element && childNode.tagName === 'MaxScaleDenominator';
            });
            if(maxNode === null) {
                maxNode = findChildNode(layerNode.parentNode, (childNode: Node) => {
                    return childNode instanceof Element && childNode.tagName === 'MaxScaleDenominator';
                });
            }

            return <ScaleRange> {
                min: minNode === null ? null : parseFloat(minNode.textContent),
                max: maxNode === null ? null : parseFloat(maxNode.textContent),
            };
        });
    }

    setCustomBoundingPolygon<C extends Coordinate>(polygon: C[]): this
    {
        this.customBoundingPolygon = polygon;
        return this;
    }

    getBoundingPolygon<C extends Coordinate>(coordinateSystem: CoordinateSystem<C>): Promise<C[]|null> {
        if (this.customBoundingPolygon) {
            return Promise.resolve(CoordinateConverter.convertPolygon(this.customBoundingPolygon, coordinateSystem));
        }

        return this.fetchCapabilities().then(() => {
            const layerNode = this.getLayerNode();

            const boundingBoxNode = findChildNode(layerNode, (node: Node) => {
                return node instanceof Element
                    && node.tagName === 'BoundingBox'
                    && node.getAttribute('CRS') === coordinateSystem.code;
            });

            if(boundingBoxNode instanceof Element) {
                // Found the right bounding box, return it!
                const xMin = parseFloat(boundingBoxNode.getAttribute(coordinateSystem.code !== 'EPSG:4326' ? 'minx' : 'miny'));
                const yMin = parseFloat(boundingBoxNode.getAttribute(coordinateSystem.code !== 'EPSG:4326' ? 'miny' : 'minx'));
                const xMax = parseFloat(boundingBoxNode.getAttribute(coordinateSystem.code !== 'EPSG:4326' ? 'maxx' : 'maxy'));
                const yMax = parseFloat(boundingBoxNode.getAttribute(coordinateSystem.code !== 'EPSG:4326' ? 'maxy' : 'maxx'));

                return <C[]>[
                    coordinateSystem.make(xMin, yMin),
                    coordinateSystem.make(xMin, yMax),
                    coordinateSystem.make(xMax, yMax),
                    coordinateSystem.make(xMax, yMin),
                ];
            }

            // The Capabilities file does not provide a bounding box in the requested coordinate system.
            // However, we can try and find the WGS84 bounding box and convert it
            if (coordinateSystem.code === 'EPSG:4326') {
                // A WGS84 box is not provided by the capabilities file... but perhaps it does have geographic bounds?
                return <C[]>this.getBoundingPolygonFromGeographicBoundingBox(layerNode);
            }

            return this.getBoundingPolygon(CoordinateConverter.getCoordinateSystem('EPSG:4326')).then((wgs84Polygon) => {
                if (wgs84Polygon === null) {
                    return null;
                }

                return CoordinateConverter.convertPolygon(wgs84Polygon, coordinateSystem);
            });
        });
    }

    private getBoundingPolygonFromGeographicBoundingBox(layerNode: Node): WGS84[]|null {
        const boundingBoxNode = findChildNode(layerNode, (node: Node) => {
            return node instanceof Element
                && node.tagName === 'EX_GeographicBoundingBox';
        });

        if(!(boundingBoxNode instanceof Element)) {
            return null;
        }

        const west = findChildNode(boundingBoxNode, (node: Node) => {
            return node instanceof Element && node.tagName === 'westBoundLongitude';
        });
        const east = findChildNode(boundingBoxNode, (node: Node) => {
            return node instanceof Element && node.tagName === 'eastBoundLongitude';
        });
        const south = findChildNode(boundingBoxNode, (node: Node) => {
            return node instanceof Element && node.tagName === 'southBoundLatitude';
        });
        const north = findChildNode(boundingBoxNode, (node: Node) => {
            return node instanceof Element && node.tagName === 'northBoundLatitude';
        });

        if(!(west instanceof Element) || !(east instanceof Element) || !(south instanceof Element) || !(north instanceof Element)) {
            return null;
        }

        return [
            new WGS84(parseFloat(west.textContent), parseFloat(south.textContent)),
            new WGS84(parseFloat(west.textContent), parseFloat(north.textContent)),
            new WGS84(parseFloat(east.textContent), parseFloat(north.textContent)),
            new WGS84(parseFloat(east.textContent), parseFloat(south.textContent)),
        ];
    }

    setDefaultDpi(dpi: number): this
    {
        this.defaultDpi = dpi;

        return this;
    }

    getDefaultDpi(): number|null
    {
        return this.defaultDpi;
    }

    downloadLegend() {
        const layerNode = this.getLayerNode();

        const styleNode = findChildNode(layerNode, (childNode: Node) => {
            return childNode instanceof Element && childNode.tagName === 'Style';
        });

        const legendNode = styleNode === null ? null : findChildNode(styleNode, (childNode: Node) => {
            return childNode instanceof Element && childNode.tagName === 'LegendURL';
        });

        const resourceNode = legendNode === null ? null : findChildNode(legendNode, (childNode: Node) => {
            return childNode instanceof Element && childNode.tagName === 'OnlineResource';
        });

        if(resourceNode instanceof Element) {
            // @ts-ignore
            const url = resourceNode.getAttribute('xlink:href').valueOf();

            window.open(url);
        } else {
            alert('Could not find legend URL');
        }
    }
}



/*
const { jsPDF } = window.jspdf;
Wms.pdf = function() {
    $.get(Wms.doGetMapUrl(), function(data) {console.log(data);
        var img = document.createElement('img');
        //img.src = 'data:image/png;base64,' + Base64.encodeURI(data);
        //img.src = URL.createObjectURL(new Blob(data, {type : 'image/png'}));
        //$('.cutoutList')[0].appendChild(img);

        //var fr = new FileReader();
        //fr.onload = function(e) {console.log(fr.result);
        //	img.src = fr.result; //Display saved icon
        //	$('.cutoutList')[0].appendChild(img);
        //};
        //fr.readAsDataURL(new Blob([data], {type : 'image/png'}));

        var xhr = new XMLHttpRequest();
        xhr.open('GET', Wms.doGetMapUrl(), true);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            var icon_blob = xhr.response; //That can be saved to db


            var fr = new FileReader();
            fr.onload = function(e) {
                img.src = fr.result; //Display saved icon
                $('.cutoutList')[0].appendChild(img);
            };
            fr.readAsDataURL(icon_blob);
        };
        xhr.send(null);

    }, 'text')

    //var img = new Image();
    //img.src = Wms.doGetMapUrl();
    //img.onload = function () {
    //	const doc = new jsPDF();
    //	doc.addImage(img, 'PNG', 10, 20, 50, 50);
    //	doc.save("a4.pdf");
    //};

    //const doc = new jsPDF();
    //doc.addImage(Wms.doGetMapUrl(), 'PNG', 10, 20, 50, 50);
    //doc.save("a4.pdf");
}
*/
