import Action from "./Action";
import Cutout from "../Main/Cutout";
import {Serialization} from "../Main/Serializer";

export default class ChangeCutoutAdvancedSettingsModeAction implements Action {

    private readonly oldMode: boolean;
    private oldAdvancedSettings: Partial<Cutout>;

    constructor(private cutout: Cutout<any, any, any>, private newMode: boolean) {
        this.oldMode = this.cutout.options.advanced_settings_mode;

        if (this.oldMode !== !newMode) {
            throw new Error('Invalid new mode');
        }
    }

    public apply() {
        if (this.newMode) {
            this.cutout.setAdvancedSettingsMode(true);
        } else {
            this.oldAdvancedSettings = this.cutout.resetAdvancedSettings();
        }
    }

    public revert() {
        if (this.newMode) {
            this.cutout.setAdvancedSettingsMode(false);
        } else {
            this.cutout.reapplyAdvancedSettings(this.oldAdvancedSettings);
        }
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldMode: this.oldMode,
            newMode: this.newMode,
        };
    }
}
