import Action from "./Action";
import Cutout from "../Main/Cutout";
import {GridSpec} from "../Main/Grid";
import {Serialization} from "../Main/Serializer";
import {copyObject} from "../Util/functions";

export default class ChangeCutoutGridTypeAction implements Action {

    private readonly oldCustomGridSpec: GridSpec|null;
    private readonly newCustomGridSpec: GridSpec|null;

    constructor(private cutout: Cutout<any, any, any>, private newSetting: 'auto'|'manual'|'manual_affine') {
        const grid = this.cutout.getGrid();
        this.oldCustomGridSpec = grid.getCustomGridSpec();

        if(newSetting === 'manual' || newSetting === 'manual_affine') {
            this.newCustomGridSpec = copyObject(grid.getGridSpec());
            this.newCustomGridSpec.type = newSetting;

            if (newSetting === 'manual') {
                this.newCustomGridSpec.mul_x = 1;
                this.newCustomGridSpec.add_x = 0;
                this.newCustomGridSpec.mul_y = 1;
                this.newCustomGridSpec.add_y = 0;
            }
        } else {
            this.newCustomGridSpec = null;
        }
    }

    public apply() {
        const grid = this.cutout.getGrid();
        grid.setCustomGridSpec(this.newCustomGridSpec);
    }

    public revert() {
        const grid = this.cutout.getGrid();
        grid.setCustomGridSpec(this.oldCustomGridSpec);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldCustomGridSpec: this.oldCustomGridSpec,
            newCustomGridSpec: this.newCustomGridSpec,
        };
    }
}
