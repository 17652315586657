import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import {IntermediatesMeta} from "../Main/RouteIntermediates";
import {Options} from "roughjs/bin/core";
import {randomInt} from "../Util/Math";
import UserError from "../Util/UserError";
import {createSvgEl} from "./Util/Svg";

type VectorConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    displayScale: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
};

type Arrow = {
    originX: number,
    tipLocationX: number,
    tipLocationY: number,
    tipUnitX: number,
    tipUnitY: number,
    perpUnitX: number,
    perpUnitY: number,
};

export default class Vector extends ImageGeneratingTechnique<VectorConfig>
{
    public static readonly TECHNIQUE_NAME = 'vector';
    public static readonly TECHNIQUE_TITLE = 'Vectorroute';
    public static readonly TECHNIQUE_WIKI = 'vectorroute';

    protected readonly requiresIntermediates = true;

    protected getDefaultConfig(): VectorConfig {
        return {
            format: 'png',
            transparent: false,
            displayScale: true,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            seed: this.config.roughJsSeed,
        };
    }

    static readonly ARROW_PADDING = 0.3;
    static readonly ARROW_TIP_LENGTH = 0.2;

    protected generateSvg(): SVGSVGElement
    {
        const intermediatesMeta = this.getIntermediatesMeta();

        let maxD = null;
        for (let i = 0; i < intermediatesMeta.intermediateMetas.length; i++) {
            const intermediate = intermediatesMeta.intermediateMetas[i];

            if (maxD === null || intermediate.directDistance > maxD) {
                maxD = intermediate.directDistance;
            }
        }

        if (maxD === null) {
            maxD = 100;
        }

        const arrows = this.computeArrows(intermediatesMeta, maxD);

        const imageWidth = (1.2 + Vector.ARROW_PADDING) * maxD + (arrows.length ? Math.max(arrows[arrows.length - 1].originX, arrows[arrows.length - 1].tipLocationX) : 0);
        const imageHeight = 2.2 * maxD;

        const offsetX = 1.1 * maxD;
        const offsetY = 1.1 * maxD;

        const svg = createSvgEl('svg', {
            width: (200 * imageWidth / imageHeight) + 'px',
            height: '200px',
            viewBox: "0 0 " + imageWidth + " " + imageHeight,
        });

        svg.appendChild(createSvgEl('polyline', {
            points: [
                (offsetX) + ',' + (offsetY + 0.05 * maxD),
                (offsetX - 0.85 * maxD) + ',' + (offsetY + 0.05 * maxD),
                (offsetX - 0.7 * maxD) + ',' + (offsetY + 0.2 * maxD),
                (offsetX - 0.75 * maxD) + ',' + (offsetY + 0.25 * maxD),
                (offsetX - maxD) + ',' + (offsetY),
                (offsetX - 0.75 * maxD) + ',' + (offsetY - 0.25 * maxD),
                (offsetX - 0.7 * maxD) + ',' + (offsetY - 0.2 * maxD),
                (offsetX - 0.85 * maxD) + ',' + (offsetY - 0.05 * maxD),
                (offsetX) + ',' + (offsetY - 0.05 * maxD),
                (offsetX) + ',' + (offsetY + 0.05 * maxD),
            ].join(' '),
            stroke: '#000000',
            strokeWidth: 0.01 * maxD,
            fill: 'none',
        }));

        const northText = createSvgEl('text', {
            x: offsetX - 0.9 * maxD,
            y: offsetY - 0.3 * maxD,
            textAnchor: 'start',
            fill: '#000000',
            lengthAdjust: 'spacingAndGlyphs',
            textLength: 0.6 * maxD,
        });
        northText.textContent = 'NOORD';
        northText.style.font = (maxD * 0.2) + 'px sans-serif';
        svg.appendChild(northText);

        svg.appendChild(createSvgEl('line', {
            x1: offsetX,
            y1: offsetY,
            x2: imageWidth - 0.1 * maxD - 0.5 * Vector.ARROW_PADDING * maxD,
            y2: offsetY,
            stroke: '#000000',
            strokeWidth: 0.01 * maxD,
        }));

        for (const arrow of arrows) {
            svg.appendChild(createSvgEl('circle', {
                cx: offsetX + arrow.originX,
                cy: offsetY,
                r: 0.05 * maxD,
                stroke: 'none',
                fill: '#000000',
            }));

            const arrowBaseX = offsetX + arrow.tipLocationX - arrow.tipUnitX * maxD * Vector.ARROW_TIP_LENGTH;
            const arrowBaseY = offsetY + arrow.tipLocationY - arrow.tipUnitY * maxD * Vector.ARROW_TIP_LENGTH;

            svg.appendChild(createSvgEl('line', {
                x1: offsetX + arrow.originX,
                y1: offsetY,
                x2: offsetX + arrow.tipLocationX,
                y2: offsetY + arrow.tipLocationY,
                stroke: '#000000',
                strokeWidth: 0.025 * maxD,
            }));

            svg.appendChild(createSvgEl('polyline', {
                points: [
                    (arrowBaseX + arrow.perpUnitX * maxD * 0.15) + ',' + (arrowBaseY + arrow.perpUnitY * maxD * 0.15),
                    (offsetX + arrow.tipLocationX) + ',' + (offsetY + arrow.tipLocationY),
                    (arrowBaseX - arrow.perpUnitX * maxD * 0.15) + ',' + (arrowBaseY - arrow.perpUnitY * maxD * 0.15),
                ].join(' '),
                stroke: '#000000',
                strokeWidth: 0.025 * maxD,
                fill: 'none',
            }));
        }

        if (this.config.displayScale) {
            let scaleWidth = 10 ** Math.floor(Math.log10(maxD));
            if (scaleWidth < maxD / 5) {
                scaleWidth *= 5;
            } else if (scaleWidth < maxD / 2) {
                scaleWidth *= 2;
            }

            const scaleRefX = 0.1 * maxD;
            const scaleRefY = imageHeight - 0.1 * maxD;
            svg.appendChild(createSvgEl('polyline', {
                points: [
                    (scaleRefX) + ',' + (scaleRefY - 0.2 * maxD),
                    (scaleRefX) + ',' + (scaleRefY),
                    (scaleRefX + scaleWidth) + ',' + (scaleRefY),
                    (scaleRefX + scaleWidth) + ',' + (scaleRefY - 0.2 * maxD),
                ].join(' '),
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 0.01 * maxD,
            }));

            const scaleText = createSvgEl('text', {
                x: scaleRefX + 0.5 * scaleWidth,
                y: scaleRefY - 0.1 * maxD,
                textAnchor: 'middle',
                fill: '#000000',
            });
            scaleText.textContent = scaleWidth + 'm';
            scaleText.style.font = (maxD * 0.2) + 'px sans-serif';
            svg.appendChild(scaleText);
        }

        this.route.routeCollection.userInterface.toasts.addRouteTechniqueScaleToast('Vectorroute', imageWidth);

        return svg;
    }

    computeArrows(intermediatesMeta: IntermediatesMeta, maxD: number): Arrow[] {
        const arrowPadding = Vector.ARROW_PADDING * maxD;

        let cursorX = 0;

        const arrows = <Arrow[]>[];

        for (const intermediate of intermediatesMeta.intermediateMetas) {
            if (intermediate.directBearing === null || intermediate.directDistance === null) {
                throw new UserError('Het laatste beslispunt in de route mag voor deze routetechniek niet aan het einde van de route liggen.');
            }

            const angle = (90 + (90 - intermediate.directBearing) + 360) % 360;

            const tipUnitX = Math.cos(angle / 180 * Math.PI);
            const tipUnitY = -Math.sin(angle / 180 * Math.PI);

            const tipVectorX = tipUnitX * Math.max(intermediate.directDistance, 0.75 * Vector.ARROW_TIP_LENGTH * maxD);
            const tipVectorY = tipUnitY * Math.max(intermediate.directDistance, 0.75 * Vector.ARROW_TIP_LENGTH * maxD);

            let originX, tipLocationX, tipLocationY;
            if (angle > 90 && angle < 270) {
                // Arrow to the left
                originX = cursorX - tipVectorX;
                tipLocationX = cursorX;
                tipLocationY = tipVectorY;

                cursorX = originX + arrowPadding;
            } else {
                // Arrow to the right
                originX = cursorX;
                tipLocationX = cursorX + tipVectorX;
                tipLocationY = tipVectorY;

                cursorX = tipLocationX + arrowPadding;
            }

            arrows.push({
                originX,
                tipLocationX,
                tipLocationY,
                tipUnitX,
                tipUnitY,
                perpUnitX: -tipUnitY,
                perpUnitY: tipUnitX,
            });
        }

        return arrows;
    }
}
