import Action from "./Action";
import Cutout from "../Main/Cutout";
import {GridSpec} from "../Main/Grid";
import {Serialization} from "../Main/Serializer";
import {copyObject} from "../Util/functions";

export default class ChangeCutoutFlexagonSizeAutoAction implements Action {

    private readonly oldWidth: number;
    private readonly oldHeight: number;

    constructor(private cutout: Cutout<any, any, any>, private newSizeAuto: boolean) {
        this.oldWidth = this.cutout.options.flexagon_options.width;
        this.oldHeight = this.cutout.options.flexagon_options.height;
    }

    public apply() {
        if (this.newSizeAuto) {
            this.cutout.setFlexagonOption('width', null);
            this.cutout.setFlexagonOption('height', null);
        } else {
            const autoSize = this.cutout.computeFlexagonSize();
            this.cutout.setFlexagonOption('width', autoSize.width);
            this.cutout.setFlexagonOption('height', autoSize.height);
        }

        this.cutout.updateMap();
    }

    public revert() {
        this.cutout.setFlexagonOption('width', this.oldWidth);
        this.cutout.setFlexagonOption('height', this.oldHeight);

        this.cutout.updateMap();
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldWidth: this.oldWidth,
            oldHeight: this.oldHeight,
        };
    }
}
