import Action from "./Action";
import Cutout from "../Main/Cutout";
import WmsProjection from "../Projection/WmsProjection";
import WmtsProjection from "../Projection/WmtsProjection";
import OlProjection from "../Projection/OlProjection";
import EmptyProjection from "../Projection/EmptyProjection";
import OlVectorProjection from "../Projection/OlVectorProjection";
import {Serialization} from "../Main/Serializer";

export default class ChangeCutoutScaleAction implements Action {

    private readonly oldScale: number;

    constructor(private cutout: Cutout<any, any, any>, private newScale: number) {
        const projection = this.cutout.getProjection();
        if(!(
            projection instanceof WmsProjection
            || projection instanceof WmtsProjection
            || projection instanceof OlProjection
            || projection instanceof OlVectorProjection
            || projection instanceof EmptyProjection
        )) {
            throw new Error('Can only change scale for WMS/WMTS/OL/OL-vector/Empty projection');
        }
        this.oldScale = projection.getScale();
    }

    public apply() {
        const projection = this.cutout.getProjection();
        if(!(
            projection instanceof WmsProjection
            || projection instanceof WmtsProjection
            || projection instanceof OlProjection
            || projection instanceof OlVectorProjection
            || projection instanceof EmptyProjection
        )) {
            throw new Error('Can only set scale for WMS/WMTS/OL/OL-vector/Empty projection');
        }
        projection.setScale(this.newScale);
    }

    public revert() {
        const projection = this.cutout.getProjection();
        if(!(
            projection instanceof WmsProjection
            || projection instanceof WmtsProjection
            || projection instanceof OlProjection
            || projection instanceof OlVectorProjection
            || projection instanceof EmptyProjection
        )) {
            throw new Error('Can only set scale for WMS/WMTS/OL/OL-vector/Empty projection');
        }
        projection.setScale(this.oldScale);
    }

    public merge(newAction: Action): boolean {
        if(!(newAction instanceof ChangeCutoutScaleAction) || newAction.cutout !== this.cutout || newAction.oldScale !== this.newScale) {
            return false;
        }

        if((this.newScale > this.oldScale) !== (newAction.newScale > newAction.oldScale)) {
            return false;
        }

        this.newScale = newAction.newScale;
        this.apply();

        return true;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldScale: this.oldScale,
            newScale: this.newScale,
        };
    }
}
