<template>
  <div v-bind:class="'control-pane control-pane-sketch-route-panel ' + (active ? '' : 'd-none')" id="sketchRoute">
    <button type="button" class="btn-close" aria-label="Close" v-on:click="closeSketchRoutePanel" title="Sluiten"></button>

    <button type="button" class="btn-close make-route-from-sketch" aria-label="Close" v-on:click="makeRouteFromSketch" title="Maak route" v-if="!userInterface.isLocked()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
    </button>

    <div class="control-pane-content" id="sketchRouteControlPanelContent">
      <div>Lengte: {{ length }}</div>
      <div v-if="area !== null">Oppervlakte: {{ area }}</div>
      <div v-if="bearing !== null">Richting: {{ bearing }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import UserInterface from "../Main/UserInterface";

export default {
  props: {
    userInterface: <UserInterface>null,
  },
  data () {
    const route = this.userInterface.getRouteCollection().getSketchRoute();

    route.onChange(() => {
      this.sketchRoutePanelRecomputeCounter++;
    });

    return {
      routeRP: route.reactiveProps,
      sketchRoutePanelRecomputeCounter: 0,
    };
  },
  watch: {

  },
  computed: {
    active: function() {
      return this.routeRP.hasFocus;
    },
    length: function() {
      //this.sketchRoutePanelRecomputeCounter;

      return this.routeRP.formattedLength;
    },
    area: function() {
      //this.sketchRoutePanelRecomputeCounter;

      return this.routeRP.formattedArea;
    },
    bearing: function() {
      //this.sketchRoutePanelRecomputeCounter;

      return this.routeRP.formattedBearing;
    },
  },
  methods: {
    closeSketchRoutePanel() {
      this.userInterface.getRouteCollection().stopSketchRoute();
    },
    makeRouteFromSketch() {
      this.userInterface.getRouteCollection().addRouteFromSketch();
    },
  }
};
</script>

<style scoped>

.control-pane-sketch-route-panel {
  width: 225px;
  font-size: 90%;
}

#sketchRouteControlPanelContent > div + div {
  margin-top: .25rem;
}

.control-pane-sketch-route-panel .btn-close {
  float: right;
  margin-top: -12px;
  margin-right: -6px;
}

.control-pane-sketch-route-panel .btn-close svg {
  vertical-align: baseline;
}

.control-pane-sketch-route-panel .btn-close.make-route-from-sketch {
  background: white;
  margin-top: -13px;
  margin-right: 3px;
}

</style>
