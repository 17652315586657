import Action from "./Action";
import Location from "../Main/Location";
import {Serialization} from "../Main/Serializer";

export default class LocationChangeNameAction implements Action {

    private readonly oldName: string;

    constructor(private location: Location, private newName: string) {
        this.oldName = this.location.getName();
    }

    public apply() {
        this.location.setName(this.newName);
    }

    public revert() {
        this.location.setName(this.oldName);
    }

    public merge(newAction: Action): boolean {
        if(!(newAction instanceof LocationChangeNameAction) || newAction.location !== this.location || newAction.oldName !== this.newName) {
            return false;
        }

        if((this.newName.length > this.oldName.length) !== (newAction.newName.length > newAction.oldName.length)) {
            return false;
        }

        this.newName = newAction.newName;
        this.apply();

        return true;
    }

    public serializeForDebug(): Serialization {
        return {
            location: this.location.id,
            oldName: this.oldName,
            newName: this.newName,
        };
    }
}
