<template>
  <div id="routeIntermediatesPanel">
    <div class="mb-3">
      <select class="form-select form-select-sm" id="routech_route" v-on:change="changeRoute">
        <option value="">Selecteer route...</option>
        <option
            v-for="routeRP in routechRouteRPs"
            :key="routeRP.id"
            v-bind:value="routeRP.id"
            v-bind:selected="currentRouteRP?.id === routeRP.id"
        >{{ routeRP.name }}{{ routeRP.intermediatesRP.routeTechniqueRP ? ' - ' + routeRP.intermediatesRP.routeTechniqueRP.title : '' }}</option>
      </select>
    </div>

    <div v-if="currentRouteRP">
      <div class="btn-group btn-group-sm" role="group" v-if="!locked">
        <button
            type="button"
            :class="'btn btn-' + (currentRouteRP.editMode === 'draw' ? '' : 'outline-') + 'secondary pt-0 route-intermediates-mode-draw'"
            v-on:click="selectMode('draw')"
            title="Teken route (Q)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>
        </button>

        <button
            type="button"
            :class="'btn btn-' + (currentRouteRP.editMode === 'intermediate' ? '' : 'outline-') + 'secondary pt-0 route-intermediates-mode-intermediate'"
            v-on:click="selectMode('intermediate')"
            title="Nieuw beslispunt (W)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-dotted" viewBox="0 0 16 16">
            <path d="M2.5 0c-.166 0-.33.016-.487.048l.194.98A1.51 1.51 0 0 1 2.5 1h.458V0H2.5zm2.292 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zm1.833 0h-.916v1h.916V0zm1.834 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zM13.5 0h-.458v1h.458c.1 0 .199.01.293.029l.194-.981A2.51 2.51 0 0 0 13.5 0zm2.079 1.11a2.511 2.511 0 0 0-.69-.689l-.556.831c.164.11.305.251.415.415l.83-.556zM1.11.421a2.511 2.511 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415L1.11.422zM16 2.5c0-.166-.016-.33-.048-.487l-.98.194c.018.094.028.192.028.293v.458h1V2.5zM.048 2.013A2.51 2.51 0 0 0 0 2.5v.458h1V2.5c0-.1.01-.199.029-.293l-.981-.194zM0 3.875v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 5.708v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 7.542v.916h1v-.916H0zm15 .916h1v-.916h-1v.916zM0 9.375v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .916v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .917v.458c0 .166.016.33.048.487l.98-.194A1.51 1.51 0 0 1 1 13.5v-.458H0zm16 .458v-.458h-1v.458c0 .1-.01.199-.029.293l.981.194c.032-.158.048-.32.048-.487zM.421 14.89c.183.272.417.506.69.689l.556-.831a1.51 1.51 0 0 1-.415-.415l-.83.556zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373c.158.032.32.048.487.048h.458v-1H2.5c-.1 0-.199-.01-.293-.029l-.194.981zM13.5 16c.166 0 .33-.016.487-.048l-.194-.98A1.51 1.51 0 0 1 13.5 15h-.458v1h.458zm-9.625 0h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zm1.834-1v1h.916v-1h-.916zm1.833 1h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
          </svg>
        </button>

        <button
            type="button"
            :class="'btn btn-' + (currentRouteRP.editMode === 'side-road' ? '' : 'outline-') + 'secondary pt-0 route-intermediates-mode-side-road'"
            v-on:click="selectMode('side-road')"
            title="Zijwegen (E)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-node-plus" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM6.025 7.5a5 5 0 1 1 0 1H4A1.5 1.5 0 0 1 2.5 10h-1A1.5 1.5 0 0 1 0 8.5v-1A1.5 1.5 0 0 1 1.5 6h1A1.5 1.5 0 0 1 4 7.5h2.025zM11 5a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 11 5zM1.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
          </svg>
        </button>
      </div>

      <div class="btn-group btn-group-sm float-end dropdown position-static" role="group">
        <button
            type="button"
            class="btn btn-outline-secondary pt-0"
            title=".xlsx export"
            v-on:click="downloadXlsxExport()"
        >
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-file-earmark-spreadsheet" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z"/>
          </svg>
        </button>

        <button
            type="button"
            class="btn btn-outline-secondary pt-0"
            title="Rou.Tech. Instellingen"
            v-bind:id="'route_' + route.id + '_technique_settings'"
            v-show="!locked"
        >
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-sliders" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
          </svg>
        </button>

        <button
            type="button"
            class="btn btn-primary pt-0"
            title="Download"
            v-on:click="downloadRouteTechnique()"
            v-if="!locked || currentRouteRP.intermediatesRP.routeTechniqueRP"
        >
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
          </svg>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" type="button" v-on:click="moveMapToRoute()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-cursor" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z"/>
            </svg>
            Ga naar
          </button>
          <button class="dropdown-item" type="button" v-on:click="reverseRoute()" v-if="!locked">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-arrow-left-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
            </svg>
            Omdraaien
          </button>
          <button class="dropdown-item" type="button" v-on:click="duplicateRoute()" v-if="!locked">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-files" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
              <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
            </svg>
            Dupliceer
          </button>
          <button class="dropdown-item" type="button" v-on:click="deleteRouteTech()" v-if="!locked">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
            </svg>
            Wis Rou.Tech.
          </button>
        </div>
        <button class="btn btn-sm btn-outline-secondary" data-bs-toggle="dropdown" data-bs-boundary="viewport" aria-haspopup="true" aria-expanded="false" title="Meer...">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
          </svg>
          <span class="visually-hidden">Toggle Dropdown</span>
        </button>
      </div>

      <table class="table" id="routeIntermediatesTable">
        <thead>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">Hemelsbreed</th>
          <th class="text-center">Route</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(intermediate, i) in currentRouteRP.intermediatesRP.intermediates">
          <td>{{ intermediate.intermediateNumber }} - {{ i === currentRouteRP.intermediatesRP.intermediates.length - 1 ? 'Eind' : (intermediate.intermediateNumber + 1) }}</td>
          <td>
            <div class="text-end text-muted px-1 size70">{{ intermediate.directDistanceFormatted }}</div>
            <div class="text-end text-muted px-1 size70">{{ intermediate.directBearingFormatted }}</div>
          </td>
          <td>
            <div class="text-end text-muted px-1 size70">{{ intermediate.pathDistanceFormatted }}</div>
            <div class="text-end text-muted px-1 size70">{{ intermediate.pathInitialBearingFormatted }}</div>
          </td>
          <td class="pe-0">
            <button class="btn btn-sm btn-control px-1" type="button" v-on:click="deleteIntermediate(i)">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
              </svg>
            </button>
          </td>
        </tr>
        </tbody>
      </table>

      <route-technique-settings-modal
          v-bind:listen-id="'route_' + route.id + '_technique_settings'"
          v-bind:route="route"
      ></route-technique-settings-modal>
    </div>
  </div>
</template>

<script lang="ts">
import UserInterface from "../Main/UserInterface";
import Route, {RouteEditMode} from "../Main/Route";
import XlsxExport from "../RouteTechniques/XlsxExport";
import RouteTechniqueSettingsModal from "./RouteTechniqueSettingsModal.vue";
import $ from "jquery";
import RouteRemoveIntermediatesAction from "../ActionHistory/RouteRemoveIntermediatesAction";
import RouteDeleteIntermediateAction from "../ActionHistory/RouteDeleteIntermediateAction";

export default {
  components: {
    RouteTechniqueSettingsModal,
  },
  props: {
    userInterface: <UserInterface>null,
  },
  data () {
    return {
    };
  },
  computed: {
    locked: function (): boolean {
      return this.userInterface.isLocked();
    },
    route: function (): Route|null {
      const routeRP = this.currentRouteRP;
      if (!routeRP) {
        return null;
      }

      return this.userInterface.getRouteCollection().find(routeRP.id);
    },
    routechRouteRPs: function () {
      return this.userInterface.getRouteCollection().getReactiveRoutesProps().filter(r => r.hasInitializedIntermediates);
    },
    currentRouteRP: function () {
      return this.userInterface.getRouteCollection().reactiveProps.rouTechSelectedRouteRP;
    },
  },
  methods: {
    changeRoute: function () {
      const routeCollection = this.userInterface.getRouteCollection();

      routeCollection.unfocus();

      const id = +document.getElementById('routech_route').value;
      return this.userInterface.getRouteCollection().setRouTechSelectedRoute(id ? id : null);
    },
    selectMode: function (mode: RouteEditMode) {
      const route = this.route;
      const routeRP = route.reactiveProps;

      if (routeRP.hasFocus && routeRP.editMode === mode) {
        this.userInterface.getRouteCollection().unfocus();
      } else {
        this.userInterface.getRouteCollection().focusRoute(route);
        route.setEditMode(mode);
      }
    },
    downloadXlsxExport: function () {
      const intermediates = this.route.getIntermediates();

      (new XlsxExport(intermediates.computeIntermediatesMeta())).downloadXlsx();
    },
    downloadRouteTechnique: function () {
      const intermediates = this.route.getIntermediates();
      if (intermediates.getRouteTechnique()) {
        intermediates.downloadRouteTechnique();
      } else {
        // TODO: kind of ugly...
        $('#route_' + this.route.id + '_technique_settings').trigger('click');
      }
    },
    moveMapToRoute: function () {
      this.userInterface.getMap().fitTo([], [this.route], []);
    },
    reverseRoute: function () {
      this.route.reverse();
    },
    duplicateRoute: function () {
      this.route.routeCollection.duplicateRoute(this.route);
    },
    deleteRouteTech: function () {
      this.userInterface.actionHistory.addAction(new RouteRemoveIntermediatesAction(this.route));
    },
    deleteIntermediate: function (index) {
      this.userInterface.actionHistory.addAction(new RouteDeleteIntermediateAction(
          this.route.getIntermediates().getIntermediatesList()[index]
      ));

      $('#routeIntermediatesTable button:focus').blur();
    }
  },
};
</script>

<style scoped>

</style>
