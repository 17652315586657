<template>
  <div
      class="card card-cutout-settings-modal d-none position-absolute"
      tabindex="-1"
      v-bind:id="'cutout_settings_modal_' + cutoutRP.id"
  >
    <div class="card-header cutout-settings-card-header">
      <h5 class="card-title mb-0">Kaartinstellingen {{ cutoutRP.name }}</h5>

      <a class="btn btn-sm btn-outline-secondary btn-modal-header-wiki" href="https://plattekaart.nl/wiki/kaart" target="_blank">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-book" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
        </svg>
        <span>Wiki</span>
      </a>

      <button type="button" class="btn-close cutout-settings-close" v-bind:id="'csm_' + cutoutRP.id + '_modal_close'" aria-label="Close"></button>
    </div>
    <div class="card-body d-flex flex-column">
      <ul class="nav nav-tabs" role="tablist" v-bind:id="'csm_' + cutoutRP.id + '_tabbar-tablist'">
        <li class="nav-item">
          <a
              class="nav-link active"
              v-bind:id="'csm_' + cutoutRP.id + '_tabbar_general-tab'"
              role="tab"
              data-bs-toggle="tab"
              v-bind:aria-controls="'csm_' + cutoutRP.id + '_tabbar_general'"
              v-bind:data-bs-target="'#csm_' + cutoutRP.id + '_tabbar_general'"
              aria-selected="true"
          >Algemeen</a>
        </li>
        <li class="nav-item">
          <a
              class="nav-link"
              v-bind:id="'csm_' + cutoutRP.id + '_tabbar_projection-tab'"
              role="tab"
              data-bs-toggle="tab"
              v-bind:aria-controls="'csm_' + cutoutRP.id + '_tabbar_projection'"
              v-bind:data-bs-target="'#csm_' + cutoutRP.id + '_tabbar_projection'"
              aria-selected="false"
          >Projectie</a>
        </li>
        <li class="nav-item" v-if="advancedMode && !isFlexagon">
          <a
              class="nav-link"
              v-bind:id="'csm_' + cutoutRP.id + '_tabbar_grid-tab'"
              role="tab"
              data-bs-toggle="tab"
              v-bind:aria-controls="'csm_' + cutoutRP.id + '_tabbar_grid'"
              v-bind:data-bs-target="'#csm_' + cutoutRP.id + '_tabbar_grid'"
              aria-selected="false"
          >Raster</a>
        </li>
        <li class="nav-item" v-if="advancedMode && !isFlexagon">
          <a
              class="nav-link"
              v-bind:id="'csm_' + cutoutRP.id + '_tabbar_frame-tab'"
              role="tab"
              data-bs-toggle="tab"
              v-bind:aria-controls="'csm_' + cutoutRP.id + '_tabbar_frame'"
              v-bind:data-bs-target="'#csm_' + cutoutRP.id + '_tabbar_frame'"
              aria-selected="false"
          >Kader</a>
        </li>
        <li class="nav-item" v-if="isFlexagon">
          <a
              class="nav-link"
              v-bind:id="'csm_' + cutoutRP.id + '_tabbar_flexagon-tab'"
              role="tab"
              data-bs-toggle="tab"
              v-bind:aria-controls="'csm_' + cutoutRP.id + '_tabbar_flexagon'"
              v-bind:data-bs-target="'#csm_' + cutoutRP.id + '_tabbar_flexagon'"
              aria-selected="false"
          >Flexagon</a>
        </li>
        <li class="flex-fill"></li>
        <li class="align-content-end">
          <div class="form-check small text-muted">
            <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_advanced_settings_mode'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.advanced_settings_mode" v-on:change="changeAdvancedSettingsMode()">
            <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_advanced_settings_mode'">Geavanceerd</label>
          </div>
        </li>
      </ul>
      <div class="tab-content cutout-settings-tab-content">
        <div
            class="tab-pane show active"
            v-bind:id="'csm_' + cutoutRP.id + '_tabbar_general'"
            role="tabpanel"
            v-bind:aria-labelledby="'csm_' + cutoutRP.id + '_tabbar_general-tab'"
        >
          <div class="form-group">
            <label v-bind:for="'csm_' + cutoutRP.id + '_name'" class="form-label">Kaart naam</label>
            <input
                type="text"
                class="form-control"
                v-bind:id="'csm_' + cutoutRP.id + '_name'"
                placeholder="Typ een naam..."
                v-bind:value="cutoutRP.name"
                v-on:change="changeName($event)"
                v-on:keyup="changeName($event)"
                v-on:input="changeName($event)"
                v-on:blur="changeName($event)"
            />
          </div>

          <div class="form-group">
            <label v-bind:for="'csm_' + cutoutRP.id + '_paper'" class="form-label">Papier formaat</label>
            <span class="float-end btn btn-link btn-sm p-0" v-on:click="cutout.userInterface.openGeneralSettings({tab: 'paper'})">Beheren</span>
            <select
                class="form-select"
                v-bind:id="'csm_' + cutoutRP.id + '_paper'"
                v-on:change="changePaper($event)"
            >
              <option
                  v-for="paper in paperList"
                  :key="paper.name"
                  v-bind:value="paper.name"
                  v-bind:selected="cutoutRP.paperName === paper.name"
              >{{ paper.title }}</option>
            </select>
          </div>

          <div class="form-group" v-if="advancedMode">
            <label v-bind:for="'csm_' + cutoutRP.id + '_variant'" class="form-label">Variant</label>

            <a v-if="isFlexagon" class="float-end btn btn-sm btn-outline-secondary mt-m1" href="https://plattekaart.nl/wiki/flexagonkaart" target="_blank">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-book" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
              </svg>
              Wiki: Flexagonkaart
            </a>

            <select
                class="form-select"
                v-bind:id="'csm_' + cutoutRP.id + '_variant'"
                v-on:change="changeVariant($event)"
            >
              <option
                  v-for="(variantTitle, variant) in {normal: 'Normale kaart', flexagon: 'Hexatetraflexagon puzzel (Experimenteel)'}"
                  :key="variant"
                  v-bind:value="variant"
                  v-bind:selected="cutoutRP.options.variant === variant"
              >{{ variantTitle }}</option>
            </select>
          </div>

          <div class="form-group" v-if="advancedMode && !isFlexagon">
            <div class="form-check">
              <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_draw_routes'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.draw_routes" v-on:change="changeGenericOption($event, 'draw_routes')">
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_draw_routes'">Routes tekenen</label>
            </div>
          </div>

          <div class="form-group" v-if="advancedMode && !isFlexagon">
            <div class="form-check">
              <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_draw_route_intermediates'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.draw_route_intermediates" v-on:change="changeGenericOption($event, 'draw_route_intermediates')">
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_draw_route_intermediates'">Routetechniek beslispunten tekenen</label>
            </div>
          </div>

          <div class="form-group" v-if="advancedMode && !isFlexagon">
            <div class="form-check">
              <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_draw_locations'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.draw_locations" v-on:change="changeGenericOption($event, 'draw_locations')">
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_draw_locations'">Punten tekenen</label>
            </div>
          </div>

          <div class="form-group" v-if="!advancedMode && !isFlexagon">
            <div class="form-check">
              <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_draw_routes_locations'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.draw_routes" v-on:change="changeDrawRoutesLocations()">
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_draw_routes_locations'">Routes en punten tekenen</label>
            </div>
          </div>
        </div>

        <div
            class="tab-pane"
            v-bind:id="'csm_' + cutoutRP.id + '_tabbar_projection'"
            role="tabpanel"
            v-bind:aria-labelledby="'csm_' + cutoutRP.id + '_tabbar_projection-tab'"
        >
          <div class="alert alert-warning" role="alert" v-if="suggestedScaleRange.showScaleWarning">
            De ingevulde combinatie van Schaal en DPI resulteert in een resolutie die mogelijk incompatibel is met het geselecteerde WMS.
          </div>

          <div class="form-group">
            <label v-bind:for="'csm_' + cutoutRP.id + '_mip'" class="form-label">Kaartbron</label>
            <select
                class="form-select"
                v-bind:id="'csm_' + cutoutRP.id + '_mip'"
                v-on:change="changeMip($event)"
            >
              <option
                  v-for="mip in container.mapImageProviderList()"
                  v-bind:value="mip.name"
                  v-bind:selected="cutoutRP.projectionRP.mipName === mip.name"
              >{{ mip.title }}</option>
            </select>
          </div>

          <div class="form-group" v-if="advancedMode">
            <label v-bind:for="'csm_' + cutoutRP.id + '_coordinate_system'" class="form-label">Coördinatenstelsel</label>
            <select
                class="form-select"
                v-bind:id="'csm_' + cutoutRP.id + '_coordinate_system'"
                v-bind:disabled="mapImageProvider.getCompatibleCoordinateSystems().length <= 1"
                v-on:change="changeCoordinateSystem($event)"
            >
              <option
                  v-for="cs in mapImageProvider.getCompatibleCoordinateSystems()"
                  v-bind:value="cs.code"
                  v-bind:selected="cutoutRP.projectionRP.coordinateSystemCode === cs.code"
              >{{ cs.name }}</option>
            </select>
          </div>

          <div class="form-group">
            <label v-bind:for="'csm_' + cutoutRP.id + '_scale'" class="form-label">Schaal</label>
            <span class="text-danger small float-end" v-if="suggestedScaleRange.showScaleWarning">{{ suggestedScaleRange.scale }}</span>

            <div class="input-group">
              <span class="input-group-text">1:</span>
              <input
                  type="text"
                  class="form-control"
                  v-bind:id="'csm_' + cutoutRP.id + '_scale'"
                  v-bind:value="cutoutRP.projectionRP.scale"
                  v-on:change="changeScale($event)"
                  v-on:keyup="changeScale($event)"
                  v-on:input="changeScale($event)"
                  v-on:blur="changeScale($event)"
              >
            </div>
          </div>

          <div class="form-group" v-if="cutoutRP.projectionRP.type === 'WmsProjection' || (cutoutRP.projectionRP.type === 'OlVectorProjection' && advancedMode)">
            <label v-bind:for="'csm_' + cutoutRP.id + '_dpi'" class="form-label">DPI</label>
            <div class="float-end ms-2" v-if="advancedMode && cutoutRP.projectionRP.type === 'WmsProjection'">
              <div class="form-check">
                <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_dpi_auto'" type="checkbox" value="1" v-bind:checked="cutoutRP.projectionRP.dpi === null" v-on:change="changeAutoDpi($event)">
                <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_dpi_auto'">Automatisch</label>
              </div>
            </div>
            <span class="text-danger small float-end" v-if="suggestedScaleRange.showScaleWarning">{{ suggestedScaleRange.dpi }}</span>

            <input
                type="text" class="form-control"
                v-bind:id="'csm_' + cutoutRP.id + '_dpi'"
                v-bind:value="cutoutRP.projectionRP.dpi || cutout.getProjection().getDpi()"
                v-on:change="changeDpi($event, cutout)"
                v-on:keyup="changeDpi($event, cutout)"
                v-on:input="changeDpi($event, cutout)"
                v-on:blur="changeDpi($event, cutout)"
                :disabled="cutoutRP.projectionRP.dpi === null"
            >
          </div>

          <div class="form-group" v-if="cutoutRP.projectionRP.type === 'WmtsProjection' || cutoutRP.projectionRP.type === 'LuchtfotoProjection'">
            <label v-bind:for="'csm_' + cutoutRP.id + '_tile_matrix'" class="form-label">Zoom niveau</label>
            <select class="form-select" v-bind:id="'csm_' + cutoutRP.id + '_tile_matrix'" v-on:change="changeTileMatrixId($event, cutout)">
              <option
                  v-for="tileMatrix in mapImageProvider.getTileMatrixList()"
                  v-bind:value="tileMatrix.identifier"
                  v-bind:selected="cutoutRP.projectionRP.tileMatrixId === tileMatrix.identifier"
              >{{ tileMatrixName(tileMatrix) }} px/km</option>
            </select>
          </div>

          <div class="form-group" v-if="cutoutRP.projectionRP.type === 'OlProjection' || cutoutRP.projectionRP.type === 'OlVectorProjection'">
            <label v-bind:for="'csm_' + cutoutRP.id + '_ol_zoom'" class="form-label">Zoom niveau</label>
            <select class="form-select" v-bind:id="'csm_' + cutoutRP.id + '_ol_zoom'" v-on:change="changeOpenLayersZoom($event, cutout)">
              <option
                  v-for="zoom in getZoomLevelList()"
                  v-bind:value="zoom"
                  v-bind:selected="cutoutRP.projectionRP.zoom === zoom"
              >{{ openLayersZoomName(zoom) }} px/km</option>
            </select>
          </div>

          <div class="form-group" v-if="cutoutRP.projectionRP.type === 'WmtsProjection' || cutoutRP.projectionRP.type === 'LuchtfotoProjection' || cutoutRP.projectionRP.type === 'OlProjection'">
            <label v-bind:for="'csm_' + cutoutRP.id + '_dpi_wmts_ol'" class="form-label">DPI</label>
            <input type="text" disabled class="form-control" v-bind:id="'csm_' + cutoutRP.id + '_dpi_wmts_ol'" v-bind:value="Math.round(computedDpi)">
          </div>

          <div class="form-group" v-if="cutoutRP.projectionRP.type === 'LuchtfotoProjection'">
            <div class="form-check">
              <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_lfp_draw_labels'" type="checkbox" value="1" v-bind:checked="cutoutRP.projectionRP.drawLabels" v-on:change="changeLuchtfotoDrawLabels($event, cutout)">
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_lfp_draw_labels'">Teken labels</label>
            </div>
          </div>
        </div>

        <div
            class="tab-pane"
            v-bind:id="'csm_' + cutoutRP.id + '_tabbar_grid'"
            role="tabpanel"
            v-bind:aria-labelledby="'csm_' + cutoutRP.id + '_tabbar_grid-tab'"
            v-if="advancedMode"
        >
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_draw_grid'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.draw_grid" v-on:change="changeGenericOption($event, 'draw_grid')">
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_draw_grid'">Raster tekenen</label>
            </div>

            <div class="alert alert-warning" role="alert" v-if="showDrawnGridWarning">
              De gekozen projectie bevat in de kaartbron al een getekend raster, deze kan niet worden gewist. Er zal geen raster worden bijgetekend, maar er zal dus wel een raster zichtbaar blijven.
            </div>
          </div>

          <div class="form-group">
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-bind:name="'csm_' + cutoutRP.id + '_grid_type'"
                  v-bind:id="'csm_' + cutoutRP.id + '_grid_type_auto'"
                  value="auto"
                  v-bind:checked="cutoutRP.gridRP.customGridSpec === null"
                  v-on:change="changeGridType($event)"
              >
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_grid_type_auto'">
                Automatisch raster
              </label>
            </div>
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-bind:name="'csm_' + cutoutRP.id + '_grid_type'"
                  v-bind:id="'csm_' + cutoutRP.id + '_grid_type_manual'"
                  value="manual"
                  v-bind:checked="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual'"
                  v-on:change="changeGridType($event)"
              >
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_grid_type_manual'">
                Handmatig raster
              </label>
            </div>
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-bind:name="'csm_' + cutoutRP.id + '_grid_type'"
                  v-bind:id="'csm_' + cutoutRP.id + '_grid_type_manual_affine'"
                  value="manual_affine"
                  v-bind:checked="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual_affine'"
                  v-on:change="changeGridType($event)"
              >
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_grid_type_manual_affine'">
                Handmatig raster &amp; aangepaste coördinaten
              </label>
            </div>
          </div>

          <div
              class="form-group small fst-italic text-muted"
              v-if="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual_affine'"
          >
            Na het tekenen van het grid worden de te presenteren coördinaten eerst nog vermenigvuldigd met de schalingsfactor en daarna verschoven met de translatie (in meters).
          </div>

          <div class="form-group">
            <table class="table table-sm" v-if="cutoutRP.gridRP.customGridSpec !== null">
              <tr>
                <th>Rasterdefinitie</th>
                <th>Grid basis</th>
                <th>Grid stapgrootte</th>
                <th v-if="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual_affine'">Coördinaten schaling</th>
                <th v-if="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual_affine'">Coördinaten translatie</th>
              </tr>
              <tr>
                <th>X</th>
                <td>
                  <input
                      type="number" class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_grid_manual_base_x'"
                      v-bind:value="cutoutRP.gridRP.customGridSpec.base_x"
                      v-on:change="changeManualGrid($event, cutout, 'base_x')"
                      v-on:keyup="changeManualGrid($event, cutout, 'base_x')"
                      v-on:input="changeManualGrid($event, cutout, 'base_x')"
                      v-on:blur="changeManualGrid($event, cutout, 'base_x')"
                  >
                </td>
                <td>
                  <input
                      type="number" class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_grid_manual_delta_x'"
                      v-bind:value="cutoutRP.gridRP.customGridSpec.delta_x"
                      v-on:change="changeManualGrid($event, cutout, 'delta_x')"
                      v-on:keyup="changeManualGrid($event, cutout, 'delta_x')"
                      v-on:input="changeManualGrid($event, cutout, 'delta_x')"
                      v-on:blur="changeManualGrid($event, cutout, 'delta_x')"
                  >
                </td>
                <td v-if="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual_affine'">
                  <input
                      type="number" class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_grid_manual_mul_x'"
                      v-bind:value="cutoutRP.gridRP.customGridSpec.mul_x"
                      v-on:change="changeManualGrid($event, cutout, 'mul_x')"
                      v-on:keyup="changeManualGrid($event, cutout, 'mul_x')"
                      v-on:input="changeManualGrid($event, cutout, 'mul_x')"
                      v-on:blur="changeManualGrid($event, cutout, 'mul_x')"
                  >
                </td>
                <td v-if="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual_affine'">
                  <input
                      type="number" class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_grid_manual_add_x'"
                      v-bind:value="cutoutRP.gridRP.customGridSpec.add_x"
                      v-on:change="changeManualGrid($event, cutout, 'add_x')"
                      v-on:keyup="changeManualGrid($event, cutout, 'add_x')"
                      v-on:input="changeManualGrid($event, cutout, 'add_x')"
                      v-on:blur="changeManualGrid($event, cutout, 'add_x')"
                  >
                </td>
              </tr>
              <tr>
                <th>Y</th>
                <td>
                  <input
                      type="number" class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_grid_manual_base_y'"
                      v-bind:value="cutoutRP.gridRP.customGridSpec.base_y"
                      v-on:change="changeManualGrid($event, cutout, 'base_y')"
                      v-on:keyup="changeManualGrid($event, cutout, 'base_y')"
                      v-on:input="changeManualGrid($event, cutout, 'base_y')"
                      v-on:blur="changeManualGrid($event, cutout, 'base_y')"
                  >
                </td>
                <td>
                  <input
                      type="number" class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_grid_manual_delta_y'"
                      v-bind:value="cutoutRP.gridRP.customGridSpec.delta_y"
                      v-on:change="changeManualGrid($event, cutout, 'delta_y')"
                      v-on:keyup="changeManualGrid($event, cutout, 'delta_y')"
                      v-on:input="changeManualGrid($event, cutout, 'delta_y')"
                      v-on:blur="changeManualGrid($event, cutout, 'delta_y')"
                  >
                </td>
                <td v-if="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual_affine'">
                  <input
                      type="number" class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_grid_manual_mul_y'"
                      v-bind:value="cutoutRP.gridRP.customGridSpec.mul_y"
                      v-on:change="changeManualGrid($event, cutout, 'mul_y')"
                      v-on:keyup="changeManualGrid($event, cutout, 'mul_y')"
                      v-on:input="changeManualGrid($event, cutout, 'mul_y')"
                      v-on:blur="changeManualGrid($event, cutout, 'mul_y')"
                  >
                </td>
                <td v-if="cutoutRP.gridRP.customGridSpec && cutoutRP.gridRP.customGridSpec.type === 'manual_affine'">
                  <input
                      type="number" class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_grid_manual_add_y'"
                      v-bind:value="cutoutRP.gridRP.customGridSpec.add_y"
                      v-on:change="changeManualGrid($event, cutout, 'add_y')"
                      v-on:keyup="changeManualGrid($event, cutout, 'add_y')"
                      v-on:input="changeManualGrid($event, cutout, 'add_y')"
                      v-on:blur="changeManualGrid($event, cutout, 'add_y')"
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
            class="tab-pane"
            v-bind:id="'csm_' + cutoutRP.id + '_tabbar_frame'"
            role="tabpanel"
            v-bind:aria-labelledby="'csm_' + cutoutRP.id + '_tabbar_frame-tab'"
            v-if="advancedMode"
        >
          <label class="form-label">Marges</label>
          <div class="row">
            <div class="offset-md-4 col-md-4">
              <div class="form-group">
                <div>
                  <label v-bind:for="'csm_' + cutoutRP.id + '_margin_top_printable'" class="float-start form-label fst-italic">Boven</label>
                  <div class="form-check float-end">
                    <input
                        class="form-check-input"
                        v-bind:id="'csm_' + cutoutRP.id + '_coords_top'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="cutoutRP.options.display_coords_top"
                        v-on:change="changeDisplayCoords($event, 'top')"
                    />
                    <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_coords_top'">Coördinaten</label>
                  </div>
                </div>
                <div class="input-group">
                  <span class="input-group-text" title="Printbaar">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-file-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                    </svg>
                  </span>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_margin_top_printable'"
                      v-bind:value="cutoutRP.options.margin_top_printable"
                      min="0"
                      v-on:change="changeMargin($event, 'top', 'printable')"
                      v-on:keyup="changeMargin($event, 'top', 'printable')"
                      v-on:input="changeMargin($event, 'top', 'printable')"
                      v-on:blur="changeMargin($event, 'top', 'printable')"
                  >
                  <span class="input-group-text">mm</span>
                </div>
                <div class="input-group">
                  <span class="input-group-text" title="Onprintbaar">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-file-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    </svg>
                  </span>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_margin_top_nonprintable'"
                      v-bind:value="cutoutRP.options.margin_top_nonprintable"
                      min="0"
                      v-on:change="changeMargin($event, 'top', 'nonprintable')"
                      v-on:keyup="changeMargin($event, 'top', 'nonprintable')"
                      v-on:input="changeMargin($event, 'top', 'nonprintable')"
                      v-on:blur="changeMargin($event, 'top', 'nonprintable')"
                  >
                  <span class="input-group-text">mm</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <div>
                  <label v-bind:for="'csm_' + cutoutRP.id + '_margin_left_printable'" class="form-label fst-italic">Links</label>
                  <div class="form-check float-end">
                    <input
                        class="form-check-input"
                        v-bind:id="'csm_' + cutoutRP.id + '_coords_left'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="cutoutRP.options.display_coords_left"
                        v-on:change="changeDisplayCoords($event, 'left')"
                    />
                    <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_coords_left'">Coördinaten</label>
                  </div>
                </div>
                <div class="input-group">
                  <span class="input-group-text" title="Printbaar">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-file-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                    </svg>
                  </span>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_margin_left_printable'"
                      v-bind:value="cutoutRP.options.margin_left_printable"
                      min="0"
                      v-on:change="changeMargin($event, 'left', 'printable')"
                      v-on:keyup="changeMargin($event, 'left', 'printable')"
                      v-on:input="changeMargin($event, 'left', 'printable')"
                      v-on:blur="changeMargin($event, 'left', 'printable')"
                  >
                  <span class="input-group-text">mm</span>
                </div>
                <div class="input-group">
                  <span class="input-group-text" title="Onprintbaar">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-file-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    </svg>
                  </span>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_margin_left_nonprintable'"
                      v-bind:value="cutoutRP.options.margin_left_nonprintable"
                      min="0"
                      v-on:change="changeMargin($event, 'left', 'nonprintable')"
                      v-on:keyup="changeMargin($event, 'left', 'nonprintable')"
                      v-on:input="changeMargin($event, 'left', 'nonprintable')"
                      v-on:blur="changeMargin($event, 'left', 'nonprintable')"
                  >
                  <span class="input-group-text">mm</span>
                </div>
              </div>
            </div>

            <div class="offset-md-4 col-md-4">
              <div class="form-group">
                <div>
                  <label v-bind:for="'csm_' + cutoutRP.id + '_margin_right_printable'" class="form-label fst-italic">Rechts</label>
                  <div class="form-check float-end">
                    <input
                        class="form-check-input"
                        v-bind:id="'csm_' + cutoutRP.id + '_coords_right'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="cutoutRP.options.display_coords_right"
                        v-on:change="changeDisplayCoords($event, 'right')"
                    />
                    <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_coords_right'">Coördinaten</label>
                  </div>
                </div>
                <div class="input-group">
                  <span class="input-group-text" title="Printbaar">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-file-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                    </svg>
                  </span>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_margin_right_printable'"
                      v-bind:value="cutoutRP.options.margin_right_printable"
                      min="0"
                      v-on:change="changeMargin($event, 'right', 'printable')"
                      v-on:keyup="changeMargin($event, 'right', 'printable')"
                      v-on:input="changeMargin($event, 'right', 'printable')"
                      v-on:blur="changeMargin($event, 'right', 'printable')"
                  >
                  <span class="input-group-text">mm</span>
                </div>
                <div class="input-group">
                  <span class="input-group-text" title="Onprintbaar">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-file-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    </svg>
                  </span>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_margin_right_nonprintable'"
                      v-bind:value="cutoutRP.options.margin_right_nonprintable"
                      min="0"
                      v-on:change="changeMargin($event, 'right', 'nonprintable')"
                      v-on:keyup="changeMargin($event, 'right', 'nonprintable')"
                      v-on:input="changeMargin($event, 'right', 'nonprintable')"
                      v-on:blur="changeMargin($event, 'right', 'nonprintable')"
                  >
                  <span class="input-group-text">mm</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="offset-md-4 col-md-4">
              <div class="form-group">
                <div>
                  <label v-bind:for="'csm_' + cutoutRP.id + '_margin_bottom_printable'" class="form-label fst-italic">Onder</label>
                  <div class="form-check float-end">
                    <input
                        class="form-check-input"
                        v-bind:id="'csm_' + cutoutRP.id + '_coords_bottom'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="cutoutRP.options.display_coords_bottom"
                        v-on:change="changeDisplayCoords($event, 'bottom')"
                    />
                    <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_coords_bottom'">Coördinaten</label>
                  </div>
                </div>
                <div class="input-group">
                  <span class="input-group-text" title="Printbaar">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-file-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                    </svg>
                  </span>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_margin_bottom_printable'"
                      v-bind:value="cutoutRP.options.margin_bottom_printable"
                      min="0"
                      v-on:change="changeMargin($event, 'bottom', 'printable')"
                      v-on:keyup="changeMargin($event, 'bottom', 'printable')"
                      v-on:input="changeMargin($event, 'bottom', 'printable')"
                      v-on:blur="changeMargin($event, 'bottom', 'printable')"
                  >
                  <span class="input-group-text">mm</span>
                </div>
                <div class="input-group">
                  <span class="input-group-text" title="Onprintbaar">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-file-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    </svg>
                  </span>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'csm_' + cutoutRP.id + '_margin_bottom_nonprintable'"
                      v-bind:value="cutoutRP.options.margin_bottom_nonprintable"
                      min="0"
                      v-on:change="changeMargin($event, 'bottom', 'nonprintable')"
                      v-on:keyup="changeMargin($event, 'bottom', 'nonprintable')"
                      v-on:input="changeMargin($event, 'bottom', 'nonprintable')"
                      v-on:blur="changeMargin($event, 'bottom', 'nonprintable')"
                  >
                  <span class="input-group-text">mm</span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-check">
            <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_display_name'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.display_name" v-on:change="changeGenericOption($event, 'display_name')">
            <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_display_name'">Kaartnaam afdrukken</label>
          </div>

          <div class="form-check">
            <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_display_scale'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.display_scale" v-on:change="changeGenericOption($event, 'display_scale')">
            <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_display_scale'">Schaal-indicatie afdrukken</label>
          </div>

          <div class="form-check">
            <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_rotate_y_coords'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.rotate_y_coords" v-on:change="changeGenericOption($event, 'rotate_y_coords')">
            <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_rotate_y_coords'">Coördinaten langs verticale as kwartslag draaien</label>
          </div>
        </div>

        <div
            class="tab-pane"
            v-bind:id="'csm_' + cutoutRP.id + '_tabbar_flexagon'"
            role="tabpanel"
            v-bind:aria-labelledby="'csm_' + cutoutRP.id + '_tabbar_flexagon-tab'"
            v-if="isFlexagon"
        >
          <div class="form-group mt-2">
            <label v-bind:for="'csm_' + cutoutRP.id + '_flexagon_assignment_version'" class="form-label">Kaartverdeling</label>
            <select class="form-select" v-bind:id="'csm_' + cutoutRP.id + '_flexagon_assignment_version'" v-on:change="changeFlexagonOption('assignment_version')">
              <option
                  v-for="(flexagonAssignmentVersion, favKey) in FlexagonAssignmentVersions.hexatetraflexagon"
                  :key="favKey"
                  v-bind:value="favKey"
                  v-bind:selected="cutoutRP.options.flexagon_options.assignment_version === favKey"
              >{{ flexagonAssignmentVersion.title }}</option>
            </select>
            <div class="form-text">{{ FlexagonAssignmentVersions.hexatetraflexagon[cutoutRP.options.flexagon_options.assignment_version]?.description }}</div>
          </div>

          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" v-bind:id="'csm_' + cutoutRP.id + '_flexagon_size_auto'" type="checkbox" value="1" v-bind:checked="cutoutRP.options.flexagon_options.width === null" v-on:change="changeFlexagonSizeAuto($event.target.checked)">
              <label class="form-check-label" v-bind:for="'csm_' + cutoutRP.id + '_flexagon_size_auto'">Automatische grootte (maximaal)</label>
            </div>
          </div>

          <div class="form-group">
            <label v-bind:for="'csm_' + cutoutRP.id + '_flexagon_width'" class="form-label">Breedte (van gevouwen flexagon)</label>
            <div class="input-group">
              <input type="number" class="form-control" v-bind:id="'csm_' + cutoutRP.id + '_flexagon_width'" v-bind:value="cutout.computeFlexagonSize().width" :min="cutoutRP.options.flexagon_options.section_overlap" v-bind:max="cutout.computeFlexagonMaxSize().width" :disabled="cutoutRP.options.flexagon_options.width === null" v-on:change="changeFlexagonOption('width')">
              <span class="input-group-text">mm</span>
            </div>
          </div>

          <div class="form-group">
            <label v-bind:for="'csm_' + cutoutRP.id + '_flexagon_height'" class="form-label">Hoogte (van gevouwen flexagon)</label>
            <div class="input-group">
              <input type="number" class="form-control" v-bind:id="'csm_' + cutoutRP.id + '_flexagon_height'" v-bind:value="cutout.computeFlexagonSize().height" :min="cutoutRP.options.flexagon_options.section_overlap" v-bind:max="cutout.computeFlexagonMaxSize().height" :disabled="cutoutRP.options.flexagon_options.height === null" v-on:change="changeFlexagonOption('height')">
              <span class="input-group-text">mm</span>
            </div>
          </div>

          <div class="form-group">
            <label v-bind:for="'csm_' + cutoutRP.id + '_flexagon_orientation'" class="form-label">Oriëntatie gedekt kaartgebied</label>
            <select class="form-select" v-bind:id="'csm_' + cutoutRP.id + '_flexagon_orientation'" v-on:change="changeFlexagonOption('orientation')">
              <option
                  v-for="(flexagon_orientationTitle, flexagon_orientation) in {horizontal: 'Horizontaal (3 breed, 2 hoog)', vertical: 'Verticaal (2 breed, 3 hoog)'}"
                  :key="flexagon_orientation"
                  v-bind:value="flexagon_orientation"
                  v-bind:selected="cutoutRP.options.flexagon_options.orientation === flexagon_orientation"
              >{{ flexagon_orientationTitle }}</option>
            </select>
          </div>

          <div class="form-group">
            <label v-bind:for="'csm_' + cutoutRP.id + '_flexagon_section_overlap'" class="form-label">Overlap (van de zes kaartsegmenten)</label>
            <div class="input-group">
              <input type="number" class="form-control" v-bind:id="'csm_' + cutoutRP.id + '_flexagon_section_overlap'" v-bind:value="cutoutRP.options.flexagon_options.section_overlap" min="0" v-bind:max="Math.min(cutout.computeFlexagonSize().width, cutout.computeFlexagonSize().height)" v-on:change="changeFlexagonOption('section_overlap')">
              <span class="input-group-text">mm</span>
            </div>
          </div>

          <div class="form-group">
            <div class="float-end">
              <a class="btn btn-outline-secondary me-2" href="https://plattekaart.nl/wiki/flexagonkaart" target="_blank">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-book" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
                </svg>
                Wiki: Flexagonkaart
              </a>

              <div v-bind:class="'btn ' + (this.flexagonDiagramPercentage !== null ? 'btn-light' : 'btn-secondary')" v-on:click="visualizeFlexagon()">
                Bekijk diagram

                <div class="lds-dual-ring" v-bind:class="{'lds-dual-ring-hidden': this.flexagonDiagramPercentage === null}">
                  <div><div v-bind:style="{width: this.flexagonDiagramPercentage === null ? 0 : (this.flexagonDiagramPercentage + '%')}"></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ChangeCutoutNameAction from "../ActionHistory/ChangeCutoutNameAction";
import ChangeCutoutProjectionAction from "../ActionHistory/ChangeCutoutProjectionAction";
import ChangeCutoutScaleAction from "../ActionHistory/ChangeCutoutScaleAction";
import ChangeCutoutDpiAction from "../ActionHistory/ChangeCutoutDpiAction";
import UpdateCutoutOptionAction from "../ActionHistory/UpdateCutoutOptionAction";
import Container from "../Main/Container";
import Cutout from "../Main/Cutout";
import WmsProjection from "../Projection/WmsProjection";
import $ from "jquery";
import interact from "interactjs/dist/interact";
import Projection from "../Projection/Projection";
import Coordinate from "../Coordinates/Coordinate";
import MapImageProvider from "../Projection/MapImageProvider";
import Wmts, {TileMatrix} from "../Projection/Wmts";
import WmtsProjection from "../Projection/WmtsProjection";
import Wms from "../Projection/Wms";
import {trimTrailingZeroDecimalPlaces} from "../Util/functions";
import ChangeCutoutTileMatrixAction from "../ActionHistory/ChangeCutoutTileMatrixAction";
import ChangeCutoutPaperAction from "../ActionHistory/ChangeCutoutPaperAction";
import ChangeCutoutGridTypeAction from "../ActionHistory/ChangeCutoutGridTypeAction";
import ChangeCutoutManualGridAction from "../ActionHistory/ChangeCutoutManualGridAction";
import OlMip from "../Projection/OlMip";
import OlProjection from "../Projection/OlProjection";
import ChangeCutoutOLZoomAction from "../ActionHistory/ChangeCutoutOLZoomAction";
import EmptyMip from "../Projection/EmptyMip";
import EmptyProjection from "../Projection/EmptyProjection";
import CoordinateConverter from "../Util/CoordinateConverter";
import ChangeCutoutCoordinateSystemAction from "../ActionHistory/ChangeCutoutCoordinateSystemAction";
import OlVectorMip from "../Projection/OlVectorMip";
import OlVectorProjection from "../Projection/OlVectorProjection";
import LuchtfotoMip from "../Projection/Specialized/LuchtfotoMip";
import LuchtfotoProjection from "../Projection/Specialized/LuchtfotoProjection";
import ChangeLuchtfotoProjectionDrawLabelsAction from "../ActionHistory/ChangeLuchtfotoProjectionDrawLabelsAction";
import ChangeCutoutFlexagonSizeAutoAction from "../ActionHistory/ChangeCutoutFlexagonSizeAutoAction";
import {Tab} from "bootstrap";
import UpdateCutoutFlexagonOptionAction from "../ActionHistory/UpdateCutoutFlexagonOptionAction";
import {FlexagonAssignmentVersions, CutoutOptions} from "../Main/AbstractCutout";
import ChangeCutoutAdvancedSettingsModeAction from "../ActionHistory/ChangeCutoutAdvancedSettingsModeAction";
import ChangeCutoutDrawRoutesLocationsAction from "../ActionHistory/ChangeCutoutDrawRoutesLocationsAction";

export default {
  props: {
    cutout: Cutout,
    listenId: String,
  },
  data () {
    const cutout = this.cutout;

    cutout.userInterface.on('storage-reset', () => {
      this.storageResetCounter++;
    });

    Container.getPaperList().on('paper-list-update', () => {
      this.paperListResetCounter++;
    });

    $(() => {
      const $modal = $('#cutout_settings_modal_' + cutout.id);

      $('#' + this.listenId).on('click', () => {
        $modal.toggleClass('d-none');
      });

      $('#csm_' + cutout.id + '_modal_close').on('click', () => {
        $modal.addClass('d-none');
      });

      const width = Math.min(700, $('body').width() - 20);
      $modal.css({
        top: 100,
        width: width,
        left: $('body').width()/2 - width/2 - 10, // -10 is for parent offset
      });

      // target elements with the "draggable" class
      interact($modal.find('.card-header')[0])
          .draggable({
            // enable inertial throwing
            inertia: true,

            // keep the element within the area of it's parent
            modifiers: [
              interact.modifiers.restrictRect({
                restriction: 'body',
                endOnly: true
              })
            ],

            listeners: {
              // call this function on every dragmove event
              move: function(event) {
                const target = event.target;
                const modal = $modal[0];
                // keep the dragged position in the data-x/data-y attributes
                const x = (parseFloat(modal.getAttribute('data-x')) || 0) + event.dx;
                const y = (parseFloat(modal.getAttribute('data-y')) || 0) + event.dy;

                // translate the element
                modal.style.webkitTransform = modal.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

                // update the position attributes
                modal.setAttribute('data-x', x);
                modal.setAttribute('data-y', y);
              },
            }
          });

      interact($modal[0])
          .resizable({
            // resize from all edges and corners
            edges: { left: true, right: true, bottom: true, top: true },

            listeners: {
              move (event) {
                const modal = event.target;
                let x = (parseFloat(modal.getAttribute('data-x')) || 0);
                let y = (parseFloat(modal.getAttribute('data-y')) || 0);

                // update the element's style
                modal.style.width = event.rect.width + 'px';
                modal.style.height = event.rect.height + 'px';

                // translate when resizing from top or left edges
                x += event.deltaRect.left;
                y += event.deltaRect.top;

                modal.style.webkitTransform = modal.style.transform = 'translate(' + x + 'px,' + y + 'px)';

                modal.setAttribute('data-x', x);
                modal.setAttribute('data-y', y);
              }
            },
            modifiers: [
              // keep the edges inside the parent
              interact.modifiers.restrictEdges({
                outer: 'body'
              }),

              // minimum size
              interact.modifiers.restrictSize({
                min: { width: 400, height: 250 },
                max: { width: window.innerWidth, height: window.innerHeight}
              })
            ],

            inertia: true
          });

      this.checkSuggestedScaleRange(cutout);
      this.checkMipDrawnGrid(cutout);
    });

    return {
      container: Container,
      cutoutRP: cutout.reactiveProps,
      storageResetCounter: 0,
      paperListResetCounter: 0,
      suggestedScaleRange: {
        showScaleWarning: false,
        scale: null,
        dpi: null,
      },
      showDrawnGridWarning: false,
      flexagonDiagramPercentage: null,
    };
  },
  watch: {
    'cutoutRP.projectionRP': function (val, oldVal) {
      setTimeout(() => {
        this.checkSuggestedScaleRange(this.cutout);
        this.checkMipDrawnGrid(this.cutout);
      });
    },
    'cutoutRP.projectionRP.dpi': function (val, oldVal) {
      setTimeout(() => {
        this.checkSuggestedScaleRange(this.cutout);
      });
    },
    'cutoutRP.projectionRP.scale': function (val, oldVal) {
      setTimeout(() => {
        this.checkSuggestedScaleRange(this.cutout);
      });
    },
    'cutoutRP.projectionRP.tileMatrixId': function (val, oldVal) {
      setTimeout(() => {
        this.checkMipDrawnGrid(this.cutout);
      });
    },
    'cutoutRP.options.draw_grid': function (val, oldVal) {
      setTimeout(() => {
        this.checkMipDrawnGrid(this.cutout);
      });
    },
    'cutoutRP.options.variant': function (val, oldVal) {
      setTimeout(() => {
        this.checkActiveTab();
      });
    },
    'cutoutRP.options.advanced_settings_mode': function (val, oldVal) {
      setTimeout(() => {
        this.checkActiveTab();
      });
    },
  },
  computed: {
    FlexagonAssignmentVersions() {
      return FlexagonAssignmentVersions;
    },
    advancedMode: function () {
      return this.cutoutRP.options.advanced_settings_mode;
    },
    isFlexagon: function () {
      return this.cutout.reactiveProps.options.variant === 'flexagon';
    },
    paperList: function() {
      this.storageResetCounter;
      this.paperListResetCounter;
      return Container.getPaperList().getPaperList();
    },
    mapImageProvider: function (): MapImageProvider {
      return Container.mapImageProvider(this.cutoutRP.projectionRP.mipName);
    },
    computedDpi: function () {
      this.cutout.reactiveProps.projectionRP;

      const projection = this.cutout.projection;
      if (projection instanceof WmtsProjection) {
        this.cutout.reactiveProps.projectionRP.scale;
        this.cutout.reactiveProps.projectionRP.tileMatrixId;
      } else if (projection instanceof OlProjection) {
        this.cutout.reactiveProps.projectionRP.coordinateSystemCode;
        this.cutout.reactiveProps.projectionRP.scale;
        this.cutout.reactiveProps.projectionRP.zoom;
      }

      return projection.getDpi();
    }
  },
  methods: {
    getZoomLevelList() {
        return this.mapImageProvider.getZoomLevelList(this.cutout.projection.coordinateSystem);
    },
    tileMatrixName(tileMatrix: TileMatrix): string {
      const pxPerKm = Wmts.getPxPerKm(tileMatrix);
      return parseInt(tileMatrix.identifier) + ': ' + trimTrailingZeroDecimalPlaces(pxPerKm, pxPerKm < 10 ? 1 : 0);
    },
    openLayersZoomName(zoom: number): string {
      const projection = this.cutout.projection;
      if (! (projection instanceof OlProjection || projection instanceof OlVectorProjection)) {
        throw new Error();
      }
      const pxPerKm = projection.mapImageProvider.getPxPerKm(projection.coordinateSystem, zoom);
      const offset = projection instanceof OlProjection ? projection.mapImageProvider.getZoomOffset(projection.coordinateSystem) : 0;
      return (zoom + offset) + ': ' + trimTrailingZeroDecimalPlaces(pxPerKm, pxPerKm < 10 ? 1 : 0);
    },
    changeName(event) {
      const newName = $('#csm_' + this.cutout.id + '_name').val();
      if(newName !== this.cutout.name) {
        this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutNameAction(this.cutout, newName));
      }
    },
    changePaper(event) {
      const newVal = $('#csm_' + this.cutout.id + '_paper').val();
      const oldPaper = this.cutout.getPaper();
      if(newVal !== oldPaper.name) {
        const newPaper = Container.getPaperList().getPaper(newVal);
        this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutPaperAction(this.cutout, newPaper));
      }
    },
    changeVariant(event) {
      const newVariant = $('#csm_' + this.cutout.id + '_variant').val();
      const oldVariant = this.cutout.options.variant;
      if(newVariant !== oldVariant) {
        this.cutout.userInterface.actionHistory.addAction(new UpdateCutoutOptionAction(this.cutout, 'variant', newVariant));
      }
    },
    changeMip(event) {
      const newVal = $('#csm_' + this.cutout.id + '_mip').val();
      const oldProjection = this.cutout.getProjection();
      if(newVal !== oldProjection.mapImageProvider.name) {
        this.cutout.userInterface.showLoadingIndicator(100);

        let newProjection: Projection<Coordinate, MapImageProvider>;
        const mapImageProvider = Container.mapImageProvider(newVal);
        if(mapImageProvider instanceof Wms) {
          newProjection = new WmsProjection(newVal);
        } else if(mapImageProvider instanceof LuchtfotoMip) {
          newProjection = new LuchtfotoProjection(newVal);
        } else if(mapImageProvider instanceof Wmts) {
          newProjection = new WmtsProjection(newVal);
        } else if(mapImageProvider instanceof OlMip) {
          newProjection = new OlProjection(newVal);
        } else if(mapImageProvider instanceof OlVectorMip) {
          newProjection = new OlVectorProjection(newVal);
        } else if(mapImageProvider instanceof EmptyMip) {
          newProjection = new EmptyProjection(newVal);
        } else {
          throw new Error('Invalid value');
        }

        if(
            !(oldProjection instanceof WmsProjection)
            || !(newProjection instanceof WmsProjection)
            ||  oldProjection.mapImageProvider.getDefaultScale() === newProjection.mapImageProvider.getDefaultScale()
        ) {
          // The new WMS is has equivalent scaling with the old WMS, so we can reasonably keep the scale setting
          newProjection.setScale(oldProjection.getScale());
          if(oldProjection instanceof WmsProjection && newProjection instanceof WmsProjection && !oldProjection.hasAutoDpi()) {
            newProjection.setDpi(oldProjection.getRawDpi());
          }
        }

        newProjection.initialize().then(() => {
          this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutProjectionAction(this.cutout, newProjection));
        }).finally(() => {
          this.cutout.userInterface.hideLoadingIndicator();
        });
      }
    },
    changeCoordinateSystem(event) {
      const newVal = $('#csm_' + this.cutout.id + '_coordinate_system').val();
      const oldCoordinateSystem = this.cutout.getProjection().coordinateSystem;

      if (newVal === oldCoordinateSystem.code) {
        return;
      }

      const newCoordinateSystem = CoordinateConverter.getCoordinateSystem(newVal);

      this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutCoordinateSystemAction(this.cutout, newCoordinateSystem));
    },
    changeScale(event) {
      const newScale = parseInt($('#csm_' + this.cutout.id + '_scale').val());
      if (!isNaN(newScale) && newScale !== this.cutout.getProjection().getScale()) {
        this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutScaleAction(this.cutout, newScale));
      }
    },
    changeAutoDpi(event) {
      const newDpiAuto = $('#csm_' + this.cutout.id + '_dpi_auto').is(':checked');
      const projection = this.cutout.getProjection();
      if(projection instanceof WmsProjection && newDpiAuto !== projection.hasAutoDpi()) {
        this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutDpiAction(this.cutout, newDpiAuto ? null : this.cutout.getProjection().getDpi()));
      }
    },
    changeDpi(event, cutout: Cutout<any, any, any>) {
      const newDpi = parseInt($('#csm_' + cutout.id + '_dpi').val());
      const projection = cutout.getProjection();
      if(!isNaN(newDpi) && (projection instanceof WmsProjection || projection instanceof OlVectorProjection) && newDpi !== projection.getDpi()) {
        cutout.userInterface.actionHistory.addAction(new ChangeCutoutDpiAction(cutout, newDpi));
      }
    },
    changeTileMatrixId(event, cutout: Cutout<any, any, any>) {
      const newTileMatrixId = $('#csm_' + cutout.id + '_tile_matrix').val();
      const projection = cutout.getProjection();
      if(projection instanceof WmtsProjection && newTileMatrixId !== projection.getTileMatrixId()) {
        cutout.userInterface.actionHistory.addAction(new ChangeCutoutTileMatrixAction(cutout, newTileMatrixId));
      }
    },
    changeOpenLayersZoom(event, cutout: Cutout<any, any, any>) {
      const newZoom = parseInt($('#csm_' + cutout.id + '_ol_zoom').val());
      const projection = cutout.getProjection();
      if((projection instanceof OlProjection || projection instanceof OlVectorProjection) && newZoom !== projection.getZoom()) {
        cutout.userInterface.actionHistory.addAction(new ChangeCutoutOLZoomAction(cutout, newZoom));
      }
    },
    changeLuchtfotoDrawLabels(event, cutout: Cutout<any, any, any>) {
      const newDrawLabels = $('#csm_' + cutout.id + '_lfp_draw_labels').prop('checked');
      const projection = cutout.getProjection();
      if(projection instanceof LuchtfotoProjection && newDrawLabels !== projection.getDrawLabels()) {
        cutout.userInterface.actionHistory.addAction(new ChangeLuchtfotoProjectionDrawLabelsAction(cutout, newDrawLabels));
      }
    },
    changeManualGrid(event, cutout: Cutout<any, any, any>, key: 'base_x'|'delta_x'|'mul_x'|'add_x'|'base_y'|'delta_y'|'mul_y'|'add_y') {
      let newValue = $('#csm_' + cutout.id + '_grid_manual_' + key).val();
      if (key === 'mul_x' || key === 'mul_y') {
        newValue = parseFloat(newValue.replace(',', '.'));
      } else {
        newValue = parseInt(newValue);
      }
      const grid = cutout.getGrid();
      if(!isNaN(newValue) && newValue !== grid.getCustomGridSpec()[key] && (newValue > 0 || (key === 'base_x' || key === 'base_y' || key === 'add_x' || key === 'add_y'))) {
        cutout.userInterface.actionHistory.addAction(new ChangeCutoutManualGridAction(cutout, key, newValue));
      }
    },
    changeDisplayCoords(event, side: 'top'|'left'|'right'|'bottom') {
      const newVal = $('#csm_' + this.cutout.id + '_coords_' + side).prop('checked');
      if (newVal !== this.cutout.options['display_coords_' + side]) {
        this.cutout.userInterface.actionHistory.addAction(new UpdateCutoutOptionAction(
            this.cutout,
            'display_coords_' + side,
            newVal
        ));
      }
    },
    changeMargin(event, side: 'top'|'left'|'right'|'bottom', printable: 'printable'|'nonprintable') {
      const $input = $('#csm_' + this.cutout.id + '_margin_' + side + '_' + printable);
      let newVal = parseFloat($input.val());
      if (!isNaN(newVal) && newVal < 0.0) {
        $input.val('0');
        newVal = 0.0;
      }
      if (!isNaN(newVal) && newVal !== this.cutout.options['margin_' + side + '_' + printable]) {
        this.cutout.userInterface.actionHistory.addAction(new UpdateCutoutOptionAction(
            this.cutout,
            'margin_' + side + '_' + printable,
            newVal
        ));
      }
    },
    changeGridType(event) {
      const val = $('[name="csm_' + this.cutout.id + '_grid_type"]:checked').val();

      this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutGridTypeAction(
          this.cutout,
          (['auto', 'manual', 'manual_affine'].indexOf(val) > -1) ? val : 'auto',
      ));
    },
    changeGenericOption(event, optionKey: keyof CutoutOptions) {
      const newVal = $('#csm_' + this.cutout.id + '_' + optionKey).prop('checked');
      if (newVal !== this.cutout.options[optionKey]) {
        this.cutout.userInterface.actionHistory.addAction(new UpdateCutoutOptionAction(
            this.cutout,
            optionKey,
            newVal
        ));
      }
    },
    changeAdvancedSettingsMode() {
      const newVal = $('#csm_' + this.cutout.id + '_advanced_settings_mode').prop('checked');
      if (newVal !== this.cutout.options.advanced_settings_mode) {
        this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutAdvancedSettingsModeAction(this.cutout, newVal));
      }
    },
    changeDrawRoutesLocations() {
      const newVal = $('#csm_' + this.cutout.id + '_draw_routes_locations').prop('checked');
      if (newVal !== this.cutout.options.draw_routes) {
        this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutDrawRoutesLocationsAction(this.cutout, newVal));
      }
    },
    changeFlexagonSizeAuto(newValue: boolean) {
      this.cutout.userInterface.actionHistory.addAction(new ChangeCutoutFlexagonSizeAutoAction(this.cutout, newValue));
    },
    changeFlexagonOption(key: 'width'|'height'|'orientation'|'section_overlap'|'assignment_version') {
      let newValue = $('#csm_' + this.cutout.id + '_flexagon_' + key).val();

      if (['width', 'height', 'section_overlap'].includes(key)) {
        newValue = parseInt(newValue);
      }

      if(newValue !== this.cutout.options.flexagon_options[key]) {
        this.cutout.userInterface.actionHistory.addAction(new UpdateCutoutFlexagonOptionAction(this.cutout, key, newValue));
      }
    },
    checkSuggestedScaleRange(cutout: Cutout<any, any, any>) {
      const projection = cutout.getProjection();
      if(projection instanceof WmsProjection) {
        projection.isWithinSuggestedScaleRange().then((isWithin) => {
          this.suggestedScaleRange.showScaleWarning = !isWithin.isWithin();
          const minMaxScale = isWithin.minMaxScale();
          this.suggestedScaleRange.scale = this.determineRangeText(minMaxScale.min, minMaxScale.max);
          const minMaxDpi = isWithin.minMaxDpi();
          this.suggestedScaleRange.dpi = this.determineRangeText(minMaxDpi.min, minMaxDpi.max);
        });
      } else {
        this.suggestedScaleRange.showScaleWarning = false;
      }
    },
    determineRangeText(min, max): string {
      if (min !== null) {
        min = Math.ceil(min);
      }

      if (max !== null) {
        max = Math.floor(max);
      }

      if (min !== null && max !== null) {
        return '(Tussen ' + min + ' en ' + max + ')';
      } else if (min !== null) {
        return '(Minimaal ' + min + ')';
      } else if (max !== null) {
        return '(Maximaal ' + max + ');'
      } else {
        return '';
      }
    },
    checkMipDrawnGrid(cutout: Cutout<any, any, any>) {
      const projection = cutout.getProjection();
      const mip = projection.getMapImageProvider();
      if(mip instanceof Wms) {
        this.showDrawnGridWarning = !cutout.options.draw_grid && mip.hasDrawnGrid();
      } else if(mip instanceof Wmts) {
        this.showDrawnGridWarning = !cutout.options.draw_grid && mip.hasDrawnGrid((<WmtsProjection<any>>projection).getTileMatrixId());
      } else if(mip instanceof OlMip || mip instanceof OlVectorMip) {
        this.showDrawnGridWarning = !cutout.options.draw_grid && mip.hasDrawnGrid();
      } else if(mip instanceof EmptyMip) {
        this.showDrawnGridWarning = false;
      }
    },
    checkActiveTab() {
      const activeTab = document.querySelector('#cutout_settings_modal_' + this.cutout.id + ' a.nav-link.active');

      if (activeTab === null) {
        document.querySelectorAll('#cutout_settings_modal_' + this.cutout.id + ' .tab-pane').forEach((tabPanel) => {
          tabPanel.classList.remove('active', 'show');
        });

        Tab.getOrCreateInstance(document.querySelector('a.nav-link#csm_' + this.cutout.id + '_tabbar_general-tab')).show();
      }
    },
    visualizeFlexagon() {
      this.cutout.visualizeFlexagon(({done, total}) => {
        this.flexagonDiagramPercentage = total > 0 ? done / total * 100 : 0;
      }).finally(() => {
        this.flexagonDiagramPercentage = null;
      });
    }
  }
};
</script>

<style scoped>
.card-cutout-settings-modal {
  border-color: rgba(0,0,0,.2);
  z-index: 50; /* Higher than children of .object-list-main-buttons */
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  max-width: 100vw;
}

.card-cutout-settings-modal .card-body {
  min-height: 1px; /* To ensure content does not overflow */
}

.cutout-settings-tab-content {
  overflow-x: hidden;
  overflow-y: auto;
}

.cutout-settings-card-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: .5rem;
  padding: .5rem 1rem 1rem;
  background-color: #ffffff;
}

.cutout-settings-close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem 0;
}
</style>
