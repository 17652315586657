import Coordinate from "../Coordinates/Coordinate";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import OLConvertibleCoordinate from "../Coordinates/OLConvertibleCoordinate";
import OLConvertibleCoordinateSystem from "../Coordinates/OLConvertibleCoordinateSystem";
import Grid from "./Grid";
import Cutout from "./Cutout";
import UserInterface from "./UserInterface";
import AbstractCutout from "./AbstractCutout";
import {Serialization} from "./Serializer";
import CoordinateConverter from "../Util/CoordinateConverter";
import {Point} from "../Util/Math";
import WmsProjection from "../Projection/WmsProjection";
import WmtsProjection from "../Projection/WmtsProjection";
import Container from "./Container";
import Paper from "../Util/Paper";
import OlProjection from "../Projection/OlProjection";
import EmptyProjection from "../Projection/EmptyProjection";
import OlVectorProjection from "../Projection/OlVectorProjection";
import LuchtfotoProjection from "../Projection/Specialized/LuchtfotoProjection";
import {copyObject} from "../Util/functions";

export default class CutoutTemplate<
    WorkspaceCoordinate extends Coordinate & OLConvertibleCoordinate,
    ProjectionCoordinate extends Coordinate,
    WorkspaceCoordinateSystem extends CoordinateSystem<WorkspaceCoordinate> & OLConvertibleCoordinateSystem<WorkspaceCoordinate>
    > extends AbstractCutout<WorkspaceCoordinate, ProjectionCoordinate, WorkspaceCoordinateSystem> {

    makeCutout(userInterface: UserInterface): Promise<Cutout<WorkspaceCoordinate, ProjectionCoordinate, WorkspaceCoordinateSystem>> {
        const projection = this.getProjection().clone();

        return projection.initialize().then(() => {
            this.getPaper().check();

            const cutout = new Cutout(
                userInterface,
                this.getPaper(),
                this.anchorWorkspaceCoordinate.clone(),
                this.workspaceCoordinateSystem,
                projection,
                this.getGrid().clone()
            );

            cutout.options = copyObject(cutout.options, this.options);
            cutout.options.advanced_settings_mode ||= Container.getDefaultAdvancedSettingsMode();
            cutout.updateReactiveProps();

            return cutout;
        });
    }

    serialize(): Serialization {
        return {
            name: this.name,
            options: copyObject(this.options),
            anchor: {
                system: this.anchorWorkspaceCoordinate.code,
                x: this.anchorWorkspaceCoordinate.getX(),
                y: this.anchorWorkspaceCoordinate.getY(),
            },
            paper: this.paper.serialize(),
            projection: this.projection.serialize(),
            grid: this.grid.serialize(),
        };
    }

    static unserialize(serialized: Serialization): CutoutTemplate<any, any, any> {
        const coordinateSystem = CoordinateConverter.getCoordinateSystem(serialized.anchor.system);
        const coordinate = coordinateSystem.fromPoint(new Point(serialized.anchor.x, serialized.anchor.y));

        let projection = null;
        if(serialized.projection.type === 'wms') {
            projection = WmsProjection.unserialize(serialized.projection);
        } else if(serialized.projection.type === 'luchtfoto') {
            projection = LuchtfotoProjection.unserialize(serialized.projection);
        } else if(serialized.projection.type === 'wmts') {
            projection = WmtsProjection.unserialize(serialized.projection);
        } else if(serialized.projection.type === 'openlayers') {
            projection = OlProjection.unserialize(serialized.projection);
        } else if(serialized.projection.type === 'openlayers_vector') {
            projection = OlVectorProjection.unserialize(serialized.projection);
        } else if(serialized.projection.type === 'empty') {
            projection = EmptyProjection.unserialize(serialized.projection);
        } else {
            throw new Error('Invalid projection type');
        }

        let paper;
        if(typeof serialized.paper === 'object') {
            paper = Paper.unserialize(serialized.paper);
        } else {
            // Serialization v1
            paper = Container.getPaperList().getPaper(serialized.paper || 'A4L');
        }

        const cutoutTemplate = new CutoutTemplate(
            paper,
            // @ts-ignore
            coordinate,
            coordinateSystem,
            projection,
            Grid.unserialize(serialized.grid),
        );

        cutoutTemplate.name = serialized.name;
        cutoutTemplate.options = copyObject(cutoutTemplate.options, serialized.options);

        return cutoutTemplate;
    }

}
