import Coordinate from "../Coordinates/Coordinate";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Conversion from "../Conversion/Conversion";
import CoordinateConverter from "./CoordinateConverter";
import * as turfHelpers from "@turf/helpers";
import CoordinateParseResult from "./CoordinateParseResult";
import {Coordinate as olCoordinate} from "ol/coordinate";
import GeographicLib from "geographiclib-geodesic";
import {fromLonLat, toLonLat} from "ol/proj";
import {getLength} from "ol/sphere";
import {LineString} from "ol/geom";

type GeodInverseStandard = {lat1: number, lon1: number, azi1: number, lat2: number, lon2: number, azi2: number, s12: number, a12: number};
type GeodDirectStandard = {lat1: number, lon1: number, azi1: number, lat2: number, lon2: number, azi2: number, s12: number, a12: number};

export class PointSystem implements CoordinateSystem<Point> {
    readonly code = '';
    readonly name = '';

    conversions(): Conversion<Point, Coordinate>[] {
        return [];
    }

    make(x: number, y: number): Point {
        return new Point(x, y);
    }

    fromPoint(point: Point): Point {
        return new Point(point.getX(), point.getY());
    }

    rebase(c: Point): CoordinateSystem<Point> {
        return this;
    }

    parse(value: string): CoordinateParseResult<Point> | null {
        return null;
    }
}

export class Point implements Coordinate {
    readonly code = '';

    readonly x: number;
    readonly y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    static from(source: Coordinate): Point {
        return new Point(source.getX(), source.getY());
    }

    getX(): number {
        return this.x;
    }

    getY(): number {
        return this.y;
    }

    make<C extends this>(x: number, y: number): C {
        return <C>new Point(x, y);
    }

    withinBounds(): boolean {
        return true;
    }

    belongsTo(coordinateSystem: CoordinateSystem<Coordinate>): boolean {
        return this.code === coordinateSystem.code;
    }

    clone<P extends this>(): P {
        return <P>new Point(this.x, this.y);
    }

    formatOrdinateForPdf(dimension: 'x' | 'y'): string {
        throw new Error('No formatting available for Point');
    }

    formats(): Record<string, () => string> {
        throw new Error('No formatting available for Point');
    }

    defaultFormat(): string {
        throw new Error('No formatting available for Point');
    }
}

export function walkLine<C extends Coordinate, S extends CoordinateSystem<C>>(s: S, a: C, b: C, steps: number, callback: (c: C, step: number) => void) {
    for(let i=0; i<steps; i++) {
        const p = i / (steps-1);

        const c = s.make(
            a.getX()*(1-p) + b.getX()*p,
            a.getY()*(1-p) + b.getY()*p
        );

        callback(c, i);
    }
}

export function interpolatePolygonEdges<C extends Coordinate>(sourcePolygon: C[], insertionsPerEdge: number): C[] {
    if(sourcePolygon.length < 2) {
        return sourcePolygon;
    }

    // Rebase to prevent UTM cutouts from glitching when moving from zone (e.g. U32 to U31)
    const coordinateSystem = <CoordinateSystem<C>>CoordinateConverter.getCoordinateSystem(sourcePolygon[0].code).rebase(sourcePolygon[0]);
    const interpolatedPolygon = <C[]>[];

    for(let i=0; i<sourcePolygon.length; i++) {
        walkLine(
            coordinateSystem,
            sourcePolygon[i],
            sourcePolygon[(i+1) % sourcePolygon.length],
            insertionsPerEdge + 2,
            (c: C, step): void => {
                if(step < 1 + insertionsPerEdge) {
                    interpolatedPolygon.push(c);
                }
            }
        );
    }
    return interpolatedPolygon;
}

export function toTurfPolygon(polygon: Coordinate[]): turfHelpers.Feature<turfHelpers.Polygon, turfHelpers.Properties> {
    const points = <number[][]>[];
    for(const c of polygon) {
        points.push([c.getX(), c.getY()]);
    }
    if(polygon.length > 0) {
        points.push([polygon[0].getX(), polygon[0].getY()]);
    }
    return turfHelpers.polygon([points]);
}

export type LineSegment<C extends Coordinate> = {from: C, to: C};

export function lineSegmentsIntersection<C extends Coordinate>(line1: LineSegment<C>, line2: LineSegment<C>): C|null {
    // https://stackoverflow.com/a/1968345
    let
        p0_x = line1.from.getX(), p0_y = line1.from.getY(),
        p1_x = line1.to.getX(), p1_y = line1.to.getY(),
        p2_x = line2.from.getX(), p2_y = line2.from.getY(),
        p3_x = line2.to.getX(), p3_y = line2.to.getY();

    let s1_x, s1_y, s2_x, s2_y;
    s1_x = p1_x - p0_x;     s1_y = p1_y - p0_y;
    s2_x = p3_x - p2_x;     s2_y = p3_y - p2_y;

    let s, t;
    s = (-s1_y * (p0_x - p2_x) + s1_x * (p0_y - p2_y)) / (-s2_x * s1_y + s1_x * s2_y);
    t = ( s2_x * (p0_y - p2_y) - s2_y * (p0_x - p2_x)) / (-s2_x * s1_y + s1_x * s2_y);

    if (s >= 0 && s <= 1 && t >= 0 && t <= 1)
    {
        // Collision detected
        return line1.from.make(
            p0_x + (t * s1_x),
            p0_y + (t * s1_y),
        );
    }

    return null; // No collision
}

export function lineSegmentIntersectsPolygonEdges<C extends Coordinate>(line: LineSegment<C>, polygon: C[]): boolean {
    for (let i=0; i<polygon.length; i++) {
        if(lineSegmentsIntersection(line, {from: polygon[i], to: polygon[(i+1)%polygon.length]}) !== null) {
            return true;
        }
    }
    return false;
}

export function dot<C extends Coordinate>(a: C, b: C): number {
    return a.getX() * b.getX() + a.getY() * b.getY();
}

export function polygonsOverlap<C extends Coordinate>(a: C[], b: C[]) {
    // http://web.archive.org/web/20141127210836/http://content.gpwiki.org/index.php/Polygon_Collision
    // Only works for convex polygons
    // TODO: is this more efficient than naively checking whether any edge of a intersects any edge of b + checking b contained in a or a contained in b?

    const findSeparatingAxis = (a: C[], b: C[]): boolean => {
        for(let i=0; i<a.length; i++) {
            const p1 = a[i];
            const p2 = a[(i+1)%a.length];

            const direction = new Point(p2.getX() - p1.getX(), p2.getY() - p1.getY());
            const normal = new Point(-direction.getY(), direction.getX());

            const projectionsA: number[] = [];
            for(const pa of a) {
                projectionsA.push(dot(normal, new Point(pa.getX(), pa.getY())));
            }

            const projectionsB: number[] = [];
            for(const pb of b) {
                projectionsB.push(dot(normal, new Point(pb.getX(), pb.getY())));
            }

            if(Math.max(...projectionsA) <= Math.min(...projectionsB)) {
                return true;
            }

            if(Math.max(...projectionsB) <= Math.min(...projectionsA)) {
                return true;
            }
        }

        return false;
    };

    return !(findSeparatingAxis(a, b) || findSeparatingAxis(b, a));
}

export function diff(coordinates: Point[]): Point[] {
    const diffs = [];
    for (let i=0; i<coordinates.length-1; i++) {
        diffs.push([
            coordinates[i+1].getX() - coordinates[i].getX(),
            coordinates[i+1].getY() - coordinates[i].getY(),
        ]);
    }
    return diffs;
}

export function min<T>(list: T[], callback: (item: T) => number = x => <number>x): T|null {
    let result: T = null;
    let minVal: number = null;

    for (const item of list) {
        const val = callback(item);
        if (minVal === null || val < minVal) {
            result = item;
            minVal = val;
        }
    }

    return result;
}

export function randomInt(min: number, max: number): number {
    return Math.floor(min + Math.random() * (max - min));
}

export function sliceGeodeticPathMeters(
    sourceCoordinates: [number, number][],
    length: number,
    startIndex: number = 0,
    backward: boolean = false,
    stopIndex: number = backward ? 0 : sourceCoordinates.length - 1,
): [number, number][] {
    if (typeof sourceCoordinates[startIndex] === 'undefined') {
        throw new Error();
    }

    const step = backward ? -1 : 1;

    let distance: number = 0;
    const sliceCoordinates = [sourceCoordinates[startIndex]];

    for (let i = startIndex + step; step * i <= step * stopIndex; i += step) {
        const prevCoord = sourceCoordinates[i - step];
        const coord = sourceCoordinates[i];

        if (prevCoord[0] === coord[0] && prevCoord[1] === coord[1]) {
            continue;
        }

        const lineDistance = Math.sqrt((coord[0] - prevCoord[0]) ** 2 + (coord[1] - prevCoord[1]) ** 2);

        if (distance + lineDistance <= length) {
            sliceCoordinates.push(sourceCoordinates[i]);
            distance += lineDistance;
            continue;
        }

        sliceCoordinates.push([
            prevCoord[0] + (coord[0] - prevCoord[0]) / lineDistance * (length - distance),
            prevCoord[1] + (coord[1] - prevCoord[1]) / lineDistance * (length - distance),
        ]);
        break;
    }

    return sliceCoordinates;
}

export function trimGeodeticPathMeters(path: [number, number][], meters: number): [number, number][] {
    const length = geodeticLength(path);

    const endTrimmed = sliceGeodeticPathMeters(path, length - meters);

    return sliceGeodeticPathMeters(endTrimmed, length - 2 * meters, endTrimmed.length - 1, true).reverse();
}

export function geodeticLength(path: [number, number][]): number {
    let length = 0;
    for (let i = 1; i < path.length; i++) {
        length += Math.sqrt((path[i][0] - path[i - 1][0])**2 + (path[i][1] - path[i - 1][1])**2);
    }
    return length;
}

export function sliceOlPathMeters(
    sourceCoordinates: olCoordinate[],
    length: number,
    startIndex: number = 0,
    backward: boolean = false,
    stopIndex: number = backward ? 0 : sourceCoordinates.length - 1,
): olCoordinate[] {
    if (typeof sourceCoordinates[startIndex] === 'undefined') {
        throw new Error();
    }

    const step = backward ? -1 : 1;

    let distance: number = 0;
    const sliceCoordinates = [sourceCoordinates[startIndex]];

    const geod = GeographicLib.Geodesic.WGS84;

    for (let i = startIndex + step; step * i <= step * stopIndex; i += step) {
        const prevCoord = toLonLat(sourceCoordinates[i - step]);
        const coord = toLonLat(sourceCoordinates[i]);

        if (prevCoord[0] === coord[0] && prevCoord[1] === coord[1]) {
            continue;
        }

        const distanceResult = <GeodInverseStandard>geod.Inverse(prevCoord[1], prevCoord[0], coord[1], coord[0]);
        const lineDistance = distanceResult.s12;

        if (distance + lineDistance <= length) {
            sliceCoordinates.push(sourceCoordinates[i]);
            distance += lineDistance;
            continue;
        }

        const projectResult = <GeodDirectStandard>geod.Direct(prevCoord[1], prevCoord[0], distanceResult.azi1, length - distance);
        sliceCoordinates.push(fromLonLat([projectResult.lon2, projectResult.lat2]));
        break;
    }

    return sliceCoordinates;
}

export function olPathStartBearing(coordinates: olCoordinate[], radius: number): number {
    const startBearingCoordinate = projectOlPathCircle(coordinates, coordinates[0], radius);
    return olComputeHeading(coordinates[0], startBearingCoordinate).startBearing;
}

export function olComputeHeading(from: olCoordinate, to: olCoordinate): {startBearing: number, endBearing: number, distance: number} {
    const Geodesic = GeographicLib.Geodesic;
    const geod = Geodesic.WGS84;
    const coord1 = toLonLat(from);
    const coord2 = toLonLat(to);
    const r = <GeodInverseStandard>geod.Inverse(coord1[1], coord1[0], coord2[1], coord2[0]);

    return {
        startBearing: (r.azi1 + 360) % 360,
        endBearing: (r.azi2 + 360) % 360,
        distance: r.s12,
    };
}

export function projectOlPathCircle(
    coordinates: olCoordinate[],
    center: olCoordinate,
    radius: number,
    startIndex: number = 0,
    backward: boolean = false,
    stopIndex: number = backward ? 0 : coordinates.length - 1,
    projectDistance: number = radius,
): olCoordinate {
    let target = intersectOlPathCircle(coordinates, center, radius, startIndex, backward, stopIndex);

    if (target === null) {
        target = coordinates[stopIndex];
    }

    return olProjectTowards(center, target, projectDistance);
}

function intersectOlPathCircle(
    coordinates: olCoordinate[],
    center: olCoordinate,
    radius: number,
    startIndex: number = 0,
    backward: boolean = false,
    stopIndex: number = backward ? 0 : coordinates.length - 1,
): olCoordinate|null {
    if (typeof coordinates[startIndex] === 'undefined') {
        return null;
    }

    const inside = (c: olCoordinate) => (c[0] - center[0]) ** 2 + (c[1] - center[1]) ** 2 < radius ** 2;

    const startedInside = inside(coordinates[startIndex]);
    const step = backward ? -1 : 1;

    for (let i = startIndex + step; step * i <= step * stopIndex; i += step) {
        if (inside(coordinates[i]) === startedInside) {
            continue;
        }

        return firstIntersectionOlLineAndCircle(coordinates[i - step], coordinates[i], center, radius);
    }

    return null;
}

function firstIntersectionOlLineAndCircle(point1: olCoordinate, point2: olCoordinate, center: olCoordinate, radius: number): olCoordinate|null {
    const diff = [
        point2[0] - point1[0],
        point2[1] - point1[1],
    ];

    // (x - c.x) ** 2 + (y - c.y) ** 2 == r ** 2
    // x = line.x + p * lv.dx;
    // y = line.y + p * lv.dy;

    const sx = point1[0] - center[0];
    const sy = point1[1] - center[1];

    // (sx + p * lv.dx) ** 2 + (sy + p * lv.dy) ** 2 == r ** 2

    const A = diff[0] ** 2 + diff[1] ** 2;
    const B = 2 * diff[0] * sx + 2 * diff[1] * sy;
    const C = sx ** 2 + sy ** 2 - radius ** 2;

    const sqD = Math.sqrt(B ** 2 - 4 * A * C);

    const ps = [
        (-B + sqD) / (2 * A),
        (-B - sqD) / (2 * A),
    ].filter(p => 0 <= p && p <= 1);

    if (ps.length === 0) {
        return null;
    }

    const p = Math.min(...ps);

    return [
        point1[0] + p * diff[0],
        point1[1] + p * diff[1],
    ];
}

export function olNormalize(diff: [number, number]): [number, number] {
    const length = Math.sqrt(diff[0] ** 2 + diff[1] ** 2);

    return [
        diff[0] / length,
        diff[1] / length,
    ];
}

export function olProjectTowards(from: olCoordinate, to: olCoordinate, distance: number): olCoordinate {
    const diff = olNormalize([
        to[0] - from[0],
        to[1] - from[1],
    ]);

    return [
        from[0] + diff[0] * distance,
        from[1] + diff[1] * distance,
    ];
}

export function olTrimPath(path: olCoordinate[], percent: number): olCoordinate[] {
    const length = getLength(new LineString(path));

    const endTrimmed = sliceOlPathMeters(path, length * (1 - percent / 100));

    return sliceOlPathMeters(endTrimmed, length * (1 - 2 * percent / 100), endTrimmed.length - 1, true).reverse();
}

export function radiansToBearing(radians: number): number {
    return 90 - radiansToDegrees(radians);
}

export function radiansToDegrees(radians: number): number {
    return radians / Math.PI * 180;
}

export function bearingToRadians(bearing: number): number {
    return degreesToRadians(90 - bearing);
}

export function degreesToRadians(angle: number): number {
    return angle / 180 * Math.PI;
}
