import UserError from "../Util/UserError";
import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import {Options} from "roughjs/bin/core";
import {randomInt} from "../Util/Math";
import {createSvgEl} from "./Util/Svg";

type BolletjesConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
};

export default class Bolletjes extends ImageGeneratingTechnique<BolletjesConfig>
{
    public static readonly TECHNIQUE_NAME = 'bolletjes';
    public static readonly TECHNIQUE_TITLE = 'Bolletjesroute';
    public static readonly TECHNIQUE_WIKI = 'bolletjesroute';

    protected readonly requiresIntermediates = true;

    static readonly DIRECTIONS = [
        ['N'],
        ['N', 'N', 'O'],
        ['N', 'O'],
        ['O', 'N', 'O'],
        ['O'],
        ['O', 'Z', 'O'],
        ['Z', 'O'],
        ['Z', 'Z', 'O'],
        ['Z'],
        ['Z', 'Z', 'W'],
        ['Z', 'W'],
        ['W', 'Z', 'W'],
        ['W'],
        ['W', 'N', 'W'],
        ['N', 'W'],
        ['N', 'N', 'W'],
    ];

    protected getDefaultConfig(): BolletjesConfig {
        return {
            format: 'png',
            transparent: false,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 0.2,
            fillStyle: 'solid',
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const intermediatesMeta = this.getIntermediatesMeta();

        const boxWidth = 20; // mm
        const boxHeight = 30; // mm
        const maxPaperWidth = 210 - 12; // mm
        const maxBoxesOnLine = Math.floor(maxPaperWidth / boxWidth);

        const lines = Math.ceil(intermediatesMeta.intermediateMetas.length / maxBoxesOnLine);
        const cols = Math.min(maxBoxesOnLine, intermediatesMeta.intermediateMetas.length);

        const svg = createSvgEl('svg', {
            width: (cols * boxWidth),
            height: (lines * boxHeight),
            viewBox: "0 0 " + (cols * boxWidth) + " " + (lines * boxHeight),
        });

        for (let i = 0; i < intermediatesMeta.intermediateMetas.length; i++) {
            const intermediate = intermediatesMeta.intermediateMetas[i];

            if (intermediate.pathInitialBearing === null) {
                throw new UserError('Het laatste beslispunt in de route mag voor deze routetechniek niet aan het einde van de route liggen.');
            }

            const gridX = i % maxBoxesOnLine;
            const gridY = Math.floor(i / maxBoxesOnLine);

            const baseX = gridX * boxWidth;
            const baseY = gridY * boxHeight;

            const directionIndex = Math.round(intermediate.pathInitialBearing / (360 / 16)) % 16;
            const parts = Bolletjes.DIRECTIONS[directionIndex];

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i];

                const cx = baseX + 13;
                const cy = baseY + 10 + (parts.length - 1 - i) * 7;

                const dx = part === 'W' ? -1 : part === 'O' ? 1 : 0;
                const dy = part === 'N' ? -1 : part === 'Z' ? 1 : 0;

                svg.appendChild(createSvgEl('circle', {
                    cx: cx,
                    cy: cy - dy,
                    r: 2,
                    stroke: 'none',
                    fill: '#000000',
                }));

                svg.appendChild(createSvgEl('line', {
                    x1: cx,
                    y1: cy - dy,
                    x2: cx + dx * 4,
                    y2: cy + dy * 3,
                    stroke: '#000000',
                    strokeWidth: 0.5,
                }));
            }

            svg.appendChild(createSvgEl('circle', {
                cx: baseX + 6,
                cy: baseY + 6,
                r: 3,
                stroke: '#000000',
                fill: 'none',
                strokeWidth: 0.5,
            }));

            const text = createSvgEl('text', {
                x: baseX + 6,
                y: baseY + 6,
                textAnchor: 'middle',
                dominantBaseline: 'central',
            });
            text.textContent = '' + intermediate.intermediateNumber;
            text.style.font = '3px sans-serif';
            svg.appendChild(text);
        }

        return svg;
    }
}
