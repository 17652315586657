import {RouteImageFormat} from "./ImageGeneratingTechnique";
import {Options} from "roughjs/bin/core";
import {radiansToBearing, randomInt, trimGeodeticPathMeters} from "../Util/Math";
import {createSvgEl} from "./Util/Svg";
import GeodeticLineTechnique, {Dimensioning, ProjectedCoordinates} from "./GeodeticLineTechnique";
import {GraphPoint, offset} from "../Util/PolylineOffset/PolylineOffset";
import {ArcSegment, Drawing, drawingMinMax, LineSegment, Point} from "../Util/PolylineOffset/DrawingGeometry";
import {bearingBetween} from "./Util/SideRoadSnapping";
import {TipArrow} from "./Util/Arrow";

type SituatieschetsConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    displayScale: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
    rotation: number,
    displayNorthArrow: boolean,
    drawRouteLine: boolean,
    roadWidth: number|null,
};

export default class Situatieschets extends GeodeticLineTechnique<SituatieschetsConfig>
{
    public static readonly TECHNIQUE_NAME = 'situatieschets';
    public static readonly TECHNIQUE_TITLE = 'Situatieschets sjabloon (RD) (Experimenteel)';
    public static readonly TECHNIQUE_WIKI = 'situatieschets';
    protected readonly TECHNIQUE_TEXT_TITLE = 'Situatieschets';

    protected readonly requiresIntermediates = false;

    private drawing: Drawing;

    protected getDefaultConfig(): SituatieschetsConfig {
        return {
            format: 'png',
            transparent: false,
            displayScale: true,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
            rotation: randomInt(0, 360),
            displayNorthArrow: false,
            drawRouteLine: false,
            roadWidth: null,
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 1.5,
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const projectedCoordinates = this.getProjectedCoordinates(true);

        const dim = this.computeDimensioning(projectedCoordinates);

        const svg = createSvgEl('svg', {
            width: dim.widthPx + 'px',
            height: dim.heightPx + 'px',
            viewBox: "0 0 " + dim.width + " " + dim.height,
        });

        this.drawRouteRoad(svg, dim);

        if (this.config.drawRouteLine) {
            const offsetWidth = this.getOffsetWidth(dim.dimension);
            const {transformX, transformY} = this.getTransformFunctions(dim);
            const transformXY = (c: [number, number]) => [transformX(c[0]), transformY(c[1])];

            const trimmedRoute = trimGeodeticPathMeters(projectedCoordinates.route, offsetWidth);

            this.drawRoute(svg, dim, {
                route: trimmedRoute,
                sideRoads: [],
            }, 0.0025);

            svg.appendChild(createSvgEl('circle', {
                cx: transformX(trimmedRoute[0][0]),
                cy: transformY(trimmedRoute[0][1]),
                r: offsetWidth / 4,
                stroke: 'none',
                fill: '#000000',
            }));

            const arrow = new TipArrow(offsetWidth / 4, offsetWidth / 4, offsetWidth / 4);
            arrow.setAnchor('body-center');
            arrow.drawSvg(svg, transformXY(trimmedRoute[trimmedRoute.length - 2]), transformXY(trimmedRoute[trimmedRoute.length - 1]));
        }

        if (this.config.displayScale) {
            this.drawScale(svg, dim);
        }

        if (this.config.displayNorthArrow) {
            this.drawNorthArrow(svg, dim);
        }

        this.route.routeCollection.userInterface.toasts.addRouteTechniqueScaleToast(this.TECHNIQUE_TEXT_TITLE, dim.width);

        return svg;
    }

    private getOffsetWidth(dimension: number): number {
        if (this.config.roadWidth) {
            return this.config.roadWidth;
        }

        return this.computeOffsetWidth(dimension);
    }

    private computeOffsetWidth(dimension: number|null = null): number {
        if (!dimension) {
            const projectedCoordinates = this.getProjectedCoordinates(true);

            const minMax = super.computeMinMax(projectedCoordinates);

            dimension = Math.max(minMax.maxX - minMax.minX, minMax.maxY - minMax.minY);
        }

        return Math.sqrt(dimension) / 1.4;
    }

    public computeAutoRoadWidth(): number {
        return this.computeOffsetWidth();
    }

    protected computeMinMax(projectedCoordinates: ProjectedCoordinates): {minX: number; minY: number; maxX: number; maxY: number} {
        const startPoint = this.buildRoadGraph(projectedCoordinates);

        const minMax = super.computeMinMax(projectedCoordinates);

        const dimension = Math.max(minMax.maxX - minMax.minX, minMax.maxY - minMax.minY);

        this.drawing = offset(startPoint, this.getOffsetWidth(dimension));

        return drawingMinMax(this.drawing);
    }

    private buildSideRoad(point: GraphPoint, sideRoad: [number, number][]): void {
        for (let i = 1; i < sideRoad.length; i++) {
            const newPoint: GraphPoint = <GraphPoint>{
                x: sideRoad[i][0],
                y: sideRoad[i][1],
                neighbours: [point],
            };
            point.neighbours.push(newPoint);

            point = newPoint;
        }
    }

    private buildRoadGraph(projectedCoordinates: ProjectedCoordinates): GraphPoint {
        let startPoint: GraphPoint|null = null, lastPoint: GraphPoint|null = null;

        const sideRoadsByIndex: Record<number, [number, number][][]> = {};
        for (const sideRoad of projectedCoordinates.sideRoads) {
            if (typeof sideRoadsByIndex[sideRoad.index] === 'undefined') {
                sideRoadsByIndex[sideRoad.index] = [];
            }

            sideRoadsByIndex[sideRoad.index].push(sideRoad.coordinates);
        }

        const leftSideRoads = [];
        for (let i = 0; i < projectedCoordinates.route.length; i++) {
            const projectedCoordinate = projectedCoordinates.route[i];

            const point: GraphPoint = <GraphPoint>{
                x: projectedCoordinate[0],
                y: projectedCoordinate[1],
                neighbours: lastPoint ? [lastPoint] : [],
            };
            lastPoint?.neighbours?.push(point);

            const angleBearing = (a: [number, number], b: [number, number]): number => {
                return (radiansToBearing(Math.atan2(b[1] - a[1], b[0] - a[0])) + 360) % 360;
            };

            let bearingBackwards = i === 0 ? null : angleBearing(projectedCoordinate, projectedCoordinates.route[i-1]);
            let bearingForwards = i === (projectedCoordinates.route.length - 1) ? null : angleBearing(projectedCoordinate, projectedCoordinates.route[i+1]);
            bearingBackwards ??= (bearingForwards + 180) % 360;
            bearingForwards ??= (bearingBackwards + 180) % 360;

            const sortedSideRoads = (sideRoadsByIndex[i] || []).sort((a, b) => {
                const bearingA = (angleBearing(projectedCoordinate, a[1]) - bearingBackwards + 360) % 360;
                const bearingB = (angleBearing(projectedCoordinate, b[1]) - bearingBackwards + 360) % 360;
                return - (bearingA - bearingB);
            });

            for (const sideRoad of sortedSideRoads) {
                const bearing = angleBearing(projectedCoordinate, sideRoad[1]);
                if (bearingBetween(bearing, bearingBackwards, bearingForwards)) {
                    leftSideRoads.push({point, sideRoad});
                } else {
                    this.buildSideRoad(point, sideRoad);
                }
            }

            if (startPoint === null) {
                startPoint = point;
            }

            lastPoint = point;
        }

        for (const {point, sideRoad} of leftSideRoads) {
            this.buildSideRoad(point, sideRoad);
        }

        return startPoint;
    }

    private getTransformFunctions(dim: Dimensioning) {
        const scale = 1;

        const transformX = x => dim.padding + (x - dim.minX) * scale;
        const transformY = y => dim.padding + (dim.maxY - y) * scale;
        const format = (p: Point) => transformX(p.x) + ' ' + transformY(p.y);

        return {scale, transformX, transformY, format};
    }

    private drawRouteRoad(svg: SVGSVGElement, dim: Dimensioning) {
        const {scale, format} = this.getTransformFunctions(dim);

        for (const polyline of this.drawing.polylines) {
            const segmentStrings = [];

            if (polyline.segments.length > 0) {
                segmentStrings.push('M ' + format(polyline.segments[0].start));
            }

            for (let i = 0; i < polyline.segments.length; i++) {
                const segment = polyline.segments[i];

                if (segment instanceof LineSegment) {
                    segmentStrings.push('L ' + format(segment.end));
                } else if (segment instanceof ArcSegment) {
                    const radius = segment.radius() * scale;
                    const largeArc = segment.centralArcAngle > Math.PI ? 1 : 0;
                    segmentStrings.push('A ' + radius + ' ' + radius + ' 0 ' + largeArc + ' 0 ' + format(segment.end));
                } else {
                    throw new Error();
                }

                if (i < polyline.segments.length - 1 && (
                    segment.end.x !== polyline.segments[i + 1].start.x
                    || segment.end.y !== polyline.segments[i + 1].start.y
                )) {
                    segmentStrings.push('M ' + format(polyline.segments[i + 1].start));
                }
            }

            svg.appendChild(createSvgEl('path', {
                d: segmentStrings.join(' '),
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 0.005 * dim.dimension,
            }));
        }
    }
}
