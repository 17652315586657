<template>
  <div
      class="modal"
      tabindex="-1"
      role="dialog"
      v-bind:id="'route_settings_modal_' + routeRP.id"
      data-bs-backdrop="false"
      data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Route instellingen {{ routeRP.name }}</h5>

          <a class="btn btn-sm btn-outline-secondary btn-modal-header-wiki" href="https://plattekaart.nl/wiki/route" target="_blank">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-book" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
            </svg>
            <span>Wiki</span>
          </a>

          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" role="tablist" v-bind:id="'rsm_' + routeRP.id + '_tabbar-tablist'">
            <li class="nav-item">
              <a
                  class="nav-link active"
                  v-bind:id="'rsm_' + routeRP.id + '_tabbar_general-tab'"
                  role="tab"
                  data-bs-toggle="tab"
                  v-bind:aria-controls="'rsm_' + routeRP.id + '_tabbar_general'"
                  v-bind:data-bs-target="'#rsm_' + routeRP.id + '_tabbar_general'"
                  aria-selected="true"
              >Algemeen</a>
            </li>
          </ul>
          <div class="tab-content">
            <div
                class="tab-pane show active"
                v-bind:id="'rsm_' + routeRP.id + '_tabbar_general'"
                role="tabpanel"
                v-bind:aria-labelledby="'rsm_' + routeRP.id + 'tabbar_general-tab'"
            >
              <div class="form-group">
                <label v-bind:for="'rsm_' + routeRP.id + '_name'" class="form-label">Route naam</label>
                <input type="text" class="form-control" v-bind:id="'rsm_' + routeRP.id + '_name'" placeholder="Typ een naam..." v-bind:value="routeRP.name">
              </div>

              <div class="form-group">
                <label v-bind:id="'rsm_' + routeRP.id + '_color_label'" class="form-label">Kleur</label>

                <div class="input-group">
                  <div class="form-control d-flex flex-column justify-content-center cursor-pointer" v-bind:id="'rsm_' + routeRP.id + '_color_value'">
                    <div class="color-value" v-bind:style="'background-color: ' + routeRP.color"></div>
                  </div>
                  <button
                      type="button"
                      class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      v-bind:id="'rsm_' + routeRP.id + '_color_toggle'"
                  >
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <button
                        v-for="color of colors"
                        class="dropdown-item color-option"
                        type="button"
                        v-bind:style="'background-color: ' + color"
                        v-on:click="changeColor(color)"
                    ></button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label v-bind:for="'rsm_' + routeRP.id + '_line_style_type'" class="form-label">Lijn stijl</label>
                <select class="form-select" v-bind:id="'rsm_' + routeRP.id + '_line_style_type'">
                  <option
                      v-for="[type, label] in [['solid', 'Continu'], ['dashed', 'Gestreept'], ['dotted', 'Gestipt']]"
                      :key="type"
                      v-bind:value="type"
                      v-bind:selected="routeRP.lineStyleType === type"
                  >{{ label }}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Container from "../Main/Container";
import $ from "jquery";
import Route from "../Main/Route";
import RouteChangeNameAction from "../ActionHistory/RouteChangeNameAction";
import RouteChangeColorAction from "../ActionHistory/RouteChangeColorAction";
import RouteChangeLineStyleTypeAction from "../ActionHistory/RouteChangeLineStyleTypeAction";
import {Dropdown} from "bootstrap";
import {bsModal} from "../Util/vueFunctions";

export default {
  props: {
    listenId: String,
    route: Route,
  },
  data () {
    $(() => {
      const route = this.route;

      $('#' + this.listenId).on('click', () => {
        bsModal('#route_settings_modal_' + route.id).show();
      });

      $('#rsm_' + route.id + '_name').on('change keyup input blur', function() {
        const newName = $(this).val();
        if(newName !== route.name) {
          route.routeCollection.userInterface.actionHistory.addAction(new RouteChangeNameAction(route, newName));
        }
      });

      const $toggle = $('#rsm_' + route.id + '_color_toggle');
      let colorOpen = false;
      $toggle.parent()[0].addEventListener('show.bs.dropdown', () => colorOpen = true);
      $toggle.parent()[0].addEventListener('hidden.bs.dropdown', () => colorOpen = false);
      $('#rsm_' + route.id + '_color_label, #rsm_' + route.id + '_color_value').on('click', () => {
        if (!colorOpen) {
          setTimeout(() => Dropdown.getOrCreateInstance($toggle[0]).show());
        }
      });

      $('#rsm_' + route.id + '_line_style_type').on('change', function() {
        const newVal = $(this).val();
        const oldLineStyleType = route.lineStyleType;
        if(newVal !== oldLineStyleType) {
          route.routeCollection.userInterface.actionHistory.addAction(new RouteChangeLineStyleTypeAction(route, newVal));
        }
      });

    });

    return {
      container: Container,
      routeRP: this.route.reactiveProps,
      colors: this.route.routeCollection.userInterface.colors,
    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    changeColor(newColor) {
      if(newColor !== this.route.color) {
        this.route.routeCollection.userInterface.actionHistory.addAction(new RouteChangeColorAction(this.route, newColor));
      }
    }
  }
};
</script>

<style scoped>
.color-option {
  height: 1rem;
}

.color-value {
  height: .25rem;
}
</style>
