import Cache from "../Util/Cache";
import CutoutTemplate from "./CutoutTemplate";
import MapImageProvider from "../Projection/MapImageProvider";
import Wms from "../Projection/Wms";
import Wmts from "../Projection/Wmts";
import Paper, {PaperList} from "../Util/Paper";
import {Serialization} from "./Serializer";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Coordinate from "../Coordinates/Coordinate";
import CoordinateConverter from "../Util/CoordinateConverter";
import OlMip from "../Projection/OlMip";
import EmptyMip from "../Projection/EmptyMip";
import OlVectorMip from "../Projection/OlVectorMip";
import BolletjePijltje from "../RouteTechniques/BolletjePijltje";
import Bolletjes from "../RouteTechniques/Bolletjes";
import Graden from "../RouteTechniques/Graden";
import Helikopter from "../RouteTechniques/Helikopter";
import Oleaat from "../RouteTechniques/Oleaat";
import Stripkaart from "../RouteTechniques/Stripkaart";
import Vector from "../RouteTechniques/Vector";
import Vliegkoers from "../RouteTechniques/Vliegkoers";
import Kruispuntenroute from "../RouteTechniques/Kruispuntenroute";
import Situatieschets from "../RouteTechniques/Situatieschets";

export default class Container {
    static readonly CUSTOM_CUTOUT_TEMPLATE_LOCALSTORAGE_KEY = 'custom_cutout_templates';
    static readonly NEIGHBOUR_OVERLAP_LOCALSTORAGE_KEY = 'neighbour_overlap';
    static readonly DEFAULT_ADVANCED_SETTINGS_MODE_LOCALSTORAGE_KEY = 'default_advanced_settings_mode';

    static readonly PREFERRED_COORDSYSTEM_LOCALSTORAGE_KEY = 'coord_panel_preferred_coordsystem';
    static readonly PREFERRED_FORMATS_LOCALSTORAGE_KEY = 'coord_panel_preferred_formats';

    private static mapImageProviders: Record<string, MapImageProvider> = {};

    private static paperList: PaperList = new PaperList();

    private static systemCutoutTemplates: CutoutTemplate<any, any, any>[] = [];
    private static customCutoutTemplates: CutoutTemplate<any, any, any>[] = [];

    private static cache: Cache = null;

    static registerMapImageProvider(mapImageProvider: MapImageProvider) {
        Container.mapImageProviders[mapImageProvider.name] = mapImageProvider;
    }

    static mapImageProvider(name: string): MapImageProvider {
        if(!Container.mapImageProviders.hasOwnProperty(name)) {
            throw new Error('Unknown map image provider "' + name + '"');
        }

        return Container.mapImageProviders[name];
    }

    static wms(wmsName: string): Wms {
        const wms = Container.mapImageProvider(wmsName);
        if(!(wms instanceof Wms)) {
            throw new Error('Map image provider "' + name + '" is not WMS');
        }
        return wms;
    }

    static wmts(wmtsName: string): Wmts {
        const wms = Container.mapImageProvider(wmtsName);
        if(!(wms instanceof Wmts)) {
            throw new Error('Map image provider "' + wmtsName + '" is not WMTS');
        }
        return wms;
    }

    static olMip(olMipName: string): OlMip {
        const olMip = Container.mapImageProvider(olMipName);
        if(!(olMip instanceof OlMip)) {
            throw new Error('Map image provider "' + olMipName + '" is not an OpenLayers Map Image Provider');
        }
        return olMip;
    }

    static olVectorMip(olVectorMipName: string): OlVectorMip {
        const olMip = Container.mapImageProvider(olVectorMipName);
        if(!(olMip instanceof OlVectorMip)) {
            throw new Error('Map image provider "' + olVectorMipName + '" is not an OpenLayers Vector Map Image Provider');
        }
        return olMip;
    }

    static emptyMip(emptyMipName: string): EmptyMip {
        const emptyMip = Container.mapImageProvider(emptyMipName);
        if(!(emptyMip instanceof EmptyMip)) {
            throw new Error('Map image provider "' + emptyMipName + '" is not an Empty Map Image Provider');
        }
        return emptyMip;
    }

    static mapImageProviderList(): MapImageProvider[] {
        return Object.values(Container.mapImageProviders);
    }

    static registerSystemPaper(paper: Paper): void {
        Container.paperList.registerSystemPaper(paper);
    }

    static getPaperList(): PaperList {
        return Container.paperList;
    }

    static registerSystemCutoutTemplate(cutoutTemplate: CutoutTemplate<any, any, any>) {
        Container.systemCutoutTemplates.push(cutoutTemplate);
    }

    static registerCustomCutoutTemplate(cutoutTemplate: CutoutTemplate<any, any, any>) {
        Container.customCutoutTemplates.push(cutoutTemplate);
    }

    static getSerializedCustomCutoutTemplatesFromStorage(): Record<string, Serialization> {
        const entry = window.localStorage.getItem(Container.CUSTOM_CUTOUT_TEMPLATE_LOCALSTORAGE_KEY);
        if(entry === null) {
            return {};
        }
        return <Record<string, Serialization>>JSON.parse(entry);
    }

    static registerCustomCutoutTemplates(): void {
        const serializedTemplates = Container.getSerializedCustomCutoutTemplatesFromStorage();
        for(const name in serializedTemplates) {
            const serialized = serializedTemplates[name];
            const cutoutTemplate = CutoutTemplate.unserialize(serialized);
            Container.registerCustomCutoutTemplate(cutoutTemplate);
        }
    }

    static addCustomCutoutTemplate(cutoutTemplate: CutoutTemplate<any, any, any>) {
        Container.customCutoutTemplates.push(cutoutTemplate);

        const serializedTemplates = Container.getSerializedCustomCutoutTemplatesFromStorage();
        serializedTemplates[cutoutTemplate.name] = cutoutTemplate.serialize();
        window.localStorage.setItem(Container.CUSTOM_CUTOUT_TEMPLATE_LOCALSTORAGE_KEY, JSON.stringify(serializedTemplates));
    }

    static removeCustomCutoutTemplate(cutoutTemplate: CutoutTemplate<any, any, any>) {
        const index = Container.customCutoutTemplates.indexOf(cutoutTemplate);
        if(index > -1) {
            Container.customCutoutTemplates.splice(index, 1);
        }

        const serializedTemplates = Container.getSerializedCustomCutoutTemplatesFromStorage();
        delete serializedTemplates[cutoutTemplate.name];
        window.localStorage.setItem(Container.CUSTOM_CUTOUT_TEMPLATE_LOCALSTORAGE_KEY, JSON.stringify(serializedTemplates));
    }

    static cutoutTemplateList(): CutoutTemplate<any, any, any>[] {
        return [...Container.systemCutoutTemplates, ...Container.customCutoutTemplates];
    }

    static systemCutoutTemplateList(): CutoutTemplate<any, any, any>[] {
        return Container.systemCutoutTemplates;
    }

    static customCutoutTemplateList(): CutoutTemplate<any, any, any>[] {
        return Container.customCutoutTemplates;
    }

    static setNeighbourOverlap(overlap: number) {
        window.localStorage.setItem(Container.NEIGHBOUR_OVERLAP_LOCALSTORAGE_KEY, overlap.toString());
    }

    static getNeighbourOverlap(): number {
        const overlap = window.localStorage.getItem(Container.NEIGHBOUR_OVERLAP_LOCALSTORAGE_KEY);
        if(overlap === null) {
            return 5;
        }
        return parseInt(overlap);
    }

    static setDefaultAdvancedSettingsMode(defaultAdvancedSettingsMode: boolean): void {
        window.localStorage.setItem(Container.DEFAULT_ADVANCED_SETTINGS_MODE_LOCALSTORAGE_KEY, defaultAdvancedSettingsMode ? '1' : '0');
    }

    static getDefaultAdvancedSettingsMode(): boolean {
        return window.localStorage.getItem(Container.DEFAULT_ADVANCED_SETTINGS_MODE_LOCALSTORAGE_KEY) === '1';
    }

    static setPreferredCoordinateSystem(coordinateSystem: CoordinateSystem<Coordinate>) {
        window.localStorage.setItem(Container.PREFERRED_COORDSYSTEM_LOCALSTORAGE_KEY, coordinateSystem.code);
    }

    static setPreferredCoordinateFormatName(coordinate: Coordinate, formatName: string) {
        const formats = coordinate.formats();
        if(formats.hasOwnProperty(formatName)) {
            const preferredFormats = Container.getPreferredCoordinateFormats();
            preferredFormats[coordinate.code] = formatName;
            window.localStorage.setItem(Container.PREFERRED_FORMATS_LOCALSTORAGE_KEY, JSON.stringify(preferredFormats));
        }
    }

    static getPreferredCoordinateSystem(): CoordinateSystem<Coordinate> {
        const code = window.localStorage.getItem(Container.PREFERRED_COORDSYSTEM_LOCALSTORAGE_KEY);
        if(code === null) {
            return CoordinateConverter.getCoordinateSystem('EPSG:28992');
        } else {
            return CoordinateConverter.getCoordinateSystem(code);
        }
    }

    static getPreferredCoordinateFormats(): Record<string, string> {
        let preferredFormats = window.localStorage.getItem(Container.PREFERRED_FORMATS_LOCALSTORAGE_KEY);
        if(preferredFormats === null) {
            return {};
        } else {
            return JSON.parse(preferredFormats);
        }
    }

    static getCache(): Promise<Cache> {
        return new Promise((resolve, reject) => {
            if(Container.cache !== null) {
                resolve(Container.cache);
            } else {
                const cache = new Cache();
                cache.initialize().then(() => {
                    Container.cache = cache;
                    resolve(cache);
                });
            }
        });
    }

    static clearCaches(): Promise<void> {
        return new Promise((resolve, reject) => {
            Container.getCache().then((cache) => {
                return cache.clear().then(resolve, reject);
            });
        }).then(() => {
            alert('De buffers zijn geleegd');
        }).catch((...args) => {
            console.log(...args);
            alert('Something went wrong clearing the cache');
        });
    }

    static resetStorage(): void {
        window.localStorage.clear();
        Container.customCutoutTemplates = [];
        alert('De voorkeuren zijn gewist');
    }

    static resetApplication(): Promise<void> {
        return new Promise((resolve, reject) => {
            window.localStorage.clear();
            Container.customCutoutTemplates = [];

            if(Container.cache !== null) {
                Container.cache.close();
                Container.cache = null;
            }

            Cache.drop().then(resolve, reject);
        }).then(() => {
            alert('De applicatie is gewist. De applicatie zal nu opnieuw geopend worden.');
            window.location.reload();
        }).catch((...args) => {
            console.log(...args);
            alert('Something went wrong clearing the cache');
        });
    }

    public static allRouteTechniques() {
        const techniques = {};
        techniques[BolletjePijltje.TECHNIQUE_NAME] = BolletjePijltje;
        techniques[Bolletjes.TECHNIQUE_NAME] = Bolletjes;
        techniques[Graden.TECHNIQUE_NAME] = Graden;
        techniques[Helikopter.TECHNIQUE_NAME] = Helikopter;
        techniques[Kruispuntenroute.TECHNIQUE_NAME] = Kruispuntenroute;
        techniques[Oleaat.TECHNIQUE_NAME] = Oleaat;
        techniques[Situatieschets.TECHNIQUE_NAME] = Situatieschets;
        techniques[Stripkaart.TECHNIQUE_NAME] = Stripkaart;
        techniques[Vector.TECHNIQUE_NAME] = Vector;
        techniques[Vliegkoers.TECHNIQUE_NAME] = Vliegkoers;
        return techniques;
    }
}
