import Action from "./Action";
import Cutout from "../Main/Cutout";
import {Serialization} from "../Main/Serializer";
import {FlexagonOptions} from "../Main/AbstractCutout";

export default class UpdateCutoutFlexagonOptionAction<Type> implements Action {

    static readonly updateMapOptions = ['width', 'height', 'orientation', 'section_overlap'];
    static readonly mergableOptions = ['width', 'height', 'section_overlap'];

    private readonly oldValue: Type;

    constructor(
        private cutout: Cutout<any, any, any>,
        private key: keyof FlexagonOptions,
        private newValue: Type
    ) {
        this.oldValue = this.cutout.options.flexagon_options[this.key];
    }

    public apply() {
        this.cutout.setFlexagonOption(this.key, this.newValue);

        if(UpdateCutoutFlexagonOptionAction.updateMapOptions.indexOf(this.key) > -1) {
            this.cutout.updateMap();
        }
    }

    public revert() {
        this.cutout.setFlexagonOption(this.key, this.oldValue);

        if(UpdateCutoutFlexagonOptionAction.updateMapOptions.indexOf(this.key) > -1) {
            this.cutout.updateMap();
        }
    }

    public merge(newAction: Action): boolean {
        if(
            !(newAction instanceof UpdateCutoutFlexagonOptionAction)
            || newAction.cutout !== this.cutout
            || newAction.key !== this.key
            || newAction.oldValue !== this.newValue
        ) {
            return false;
        }

        if(UpdateCutoutFlexagonOptionAction.mergableOptions.indexOf(this.key) === -1) {
            return false;
        }

        this.newValue = newAction.newValue;
        this.apply();

        return true;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            key: this.key,
            oldValue: this.oldValue,
            newValue: this.newValue,
        };
    }
}
