<template>
  <div
      class="modal"
      tabindex="-1"
      role="dialog"
      v-bind:id="'route_technique_settings_modal_' + route.id"
      data-bs-backdrop="false"
      data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Routetechniek instellingen</h5>

          <a class="btn btn-sm btn-outline-secondary btn-modal-header-wiki" href="https://plattekaart.nl/wiki/routetechniek" target="_blank">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-book" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
            </svg>
            <span>Wiki</span>
          </a>

          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" role="tablist" v-bind:id="'rtsm_' + route.id + '_tabbar-tablist'">
            <li class="nav-item">
              <a
                  class="nav-link active"
                  v-bind:id="'rtsm_' + route.id + '_tabbar_general-tab'"
                  role="tab"
                  data-bs-toggle="tab"
                  v-bind:aria-controls="'rtsm_' + route.id + '_tabbar_general'"
                  v-bind:data-bs-target="'#rtsm_' + route.id + '_tabbar_general'"
                  aria-selected="true"
              >Algemeen</a>
            </li>
          </ul>
          <div class="tab-content">
            <div
                class="tab-pane show active"
                v-bind:id="'rtsm_' + route.id + '_tabbar_general'"
                role="tabpanel"
                v-bind:aria-labelledby="'rtsm_' + route.id + 'tabbar_general-tab'"
            >
              <div class="form-group">
                <label v-bind:for="'rtsm_' + route.id + '_start_number'" class="form-label">Startnummer</label>
                <input
                    type="number" class="form-control"
                    v-bind:id="'rtsm_' + route.id + '_start_number'"
                    v-bind:value="startNumber"
                    v-on:change="changeStartNumber($event)"
                    v-on:keyup="changeStartNumber($event)"
                    v-on:input="changeStartNumber($event)"
                    v-on:blur="changeStartNumber($event)"
                >
              </div>

              <div class="form-group">
                <label v-bind:for="'rtsm_' + route.id + '_technique'" class="form-label">Routetechniek</label>

                <a
                    v-if="routeTechniqueRP?.wiki"
                    class="float-end btn btn-sm btn-outline-secondary mt-m1"
                    :href="'https://plattekaart.nl/wiki/' + routeTechniqueRP.wiki"
                    target="_blank"
                >
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-book" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
                  </svg>
                  Wiki: {{ routeTechniqueRP?.title }}
                </a>

                <select
                    class="form-select" v-bind:id="'rtsm_' + route.id + '_technique'"
                    v-on:change="changeTechnique($event)"
                >
                  <option value="" v-if="!routeTechniqueRP">Kies...</option>
                  <option
                      v-for="techniqueName in Object.keys(availableTechniques)"
                      :key="techniqueName"
                      v-bind:value="techniqueName"
                      v-bind:selected="routeTechniqueRP?.name === techniqueName"
                  >{{ availableTechniques[techniqueName].TECHNIQUE_TITLE }}</option>
                </select>
              </div>

              <div v-if="routeTechniqueRP">
                <div class="form-group" v-if="typeof routeTechniqueRP.config.format !== 'undefined'">
                  <label v-bind:for="'rtsm_' + route.id + '_format'" class="form-label">Bestandsformaat</label>
                  <select
                      class="form-select"
                      v-bind:id="'rtsm_' + route.id + '_format'"
                      v-on:change="changeConfig($event, 'format', 'format')"
                  >
                    <option
                        v-for="{name, label} in imageFormats"
                        :key="name"
                        v-bind:value="name"
                        v-bind:selected="routeTechniqueRP.config.format === name"
                    >{{ label }}</option>
                  </select>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.transparent !== 'undefined' && routeTechniqueRP.config.format === 'png'">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        v-bind:id="'rtsm_' + route.id + '_transparent'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="routeTechniqueRP.config.transparent"
                        v-on:change="changeConfig($event, 'transparent', 'transparent')"
                    >
                    <label class="form-check-label" v-bind:for="'rtsm_' + route.id + '_transparent'">Transparante achtergrond</label>
                  </div>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.displayScale !== 'undefined'">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        v-bind:id="'rtsm_' + route.id + '_display_scale'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="routeTechniqueRP.config.displayScale"
                        v-on:change="changeConfig($event, 'display_scale', 'displayScale')"
                    >
                    <label class="form-check-label" v-bind:for="'rtsm_' + route.id + '_display_scale'">Schaal-indicator weergeven</label>
                  </div>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.displayNorthArrow !== 'undefined'">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        v-bind:id="'rtsm_' + route.id + '_display_north_arrow'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="routeTechniqueRP.config.displayNorthArrow"
                        v-on:change="changeConfig($event, 'display_north_arrow', 'displayNorthArrow')"
                    >
                    <label class="form-check-label" v-bind:for="'rtsm_' + route.id + '_display_north_arrow'">Noordpijl tekenen</label>
                  </div>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.drawSideRoads !== 'undefined'">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        v-bind:id="'rtsm_' + route.id + '_draw_side_roads'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="routeTechniqueRP.config.drawSideRoads"
                        v-on:change="changeConfig($event, 'draw_side_roads', 'drawSideRoads')"
                    >
                    <label class="form-check-label" v-bind:for="'rtsm_' + route.id + '_draw_side_roads'">Zijwegen tekenen</label>
                  </div>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.drawRouteLine !== 'undefined'">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        v-bind:id="'rtsm_' + route.id + '_draw_route_line'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="routeTechniqueRP.config.drawRouteLine"
                        v-on:change="changeConfig($event, 'draw_route_line', 'drawRouteLine')"
                    >
                    <label class="form-check-label" v-bind:for="'rtsm_' + route.id + '_draw_route_line'">Route lijn tekenen</label>
                  </div>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.rotation !== 'undefined'">
                  <label v-bind:for="'rtsm_' + route.id + '_rotation'" class="form-label">Rotatie (graden)</label>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'rtsm_' + route.id + '_rotation'"
                      v-bind:value="routeTechniqueRP.config.rotation"
                      v-on:change="changeConfig($event, 'rotation', 'rotation')"
                      v-on:keyup="changeConfig($event, 'rotation', 'rotation')"
                      v-on:input="changeConfig($event, 'rotation', 'rotation')"
                      v-on:blur="changeConfig($event, 'rotation', 'rotation')"
                      min="0"
                      max="360"
                  />
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.roadWidth !== 'undefined'">
                  <label v-bind:for="'rtsm_' + route.id + '_road_width'" class="form-label">Wegbreedte (meters)</label>

                  <div class="form-check d-inline-block ms-2">
                    <input
                        class="form-check-input"
                        v-bind:id="'rtsm_' + route.id + '_road_width_auto'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="routeTechniqueRP.config.roadWidth === null"
                        v-on:change="changeRoadWidthAuto()"
                    >
                    <label class="form-check-label small" v-bind:for="'rtsm_' + route.id + '_road_width_auto'">Automatisch</label>
                  </div>

                  <input
                      type="number"
                      class="form-control"
                      v-if="routeTechniqueRP.config.roadWidth !== null"
                      v-bind:id="'rtsm_' + route.id + '_road_width'"
                      v-bind:value="routeTechniqueRP.config.roadWidth"
                      v-on:change="changeConfig($event, 'road_width', 'roadWidth')"
                      v-on:keyup="changeConfig($event, 'road_width', 'roadWidth')"
                      v-on:input="changeConfig($event, 'road_width', 'roadWidth')"
                      v-on:blur="changeConfig($event, 'road_width', 'roadWidth')"
                      min="0"
                      step="0.1"
                  />
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.bolpiConvention !== 'undefined'">
                  <label v-bind:for="'rtsm_' + route.id + '_bolpi_convention'" class="form-label">Bolletje-Pijltje methode</label>
                  <select
                      class="form-select"
                      v-bind:id="'rtsm_' + route.id + '_bolpi_convention'"
                      v-on:change="changeConfig($event, 'bolpi_convention', 'bolpiConvention')"
                  >
                    <option
                        v-for="{name, label} in bolpiConventions"
                        :key="name"
                        v-bind:value="name"
                        v-bind:selected="routeTechniqueRP.config.bolpiConvention === name"
                    >{{ label }}</option>
                  </select>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.kprOrientation !== 'undefined'">
                  <label v-bind:for="'rtsm_' + route.id + '_kpr_orientation'" class="form-label">Oriëntatie</label>
                  <select
                      class="form-select"
                      v-bind:id="'rtsm_' + route.id + '_kpr_orientation'"
                      v-on:change="changeConfig($event, 'kpr_orientation', 'kprOrientation')"
                  >
                    <option
                        v-for="{name, label} in kprOrientations"
                        :key="name"
                        v-bind:value="name"
                        v-bind:selected="routeTechniqueRP.config.kprOrientation === name"
                    >{{ label }}</option>
                  </select>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.kprOrientationSeed !== 'undefined' && routeTechniqueRP.config.kprOrientation === 'random-90'">
                  <label v-bind:for="'rtsm_' + route.id + '_kpr_orientation_seed'" class="form-label">Oriëntatie seed</label>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'rtsm_' + route.id + '_kpr_orientation_seed'"
                      v-bind:value="routeTechniqueRP.config.kprOrientationSeed"
                      v-on:change="changeConfig($event, 'kpr_orientation_seed', 'kprOrientationSeed')"
                      v-on:keyup="changeConfig($event, 'kpr_orientation_seed', 'kprOrientationSeed')"
                      v-on:input="changeConfig($event, 'kpr_orientation_seed', 'kprOrientationSeed')"
                      v-on:blur="changeConfig($event, 'kpr_orientation_seed', 'kprOrientationSeed')"
                      min="1"
                      max="2147483648"
                  />
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.kprInstruction !== 'undefined'">
                  <label v-bind:for="'rtsm_' + route.id + '_kpr_instruction'" class="form-label">Richting instructie middels&mldr;</label>
                  <select
                      class="form-select"
                      v-bind:id="'rtsm_' + route.id + '_kpr_instruction'"
                      v-on:change="changeConfig($event, 'kpr_instruction', 'kprInstruction')"
                  >
                    <option
                        v-for="{name, label} in kprInstructions"
                        :key="name"
                        v-bind:value="name"
                        v-bind:selected="routeTechniqueRP.config.kprInstruction === name"
                    >{{ label }}</option>
                  </select>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.kprDrawIncomingPath !== 'undefined'">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        v-bind:id="'rtsm_' + route.id + '_kpr_draw_incoming_path'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="routeTechniqueRP.config.kprDrawIncomingPath"
                        v-on:change="changeConfig($event, 'kpr_draw_incoming_path', 'kprDrawIncomingPath')"
                    >
                    <label class="form-check-label" v-bind:for="'rtsm_' + route.id + '_kpr_draw_incoming_path'">Aankomst pad aangeven</label>
                  </div>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.kprSnappingDirections !== 'undefined'">
                  <label v-bind:for="'rtsm_' + route.id + '_kpr_snapping_directions'" class="form-label">Richtingen afronden op veelvoud van graden</label>
                  <select
                      class="form-select"
                      v-bind:id="'rtsm_' + route.id + '_kpr_snapping_directions'"
                      v-on:change="changeConfig($event, 'kpr_snapping_directions', 'kprSnappingDirections')"
                  >
                    <option
                        v-for="{name, label} in kprSnappingDirections"
                        :key="name"
                        v-bind:value="name"
                        v-bind:selected="routeTechniqueRP.config.kprSnappingDirections === name"
                    >{{ label }}</option>
                  </select>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.kprTilingLayout !== 'undefined'">
                  <label v-bind:for="'rtsm_' + route.id + '_kpr_tiling_layout'" class="form-label">Indeling</label>
                  <select
                      class="form-select"
                      v-bind:id="'rtsm_' + route.id + '_kpr_tiling_layout'"
                      v-on:change="changeConfig($event, 'kpr_tiling_layout', 'kprTilingLayout')"
                  >
                    <option
                        v-for="{name, label} in kprTilingLayouts"
                        :key="name"
                        v-bind:value="name"
                        v-bind:selected="routeTechniqueRP.config.kprTilingLayout === name"
                    >{{ label }}</option>
                  </select>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.useRoughJs !== 'undefined'">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        v-bind:id="'rtsm_' + route.id + '_use_roughjs'"
                        type="checkbox"
                        value="1"
                        v-bind:checked="routeTechniqueRP.config.useRoughJs"
                        v-on:change="changeConfig($event, 'use_roughjs', 'useRoughJs')"
                    >
                    <label class="form-check-label" v-bind:for="'rtsm_' + route.id + '_use_roughjs'">Gebruik getekende stijl</label>
                  </div>
                </div>

                <div class="form-group" v-if="typeof routeTechniqueRP.config.roughJsSeed !== 'undefined' && routeTechniqueRP.config.useRoughJs">
                  <label v-bind:for="'rtsm_' + route.id + '_roughjs_seed'" class="form-label">Tekening seed</label>
                  <input
                      type="number"
                      class="form-control"
                      v-bind:id="'rtsm_' + route.id + '_roughjs_seed'"
                      v-bind:value="routeTechniqueRP.config.roughJsSeed"
                      v-on:change="changeConfig($event, 'roughjs_seed', 'roughJsSeed')"
                      v-on:keyup="changeConfig($event, 'roughjs_seed', 'roughJsSeed')"
                      v-on:input="changeConfig($event, 'roughjs_seed', 'roughJsSeed')"
                      v-on:blur="changeConfig($event, 'roughjs_seed', 'roughJsSeed')"
                      min="1"
                      max="2147483648"
                  />
                </div>

                <button
                    type="button"
                    class="btn btn-primary float-end"
                    v-on:click="downloadRouteTechnique()"
                >
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                  </svg>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import $ from "jquery";
import RouteTechnique from "../RouteTechniques/RouteTechnique";
import UpdateRouteTechniqueOptionAction from "../ActionHistory/UpdateRouteTechniqueOptionAction";
import RouteIntermediatesChangeStartNumber from "../ActionHistory/RouteIntermediatesChangeStartNumber";
import Route from "../Main/Route";
import Container from "../Main/Container";
import RouteIntermediatesChangeRouteTechnique from "../ActionHistory/RouteIntermediatesChangeRouteTechnique";
import {KPR_INSTRUCTIONS, KPR_ORIENTATIONS, KPR_SNAPPING_DIRECTIONS, KPR_TILING_LAYOUTS} from "../RouteTechniques/Kruispuntenroute";
import {BOLPI_CONVENTIONS} from "../RouteTechniques/BolletjePijltje";
import {bsModal} from "../Util/vueFunctions";

export default {
  props: {
    listenId: String,
    route: Route,
  },
  data() {
    $(() => {
      const route = this.route;

      $('#' + this.listenId).on('click', (event) => {
        event.stopPropagation();

        bsModal('#route_technique_settings_modal_' + this.route.id).show();
      });
    });

    return {
      imageFormats: [
        {name: 'png', label: 'PNG'},
        {name: 'svg', label: 'SVG'},
      ],
      bolpiConventions: BOLPI_CONVENTIONS,
      kprOrientations: KPR_ORIENTATIONS,
      kprInstructions: KPR_INSTRUCTIONS,
      kprSnappingDirections: KPR_SNAPPING_DIRECTIONS,
      kprTilingLayouts: KPR_TILING_LAYOUTS,
      availableTechniques: Container.allRouteTechniques(),
    };
  },
  watch: {
  },
  computed: {
    startNumber: function () {
      return this.route.getIntermediates().reactiveProps.startNumber;
    },
    routeTechniqueRP: function () {
      return this.route.reactiveProps.intermediatesRP.routeTechniqueRP;
    }
  },
  methods: {
    changeStartNumber(event) {
      const newStartNumber = parseInt($('#rtsm_' + this.route.id + '_start_number').val());
      if (!isNaN(newStartNumber) && newStartNumber !== this.route.getIntermediates().getStartNumber()) {
        this.route.routeCollection.userInterface.actionHistory.addAction(
            new RouteIntermediatesChangeStartNumber(this.route, newStartNumber)
        );
      }
    },
    changeTechnique(event) {
      const newTechnique = $('#rtsm_' + this.route.id + '_technique').val();
      if (newTechnique !== this.routeTechniqueRP?.name) {
        this.route.routeCollection.userInterface.actionHistory.addAction(
            new RouteIntermediatesChangeRouteTechnique(this.route, newTechnique)
        );
      }
    },
    changeConfig(event, optionKey, optionProperty) {
      const routeTechnique: RouteTechnique<any> = this.route.getIntermediates().getRouteTechnique();

      const $input = $('#rtsm_' + this.route.id + '_' + optionKey);

      if (optionProperty === 'roughJsSeed' || optionProperty === 'kprOrientationSeed') {
        if ($input.val() > 2**31) {
          $input.val(2**31);
        } else if ($input.val() < 1) {
          $input.val(1);
        }
      }

      let newVal = (['transparent', 'displayScale', 'useRoughJs', 'displayNorthArrow', 'drawSideRoads', 'drawRouteLine', 'kprDrawIncomingPath'].indexOf(optionProperty) > -1) ? $input.prop('checked') : $input.val();

      if (['roughJsSeed', 'rotation', 'kprOrientationSeed'].includes(optionProperty)) {
        newVal = parseInt(newVal);
      } else if (['roadWidth'].includes(optionProperty)) {
        newVal = parseFloat(newVal.replace(',', '.'));
      }

      if (newVal !== routeTechnique.getConfig()[optionProperty]) {
        this.route.routeCollection.userInterface.actionHistory.addAction(new UpdateRouteTechniqueOptionAction(
            routeTechnique,
            optionKey,
            newVal
        ));
      }
    },
    changeRoadWidthAuto() {
      const routeTechnique: RouteTechnique<any> = this.route.getIntermediates().getRouteTechnique();
      const newRoadWidthAuto = $('#rtsm_' + this.route.id + '_road_width_auto').is(':checked');

      this.route.routeCollection.userInterface.actionHistory.addAction(new UpdateRouteTechniqueOptionAction(
          routeTechnique,
          'road_width',
          newRoadWidthAuto ? null : Math.round(routeTechnique.computeAutoRoadWidth() * 10) / 10,
      ));
    },
    downloadRouteTechnique() {
      if (this.route.getIntermediates().getRouteTechnique()) {
        this.route.getIntermediates().downloadRouteTechnique();
      }
    },
  }
};
</script>

<style scoped>
</style>
