import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import {Options} from "roughjs/bin/core";
import {
    degreesToRadians,
    olPathStartBearing,
    olProjectTowards,
    olTrimPath,
    projectOlPathCircle,
    randomInt,
    sliceOlPathMeters, radiansToDegrees, bearingToRadians
} from "../Util/Math";
import Route from "../Main/Route";
import UserError from "../Util/UserError";
import {GraphPoint, offset} from "../Util/PolylineOffset/PolylineOffset";
import RouteIntermediate from "../Main/RouteIntermediate";
import {IntermediateMeta} from "../Main/RouteIntermediates";
import {ArcSegment, Drawing, drawingMinMax, LineSegment, Point} from "../Util/PolylineOffset/DrawingGeometry";
import SideRoadSnapping from "./Util/SideRoadSnapping";
import {max} from "../Util/functions";
import {Coordinate as olCoordinate} from "ol/coordinate";
import {createSvgEl} from "./Util/Svg";
import {TipArrow} from "./Util/Arrow";
import {RandomNumberGenerator} from "../Util/RandomNumberGenerator";

type Orientation = 'incoming-bottom' | 'north-top' | 'random-90';
type Instruction = 'arrow' | 'quiz';
type SnappingDirections = 'no-snapping' | '4' | '8' | '12' | '24' | '36' | '60' | '72' | '120' | '180' | '360';
type TilingLayout = 'regular-grid' | 'fill-compact';

export const KPR_ORIENTATIONS = [
    {name: 'incoming-bottom', label: 'Aankomst onder'},
    {name: 'north-top', label: 'Noorden boven'},
    {name: 'random-90', label: 'Willekeurige 90° draaiing'},
];

export const KPR_INSTRUCTIONS = [
    {name: 'arrow', label: 'Richtingpijl'},
    {name: 'quiz', label: 'Quiz-letters'},
];

export const KPR_SNAPPING_DIRECTIONS = [
    {name: 'no-snapping', label: 'Niet afronden'},
    {name: '4', label: '90 graden (4 richtingen)'},
    {name: '8', label: '45 graden (8 richtingen)'},
    {name: '12', label: '30 graden (12 richtingen)'},
    {name: '24', label: '15 graden (24 richtingen)'},
    {name: '36', label: '10 graden (36 richtingen)'},
    {name: '60', label: '6 graden (60 richtingen)'},
    {name: '72', label: '5 graden (72 richtingen)'},
    {name: '120', label: '3 graden (120 richtingen)'},
    {name: '180', label: '2 graden (180 richtingen)'},
    {name: '360', label: '1 graad (360 richtingen)'},
];

export const KPR_TILING_LAYOUTS = [
    {name: 'regular-grid', label: 'Regelmatig raster'},
    {name: 'fill-compact', label: 'Compact vullend'},
];

type KruispuntenrouteConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
    displayNorthArrow: boolean,
    kprOrientation: Orientation,
    kprOrientationSeed: number,
    kprInstruction: Instruction,
    kprDrawIncomingPath: boolean,
    kprSnappingDirections: SnappingDirections,
    kprTilingLayout: TilingLayout,
};

type IntermediateDrawing = {
    minX: number,
    minY: number,
    width: number,
    height: number,
    drawing: Drawing,
    intermediate: RouteIntermediate,
    intermediateMeta: IntermediateMeta,
    inRoad: olCoordinate[],
    outRoad: olCoordinate[],
    sizeMeasure: number,
    bearingNorth: number,
    roadOptions: olCoordinate[][],
    centerCoordinate: olCoordinate,
};

export default class Kruispuntenroute extends ImageGeneratingTechnique<KruispuntenrouteConfig>
{
    public static readonly DEBUG = false;

    public static readonly TECHNIQUE_NAME = 'kruispuntenroute';
    public static readonly TECHNIQUE_TITLE = 'Kruispuntenroute (experimenteel)';
    public static readonly TECHNIQUE_WIKI = 'kruispuntenroute';

    protected readonly requiresIntermediates = true;

    static readonly BOX_PADDING = 10;
    static readonly BOX_HEIGHT = 150;
    static readonly BOX_INFO_WIDTH = 20;
    static readonly BOX_INFO_PADDING = 5;
    static readonly MAX_WIDTH = 1000;

    protected getDefaultConfig(): KruispuntenrouteConfig {
        return {
            format: 'png',
            transparent: false,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
            displayNorthArrow: false,
            kprOrientation: "incoming-bottom",
            kprOrientationSeed: randomInt(0, 2**31),
            kprInstruction: "arrow",
            kprDrawIncomingPath: true,
            kprSnappingDirections: "no-snapping",
            kprTilingLayout: "regular-grid",
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 0.6,
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const intermediateDrawings = this.computeIntermediateDrawings();

        const bases = [];
        let nextBase = [Kruispuntenroute.BOX_PADDING, Kruispuntenroute.BOX_PADDING];
        let imageWidth = 0, imageHeight = 0;

        /*
         * A: BOX_PADDING
         * B: BOX_HEIGHT
         * C: BOX_INFO_WIDTH
         * D: BOX_INFO_PADDING
         *
         *           ^
         *           A
         *           V
         *      +----------+---+--------------+         +----------+---+--------------+         ^
         *      |   (1.)   |   |              |         |   (2.)   |   |              |         |
         * <-A->|          |<D>|              |<---A--->|          |   |              |<-A->    |
         *      |          |   |   (DRAWING)  |         |          |   |   (DRAWING)  |         B
         *      |<---C---->|   |              |         |          |   |              |         |
         *      |          |   |              |         |          |   |              |         |
         *      +----------+---+--------------+         +----------+---+--------------+         V
         *           ^
         *           A
         *           V
         *      +----------+---+--------------+
         *      |          |   |              |
         */

        if (this.config.kprTilingLayout === 'regular-grid') {
            const drawingBoxWidth = Kruispuntenroute.BOX_HEIGHT + Kruispuntenroute.BOX_INFO_WIDTH + Kruispuntenroute.BOX_INFO_PADDING;
            const drawingBoxHeight = Kruispuntenroute.BOX_HEIGHT;

            const numColumns = Math.floor((Kruispuntenroute.MAX_WIDTH - Kruispuntenroute.BOX_PADDING) / (drawingBoxWidth + Kruispuntenroute.BOX_PADDING));
            const numRows = Math.ceil(intermediateDrawings.length / numColumns);

            imageWidth = Kruispuntenroute.BOX_PADDING + numColumns * (drawingBoxWidth + Kruispuntenroute.BOX_PADDING);
            imageHeight = Kruispuntenroute.BOX_PADDING + numRows * (drawingBoxHeight + Kruispuntenroute.BOX_PADDING);

            for (let i = 0; i < intermediateDrawings.length; i++) {
                bases.push([
                    Kruispuntenroute.BOX_PADDING + (i % numColumns) * (drawingBoxWidth + Kruispuntenroute.BOX_PADDING),
                    Kruispuntenroute.BOX_PADDING + Math.floor(i / numColumns) * (drawingBoxHeight + Kruispuntenroute.BOX_PADDING),
                ]);
            }
        } else if (this.config.kprTilingLayout === 'fill-compact') {
            for (const intermediateDrawing of intermediateDrawings) {
                const width = intermediateDrawing.width / intermediateDrawing.height * Kruispuntenroute.BOX_HEIGHT;
                const effectiveWidth = width + Kruispuntenroute.BOX_INFO_WIDTH + Kruispuntenroute.BOX_INFO_PADDING + Kruispuntenroute.BOX_PADDING;

                if (
                    nextBase[0] !== Kruispuntenroute.BOX_PADDING
                    && nextBase[0] + effectiveWidth > Kruispuntenroute.MAX_WIDTH
                ) {
                    nextBase[0] = Kruispuntenroute.BOX_PADDING;
                    nextBase[1] += Kruispuntenroute.BOX_PADDING + Kruispuntenroute.BOX_HEIGHT;
                }

                bases.push(nextBase);

                nextBase = [nextBase[0] + effectiveWidth, nextBase[1]];
                imageWidth = Math.max(imageWidth, nextBase[0] + Kruispuntenroute.BOX_PADDING);
            }

            imageHeight = bases[bases.length - 1][1] + Kruispuntenroute.BOX_HEIGHT + Kruispuntenroute.BOX_PADDING;
        } else {
            throw new Error('Invalid tiling layout');
        }

        const svg = createSvgEl('svg', {
            width: imageWidth % 1 === 0 ? imageWidth.toFixed(1) : imageWidth,
            height: imageHeight % 1 === 0 ? imageHeight.toFixed(1) : imageHeight,
            viewBox: "0 0 " + imageWidth + " " + imageHeight,
        });

        for (let i = 0; i < intermediateDrawings.length; i++) {
            this.drawIntermediateDrawing(svg, bases[i], intermediateDrawings[i]);
        }

        return svg;
    }

    private computeIntermediateDrawings(): IntermediateDrawing[]
    {
        const EQUIDISTANT_LENGTH = 20;

        const intermediatesMeta = this.route.getIntermediates().computeIntermediateMeta();
        const intermediates = this.route.getIntermediates().getIntermediatesList();

        const intermediateDrawings = <IntermediateDrawing[]>[];

        const orientationRng = (this.config.kprOrientation === 'random-90') ? new RandomNumberGenerator(this.config.kprOrientationSeed) : null;

        for (let i = 0; i < intermediates.length; i++) {
            const intermediate = intermediates[i];

            if (intermediatesMeta[i].pathIncomingBearing === null) {
                throw new UserError('Deze routetechniek kan niet gemaakt worden met een route die op een beslispunt begint. Pas de route aan zodat het eerste beslispunt een inkomende weg heeft.');
            }
            if (intermediatesMeta[i].pathInitialBearing === null) {
                throw new UserError('Deze routetechniek kan niet gemaakt worden met een route die op een beslispunt eindigt. Pas de route aan zodat het laatste beslispunt een uitgaande weg heeft.');
            }

            const centerCoordinate = intermediate.getOlCoordinate();

            const [inRoad, outRoad, equidistant] = this.determineInOutRoad(
                centerCoordinate,
                intermediate,
                intermediates,
                i,
                EQUIDISTANT_LENGTH
            );

            let [roads, fromRoadCoords, toRoadCoords, bearingNorth, bearingIncoming] = this.transformToAbstract(
                equidistant,
                EQUIDISTANT_LENGTH,
                intermediatesMeta,
                i,
                inRoad,
                outRoad,
                centerCoordinate,
                intermediate,
            );

            [roads, fromRoadCoords, toRoadCoords, bearingNorth, bearingIncoming] = this.rotateRoads(
                centerCoordinate,
                roads,
                fromRoadCoords,
                toRoadCoords,
                bearingNorth,
                bearingIncoming,
                orientationRng,
            );

            const [sortedRoads, startPoint] = this.buildRoadGraph(
                centerCoordinate,
                roads,
                bearingIncoming,
                fromRoadCoords,
            );

            const [minX, minY, width, height, drawing, sizeMeasure] = this.createDrawing(
                centerCoordinate,
                sortedRoads,
                startPoint,
            );

            intermediateDrawings.push({
                minX: minX,
                minY: minY,
                width: width,
                height: height,
                drawing: drawing,
                intermediate: intermediate,
                intermediateMeta: intermediatesMeta[i],
                inRoad: [...fromRoadCoords].reverse(),
                outRoad: toRoadCoords,
                sizeMeasure: sizeMeasure,
                bearingNorth: bearingNorth,
                roadOptions: [...sortedRoads].reverse(),
                centerCoordinate: centerCoordinate,
            });
        }

        return intermediateDrawings;
    }

    private determineInOutRoad(
        centerCoordinate: olCoordinate,
        intermediate: RouteIntermediate,
        intermediates: RouteIntermediate[],
        i: number,
        EQUIDISTANT_LENGTH: number,
    ): [olCoordinate[], olCoordinate[], boolean] {
        let maxNodes = null;
        let maxLength = null;
        for (const sideRoad of intermediate.getSideRoads()) {
            maxNodes = max(maxNodes, sideRoad.getCoordinates().length);
            maxLength = max(maxLength, sideRoad.getLength());
        }

        maxNodes ||= 2;

        if (maxNodes < 2) {
            throw new Error();
        }

        const equidistant = maxNodes === 2;

        let inRoad, outRoad;
        if (equidistant) {
            maxLength ||= 10;

            const inStart = projectOlPathCircle(this.route.getCoordinates(), centerCoordinate, Math.min(10, maxLength), intermediate.index, true, intermediates[i - 1]?.index || undefined, EQUIDISTANT_LENGTH);
            const outEnd = projectOlPathCircle(this.route.getCoordinates(), centerCoordinate, Math.min(10, maxLength), intermediate.index, false, intermediates[i + 1]?.index || undefined, EQUIDISTANT_LENGTH);

            inRoad = [centerCoordinate, inStart];
            outRoad = [centerCoordinate, outEnd];
        } else {
            maxLength ||= 50;

            inRoad = sliceOlPathMeters(this.route.getCoordinates(), maxLength, intermediate.index, true, intermediates[i - 1]?.index || undefined);
            outRoad = sliceOlPathMeters(this.route.getCoordinates(), maxLength, intermediate.index, false, intermediates[i + 1]?.index || undefined);
        }

        return [inRoad, outRoad, equidistant];
    }

    private transformToAbstract(
        equidistant: boolean,
        EQUIDISTANT_LENGTH: number,
        intermediatesMeta: IntermediateMeta[],
        i: number,
        inRoad: olCoordinate[],
        outRoad: olCoordinate[],
        centerCoordinate: olCoordinate,
        intermediate: RouteIntermediate
    ): [olCoordinate[][], olCoordinate[], olCoordinate[], number, number] {
        let processRoad: (road: olCoordinate[]) => olCoordinate[];
        if (equidistant) {
            processRoad = road => [road[0], olProjectTowards(road[0], road[1], EQUIDISTANT_LENGTH)];
        } else {
            processRoad = road => road;
        }

        const roads: olCoordinate[][] = [];
        let fromRoadCoords: olCoordinate[]|null = null, toRoadCoords: olCoordinate[]|null = null;
        let bearingNorth: number;
        let bearingIncoming = intermediatesMeta[i].pathIncomingBearing;

        if (this.config.kprSnappingDirections === 'no-snapping') {
            fromRoadCoords = inRoad.slice(1);
            toRoadCoords = outRoad.slice(1);

            roads.push(toRoadCoords);

            for (const sideRoad of intermediate.getSideRoads()) {
                roads.push(processRoad(sideRoad.getCoordinates()).slice(1));
            }

            bearingNorth = 0;
        } else {
            const snap = new SideRoadSnapping(centerCoordinate, parseInt(this.config.kprSnappingDirections));

            snap.addFromRoad('in', inRoad);
            snap.addRoad('out', outRoad);

            for (const sideRoad of intermediate.getSideRoads()) {
                snap.addRoad(sideRoad, processRoad(sideRoad.getCoordinates()));
            }

            const roadResults = snap.snap();
            if (roadResults === null) {
                throw new UserError('Beslispunt ' + intermediatesMeta[i].intermediateNumber + ' heeft te veel zijwegen dicht op elkaar voor het geselecteerde maximum aantal kruispuntrichtingen.');
            }

            for (const roadResult of roadResults) {
                if (roadResult.reference === 'in') {
                    fromRoadCoords = roadResult.coordinates;
                } else {
                    if (roadResult.reference === 'out') {
                        toRoadCoords = roadResult.coordinates;
                    }

                    roads.push(roadResult.coordinates);
                }
            }

            bearingNorth = snap.getOffset();
            bearingIncoming += snap.getOffset();
        }

        if (fromRoadCoords === null || toRoadCoords === null) {
            throw new Error();
        }

        return [roads, fromRoadCoords, toRoadCoords, bearingNorth, bearingIncoming];
    }

    private rotateRoads(
        centerCoordinate: olCoordinate,
        roads: olCoordinate[][],
        fromRoadCoords: olCoordinate[],
        toRoadCoords: olCoordinate[],
        bearingNorth: number,
        bearingIncoming: number,
        orientationRng: RandomNumberGenerator,
    ) {
        let rotation: number|null = null;
        if (this.config.kprOrientation === 'incoming-bottom') {
            const startBearing = olPathStartBearing([centerCoordinate, ...fromRoadCoords], 10);

            rotation = degreesToRadians(startBearing - 180);
        } else if(this.config.kprOrientation === 'random-90') {
            rotation = degreesToRadians(orientationRng.nextBetween(0, 4) * 90);
        }

        if (rotation === null) {
            return [roads, fromRoadCoords, toRoadCoords, bearingNorth, bearingIncoming];
        }

        bearingNorth = (bearingNorth - radiansToDegrees(rotation) + 360) % 360;
        bearingIncoming = (bearingIncoming - radiansToDegrees(rotation) + 360) % 360;

        const rotationMap: ((c: olCoordinate) => olCoordinate) = c => {
            return [
                centerCoordinate[0] + Math.cos(rotation) * (c[0] - centerCoordinate[0]) - Math.sin(rotation) * (c[1] - centerCoordinate[1]),
                centerCoordinate[1] + Math.sin(rotation) * (c[0] - centerCoordinate[0]) + Math.cos(rotation) * (c[1] - centerCoordinate[1]),
            ];
        };

        fromRoadCoords = fromRoadCoords.map(rotationMap);
        toRoadCoords = toRoadCoords.map(rotationMap);

        const rotatedRoads = roads.map((coordinates) => coordinates.map(rotationMap));

        return [rotatedRoads, fromRoadCoords, toRoadCoords, bearingNorth, bearingIncoming];
    }

    private buildRoadGraph(
        centerCoordinate: olCoordinate,
        roads: olCoordinate[][],
        bearingIncoming: number,
        fromRoadCoords: olCoordinate[]
    ): [olCoordinate[][], GraphPoint] {
        const roadOptions = roads.map((coordinates) => {
            return {
                bearing: Route.computeBearing(centerCoordinate, coordinates[0]),
                points: coordinates,
            };
        });

        // Starting at bearingIncoming, we want to go counterclockwise
        const split = (bearingIncoming + 180) % 360;
        roadOptions.sort((a, b) => {
            const bearingA = a.bearing > split ? a.bearing - 360 : a.bearing;
            const bearingB = b.bearing > split ? b.bearing - 360 : b.bearing;

            return - (bearingA - bearingB);
        });

        let startPoint: GraphPoint|null = null, lastPoint: GraphPoint|null = null;

        for (const fromCoordinate of [...fromRoadCoords].reverse()) {
            const point = <GraphPoint>{
                x: fromCoordinate[0],
                y: fromCoordinate[1],
                neighbours: lastPoint ? [lastPoint] : [],
            };
            lastPoint?.neighbours?.push(point);

            if (startPoint === null) {
                startPoint = point;
            }
            lastPoint = point;
        }

        const centerPoint = <GraphPoint>{
            x: centerCoordinate[0],
            y: centerCoordinate[1],
            neighbours: [lastPoint],
        };
        lastPoint.neighbours.push(centerPoint);

        for (const {bearing, points} of roadOptions) {
            let previousPoint = centerPoint;

            for (let j = 0; j < points.length; j++) {
                if (points[j][0] === previousPoint.x && points[j][1] === previousPoint.y) {
                    continue;
                }

                let nextPoint = <GraphPoint>{
                    x: points[j][0],
                    y: points[j][1],
                    neighbours: [previousPoint],
                };
                previousPoint.neighbours.push(nextPoint);

                previousPoint = nextPoint;
            }
        }

        return [roadOptions.map(option => option.points), startPoint];
    }

    private createDrawing(
        centerCoordinate: olCoordinate,
        sortedRoads: olCoordinate[][],
        startPoint: GraphPoint,
    ): [number, number, number, number, Drawing, number] {

        let maxDistance = null;
        for (const road of sortedRoads) {
            for (const coordinate of road) {
                maxDistance = max(maxDistance, max(Math.abs(coordinate[0] - centerCoordinate[0]), Math.abs(coordinate[1] - centerCoordinate[1])));
            }
        }

        // Empirically determined size measure
        const sizeMeasure = 1.4 * Math.sqrt(maxDistance);
        const drawing = offset(startPoint, sizeMeasure);

        const {minX, minY, maxX, maxY} = drawingMinMax(drawing);

        return [minX, minY, maxX - minX, maxY - minY, drawing, sizeMeasure];
    }

    private drawIntermediateDrawing(svg: SVGSVGElement, base: [number, number], intermediateDrawing: IntermediateDrawing): void {
        let scale: number;

        let transformX: (x: number) => number;
        let transformY: (y: number) => number;

        if (this.config.kprTilingLayout === 'regular-grid') {
            const centeredWidth = 2 * Math.max(
                intermediateDrawing.centerCoordinate[0] - intermediateDrawing.minX,
                intermediateDrawing.minX + intermediateDrawing.width - intermediateDrawing.centerCoordinate[0],
            );
            const centeredHeight = 2 * Math.max(
                intermediateDrawing.centerCoordinate[1] - intermediateDrawing.minY,
                intermediateDrawing.minY + intermediateDrawing.height - intermediateDrawing.centerCoordinate[1],
            );

            scale = Math.min(Kruispuntenroute.BOX_HEIGHT / centeredHeight, Kruispuntenroute.BOX_HEIGHT / centeredWidth);

            transformX = x => base[0] + Kruispuntenroute.BOX_INFO_WIDTH + Kruispuntenroute.BOX_INFO_PADDING + Kruispuntenroute.BOX_HEIGHT / 2 + (x - intermediateDrawing.centerCoordinate[0]) * scale;
            transformY = y => base[1] + Kruispuntenroute.BOX_HEIGHT / 2 - (y - intermediateDrawing.centerCoordinate[1]) * scale;
        } else if (this.config.kprTilingLayout === 'fill-compact') {
            scale = Kruispuntenroute.BOX_HEIGHT / intermediateDrawing.height;
            transformX = x => base[0] + Kruispuntenroute.BOX_INFO_WIDTH + Kruispuntenroute.BOX_INFO_PADDING + (x - intermediateDrawing.minX) * scale;
            transformY = y => base[1] + (intermediateDrawing.height - (y - intermediateDrawing.minY)) * scale;
        } else {
            throw new Error('Invalid tiling layout');
        }

        const transformXY = (c: [number, number]) => [transformX(c[0]), transformY(c[1])];
        const scaledMeasure = intermediateDrawing.sizeMeasure * scale;

        const debugColors = [
            '#000000',
            '#ff0000',
            '#00ff00',
            '#0000ff',
            '#ffbb00',
            '#ff00ff',
            '#00ffff',
            '#cccccc',
        ];
        let debugColorsCursor = 0;

        const text = createSvgEl('text', {
            x: base[0] + Kruispuntenroute.BOX_INFO_WIDTH / 2,
            y: base[1] + 10,
            textAnchor: 'middle',
            dominantBaseline: 'central',
        });
        text.textContent = intermediateDrawing.intermediateMeta.intermediateNumber + '.';
        text.style.font = '15px sans-serif';
        svg.appendChild(text);

        svg.appendChild(createSvgEl('circle', {
            cx: base[0] + Kruispuntenroute.BOX_INFO_WIDTH / 2,
            cy: base[1] + 10,
            r: 11,
            stroke: '#000000',
            strokeWidth: 1.5,
            fill: 'none',
        }));

        if (this.config.displayNorthArrow) {
            this.drawNorthArrow(svg, base, intermediateDrawing);
        }

        const format = (p: Point) => transformX(p.x) + ' ' + transformY(p.y);

        for (const polyline of intermediateDrawing.drawing.polylines) {
            const segmentStrings = [];

            if (polyline.segments.length > 0) {
                segmentStrings.push('M ' + format(polyline.segments[0].start));
            }

            for (let i = 0; i < polyline.segments.length; i++) {
                const segment = polyline.segments[i];

                if (segment instanceof LineSegment) {
                    segmentStrings.push('L ' + format(segment.end));
                } else if (segment instanceof ArcSegment) {
                    const radius = segment.radius() * scale;
                    const largeArc = segment.centralArcAngle > Math.PI ? 1 : 0;
                    segmentStrings.push('A ' + radius + ' ' + radius + ' 0 ' + largeArc + ' 0 ' + format(segment.end));
                } else {
                    throw new Error();
                }

                if (i < polyline.segments.length - 1 && (
                    segment.end.x !== polyline.segments[i + 1].start.x
                    || segment.end.y !== polyline.segments[i + 1].start.y
                )) {
                    segmentStrings.push('M ' + format(polyline.segments[i + 1].start));
                }
            }

            svg.appendChild(createSvgEl('path', {
                d: segmentStrings.join(' '),
                fill: 'none',
                stroke: Kruispuntenroute.DEBUG ? debugColors[debugColorsCursor++ % debugColors.length] : '#000000',
                strokeWidth: 2.0,
            }));
        }

        // Draw arrow line
        if (this.config.kprDrawIncomingPath || this.config.kprInstruction === 'arrow') {
            let linePoints: olCoordinate[] = [];
            if (this.config.kprDrawIncomingPath) {
                linePoints = linePoints.concat(intermediateDrawing.inRoad);
            }

            linePoints.push(intermediateDrawing.intermediate.getOlCoordinate());

            if (this.config.kprInstruction === 'arrow') {
                linePoints = linePoints.concat(intermediateDrawing.outRoad);
            }

            linePoints = olTrimPath(linePoints, 10);

            const arrowStrings = [];
            linePoints.forEach(c => arrowStrings.push((arrowStrings.length === 0 ? 'M ' : 'L ') + format({x: c[0], y: c[1]})));
            svg.appendChild(createSvgEl('path', {
                d: arrowStrings.join(' '),
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 1.0,
            }));

            if (this.config.kprDrawIncomingPath) {
                svg.appendChild(createSvgEl('circle', {
                    cx: transformX(linePoints[0][0]),
                    cy: transformY(linePoints[0][1]),
                    r: scaledMeasure / 5,
                    stroke: 'none',
                    fill: '#000000',
                }));
            }

            if (this.config.kprInstruction === 'arrow') {
                const arrow = new TipArrow(scaledMeasure / 5, scaledMeasure / 5, scaledMeasure / 5);
                arrow.setAnchor('body-center');
                arrow.drawSvg(svg, transformXY(linePoints[linePoints.length - 2]), transformXY(linePoints[linePoints.length - 1]));
            }
        }

        if (this.config.kprInstruction === 'quiz') {
            let optionNumber = 0;
            for (const roadOption of intermediateDrawing.roadOptions) {
                if (optionNumber >= 26) {
                    throw new UserError('Too many options for quiz');
                }

                const trimmedRoad = olTrimPath([intermediateDrawing.intermediate.getOlCoordinate(), ...roadOption], 10);

                const text = createSvgEl('text', {
                    x: transformX(trimmedRoad[trimmedRoad.length - 1][0]),
                    y: transformY(trimmedRoad[trimmedRoad.length - 1][1]),
                    textAnchor: 'middle',
                    dominantBaseline: 'central',
                });
                text.textContent = String.fromCharCode(65 + optionNumber);
                text.style.font = '15px sans-serif';
                text.style.fontWeight = 'bold';
                svg.appendChild(text);

                optionNumber++;
            }
        }
    }

    private drawNorthArrow(svg: SVGSVGElement, base: [number, number], intermediateDrawing: IntermediateDrawing): void {
        const center = [base[0] + Kruispuntenroute.BOX_INFO_WIDTH / 2, base[1] + 40];
        const length = 20;

        const angle = bearingToRadians(intermediateDrawing.bearingNorth);

        this.drawDoubleArrow(
            svg,
            [center[0] - Math.cos(angle) * length / 2, center[1] + Math.sin(angle) * length / 2],
            [center[0] + Math.cos(angle) * length / 2, center[1] - Math.sin(angle) * length / 2],
            {
                stroke: '#666666',
                strokeWidth: 1,
                fill: 'none',
            },
            {
                arrowWidth: 2,
            }
        );

        const text = createSvgEl('text', {
            x: base[0] + Kruispuntenroute.BOX_INFO_WIDTH / 2,
            y: base[1] + 60,
            textAnchor: 'middle',
            dominantBaseline: 'central',
        });
        text.textContent = 'N';
        text.style.font = '15px sans-serif';
        text.style.fontWeight = 'bold';
        svg.appendChild(text);
    }
}
