import Action from "./Action";
import RouteTechnique from "../RouteTechniques/RouteTechnique";
import {Serialization} from "../Main/Serializer";

export default class UpdateRouteTechniqueOptionAction<Type> implements Action {

    private static readonly options = {
        format: 'format',
        transparent: 'transparent',
        display_scale: 'displayScale',
        use_roughjs: 'useRoughJs',
        roughjs_seed: 'roughJsSeed',
        display_north_arrow: 'displayNorthArrow',
        draw_side_roads: 'drawSideRoads',
        draw_route_line: 'drawRouteLine',
        rotation: 'rotation',
        bolpi_convention: 'bolpiConvention',
        road_width: 'roadWidth',
        kpr_orientation: 'kprOrientation',
        kpr_orientation_seed: 'kprOrientationSeed',
        kpr_instruction: 'kprInstruction',
        kpr_draw_incoming_path: 'kprDrawIncomingPath',
        kpr_snapping_directions: 'kprSnappingDirections',
        kpr_tiling_layout: 'kprTilingLayout',
    };

    private readonly oldValue: Type;

    constructor(
        private routeTechnique: RouteTechnique,
        private key: string,
        private newValue: Type
    ) {
        this.newValue = <Type>(this.key === 'roughjs_seed' || this.key === 'rotation' ? +this.newValue : this.newValue);
        this.oldValue = <Type>this.routeTechnique.getConfig()[UpdateRouteTechniqueOptionAction.options[key]];
    }

    public apply() {
        this.routeTechnique.setConfig(UpdateRouteTechniqueOptionAction.options[this.key], this.newValue);
    }

    public revert() {
        this.routeTechnique.setConfig(UpdateRouteTechniqueOptionAction.options[this.key], this.oldValue);
    }

    public merge(newAction: Action): boolean {
        if (!['roughjs_seed', 'rotation', 'kpr_orientation_seed', 'road_width'].includes(this.key)) {
            return false;
        }

        if(
            !(newAction instanceof UpdateRouteTechniqueOptionAction && newAction.key === this.key)
            || newAction.routeTechnique !== this.routeTechnique
            || newAction.oldValue !== this.newValue
        ) {
            return false;
        }

        if (['road_width'].includes(this.key) && (this.newValue === null || this.oldValue === null || newAction.newValue === null || newAction.oldValue === null)) {
            return false;
        }

        if((this.newValue > this.oldValue) !== (newAction.newValue > newAction.oldValue)) {
            return false;
        }

        this.newValue = <Type> +newAction.newValue;
        this.apply();

        return true;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.routeTechnique.route.id,
            key: this.key,
            oldValue: this.oldValue,
            newValue: this.newValue,
        };
    }
}
