import Coordinate from "../Coordinates/Coordinate";
import Paper, {millimeter} from "../Util/Paper";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import OLConvertibleCoordinate from "../Coordinates/OLConvertibleCoordinate";
import OLConvertibleCoordinateSystem from "../Coordinates/OLConvertibleCoordinateSystem";
import Projection from "../Projection/Projection";
import Grid from "./Grid";
import MapImageProvider from "../Projection/MapImageProvider";
import {copyObject} from "../Util/functions";

export type CutoutVariant = 'normal' | 'flexagon';
export type FlexagonVariant = 'hexatetraflexagon';
export type FlexagonAssignmentVersion = {title: string, description: string};

export const FlexagonAssignmentVersions: Record<FlexagonVariant, Record<string, FlexagonAssignmentVersion>> = {
    'hexatetraflexagon' : {
        'htf_1b3p2s': {title: 'Niveau 1: Eenzijdig', description: 'Alle kaarten zijn te vinden zonder de flexagon te hoeven omdraaien'},
        'htf_2b2s': {title: 'Niveau 1: Cadans', description: 'Basispositie toont 2 middelste kaarten, twee dubbelvoudige acties leiden tot overige 4 kaarten'},
        'htf_2b4p': {title: 'Niveau 2: Compact', description: 'Basispositie toont 2 middelste kaarten, vier enkelvoudige acties leiden tot overige 4 kaarten'},
        'htf_6p': {title: 'Niveau 2: Enkelvoudig', description: 'Alle 6 kaarten zijn bereikbaar middels enkelvoudige acties vanaf de basispositie'},
        'htf_2p4s': {title: 'Niveau 3: Ingewikkeld', description: 'Kaarten zijn verdeeld over alle staten'},
        'htf_4p2s': {title: 'Niveau 3: Verwarrend', description: 'In elke staat behalve de beginpositie toont exact 1 zijde een geldige kaart'},
    },
};

export type FlexagonOptions = {
    width: millimeter | null, // width of the folded flexagon. For hexatetraflexagon: *2 for printed width, /2 for tile width
    height: millimeter | null, // height of the folded flexagon. For hexatetraflexagon: *2 for printed height, /2 for tile height
    orientation: 'vertical' | 'horizontal',
    section_overlap: millimeter,
    assignment_version: string,
};

export type CutoutOptions = {
    margin_top_printable: millimeter,
    margin_right_printable: millimeter,
    margin_bottom_printable: millimeter,
    margin_left_printable: millimeter,
    margin_top_nonprintable: millimeter,
    margin_right_nonprintable: millimeter,
    margin_bottom_nonprintable: millimeter,
    margin_left_nonprintable: millimeter,
    draw_grid: boolean,
    draw_routes: boolean,
    draw_route_intermediates: boolean,
    draw_locations: boolean,
    display_coords_top: boolean,
    display_coords_right: boolean,
    display_coords_bottom: boolean,
    display_coords_left: boolean,
    display_name: boolean,
    display_scale: boolean,
    rotate_y_coords: boolean,
    variant: CutoutVariant,
    flexagon_options: FlexagonOptions|null,
    advanced_settings_mode: boolean,
};

export default abstract class AbstractCutout<
    WorkspaceCoordinate extends Coordinate & OLConvertibleCoordinate,
    ProjectionCoordinate extends Coordinate,
    WorkspaceCoordinateSystem extends CoordinateSystem<WorkspaceCoordinate> & OLConvertibleCoordinateSystem<WorkspaceCoordinate>
> {
    static idIncrement: number = 0;

    readonly id: number;
    options: CutoutOptions;

    anchorWorkspaceCoordinate: WorkspaceCoordinate;

    static defaultCutoutOptions: CutoutOptions = {
        margin_top_printable: 6,
        margin_right_printable: 6,
        margin_bottom_printable: 6,
        margin_left_printable: 6,
        margin_top_nonprintable: 6,
        margin_right_nonprintable: 6,
        margin_bottom_nonprintable: 6,
        margin_left_nonprintable: 6,
        draw_grid: true,
        draw_routes: true,
        draw_route_intermediates: true,
        draw_locations: true,
        display_coords_top: true,
        display_coords_right: true,
        display_coords_bottom: true,
        display_coords_left: true,
        display_name: true,
        display_scale: true,
        rotate_y_coords: false,
        variant: 'normal',
        flexagon_options: null,
        advanced_settings_mode: false,
    };

    static defaultFlexagonOptions: FlexagonOptions = {
        width: null,
        height: null,
        orientation: 'horizontal',
        section_overlap: 5,
        assignment_version: 'htf_6p',
    };

    constructor(
        protected paper: Paper,
        anchorWorkspace: WorkspaceCoordinate,
        readonly workspaceCoordinateSystem: WorkspaceCoordinateSystem,
        protected projection: Projection<ProjectionCoordinate, MapImageProvider>,
        protected grid: Grid<Coordinate> = null,
        public name: string = null,
    ) {
        this.id = AbstractCutout.idIncrement++;
        this.options = copyObject(AbstractCutout.defaultCutoutOptions);

        if(this.grid === null) {
            this.grid = new Grid(this.projection.getMapImageProvider().getDefaultCoordinateSystem());
        }

        this.setAnchorWorkspaceCoordinate(anchorWorkspace);
    }

    getName(): string {
        return this.name;
    }

    getPaper(): Paper {
        return this.paper;
    }

    getProjection(): Projection<ProjectionCoordinate, MapImageProvider> {
        return this.projection;
    }

    setProjection(projection: Projection<ProjectionCoordinate, MapImageProvider>) {
        this.projection = projection;
        this.projection.setAnchor(this.anchorWorkspaceCoordinate);
    }

    setGrid(grid: Grid<Coordinate>): void {
        this.grid = grid;
    }

    getGrid(): Grid<Coordinate> {
        return this.grid;
    }

    setAnchorWorkspaceCoordinate(c: WorkspaceCoordinate) {
        this.anchorWorkspaceCoordinate = c;
        this.projection.setAnchor(this.anchorWorkspaceCoordinate);
    }
}
