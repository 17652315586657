import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import UserError from "../Util/UserError";
import {Options} from "roughjs/bin/core";
import {randomInt} from "../Util/Math";
import {createSvgEl} from "./Util/Svg";

type VliegkoersConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
};

export default class Vliegkoers extends ImageGeneratingTechnique<VliegkoersConfig>
{
    public static readonly TECHNIQUE_NAME = 'vliegkoers';
    public static readonly TECHNIQUE_TITLE = 'Vliegkoers';
    public static readonly TECHNIQUE_WIKI = 'vliegkoers';

    protected readonly requiresIntermediates = true;

    protected getDefaultConfig(): VliegkoersConfig {
        return {
            format: 'png',
            transparent: false,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 0.5,
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const intermediatesMeta = this.getIntermediatesMeta();

        const lineHeight = 10; // mm

        const lines = intermediatesMeta.intermediateMetas.length;

        const height = lines * lineHeight + 20;
        const width = 50;
        const centerX = width / 2;

        const svg = createSvgEl('svg', {
            width: width,
            height: height,
            viewBox: "0 0 " + width + " " + height,
        });

        svg.appendChild(createSvgEl('line', {
            x1: centerX,
            y1: height - 5,
            x2: centerX,
            y2: 5,
            stroke: '#000000',
            strokeWidth: 0.5,
        }));

        svg.appendChild(createSvgEl('circle', {
            cx: centerX,
            cy: height - 5,
            r: 1,
            stroke: 'none',
            fill: '#000000',
        }));

        svg.appendChild(createSvgEl('line', {
            x1: centerX - 15,
            y1: 5,
            x2: centerX + 15,
            y2: 5,
            stroke: '#000000',
            strokeWidth: 0.5,
        }));

        for (let i = 0; i < intermediatesMeta.intermediateMetas.length; i++) {
            const intermediate = intermediatesMeta.intermediateMetas[i];

            if (intermediate.directBearingFormatted === null) {
                throw new UserError('Het laatste beslispunt in de route mag voor deze routetechniek niet aan het einde van de route liggen.');
            }

            const baseY = height - 15 - i * lineHeight;

            svg.appendChild(createSvgEl('line', {
                x1: centerX - 6,
                y1: baseY,
                x2: centerX + 6,
                y2: baseY,
                stroke: '#000000',
                strokeWidth: 0.5,
            }));

            const distanceText = createSvgEl('text', {
                x: centerX + 9,
                y: baseY,
                textAnchor: 'start',
                dominantBaseline: 'central',
            });
            distanceText.textContent = intermediate.directDistanceFormatted;
            distanceText.style.font = '4px sans-serif';
            svg.appendChild(distanceText);

            const bearingText = createSvgEl('text', {
                x: centerX - 9,
                y: baseY,
                textAnchor: 'end',
                dominantBaseline: 'central',
            });
            bearingText.textContent = intermediate.directBearingFormatted;
            bearingText.style.font = '4px sans-serif';
            svg.appendChild(bearingText);
        }

        return svg;
    }
}
