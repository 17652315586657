import OlMap from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';

import Cutout from "./Cutout";
import Coordinate from "../Coordinates/Coordinate";
import OLConvertibleCoordinate from "../Coordinates/OLConvertibleCoordinate";
import WGS84, {WGS84System} from "../Coordinates/WGS84";
import {fromLonLat} from "ol/proj";
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import * as olExtent from 'ol/extent';
import {DragPan} from "ol/interaction";
import {altKeyOnly, platformModifierKeyOnly} from "ol/events/condition";
import Feature from "ol/Feature";
import {Layer} from "ol/layer";
import Route from "./Route";
import {Circle, Fill, Icon, Stroke, Style} from "ol/style";
import mapMarker from "../img/marker.svg";
import locationCircle from "../img/location-circle.svg";
import locationCross from "../img/location-cross.svg";
import locationTriangle from "../img/location-triangle.svg";
import {Size} from "ol/size";
import UserInterface from "./UserInterface";
import Location from "./Location";
import UserError from "../Util/UserError";
import CoordinateConverter from "../Util/CoordinateConverter";
import {Extent} from "ol/extent";
import Container from "./Container";
import OlTileLoader from "../Util/OlTileLoader";
import {isProxy, toRaw} from "vue";
import {Control} from "ol/control";
import {Geolocation} from "ol";
import {Point} from "ol/geom";
import CircleStyle from "ol/style/Circle";
import {isIOS} from "../Util/functions";

export enum MarkerType {
    MapMarker = "map_marker",
    LocationCircle = "location_circle",
    LocationCross = "location_cross",
    LocationTriangle = "location_triangle",
}

export function getMarkerImage(markerType: MarkerType): string {
    if (markerType === MarkerType.LocationCircle) {
        return locationCircle;
    } else if (markerType === MarkerType.LocationCross) {
        return locationCross;
    } else if (markerType === MarkerType.LocationTriangle) {
        return locationTriangle;
    } else {
        return mapMarker;
    }
}

export function getMarkerTextOffset(markerType: MarkerType): [number, number] {
    if (markerType === MarkerType.LocationCircle || markerType === MarkerType.LocationCross) {
        return [11, -8];
    } else if (markerType === MarkerType.LocationTriangle) {
        return [13, -10];
    } else {
        return [16, -40];
    }
}

export default class Map {

    static readonly coordinatePanelMapMarkerColor = '#445577';

    private openlayersMap: OlMap;
    private openlayersVectorSource: VectorSource<any>;
    private openlayersVectorLayer: VectorLayer<any>;

    private markerSvgTextPromises: Record<any, Promise<string>> = {};
    private markerSvgText: string = null;
    private coloredMarkerImageCache: Record<string, [HTMLImageElement, Size]> = {};

    private hasMouseOverLocation_: boolean = false;

    private geolocation: Geolocation;
    private geolocationPromise: Promise<Geolocation>;
    private geolocatorUnsetter: (() => void)|null;

    constructor(readonly userInterface: UserInterface, private id: string) {

        this.openlayersVectorSource = new VectorSource({});

        this.openlayersVectorLayer = new VectorLayer({
            source: this.openlayersVectorSource,
            style: function(feature, resolution) {
                if (feature.get('custom_style')) {
                    return feature.get('custom_style')();
                }
                return null;
            },
        });

        const osm = new OSM();
        osm.setAttributions('&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors - <a href="https://www.openstreetmap.org/fixthemap" target="_blank">Fix the map</a>');
        Container.getCache().then((cache) => {
            const tileLoader = new OlTileLoader(cache);
            tileLoader.setUseFallback(true);
            osm.setTileLoadFunction(tileLoader.getTileLoadFunction());
        });

        this.openlayersMap = new OlMap({
            layers: [
                new TileLayer({source: osm}),
                this.openlayersVectorLayer,
            ],
            view: new View({
                center: fromLonLat([5.0, 52.1]),
                zoom: 8,
                constrainResolution: true,
            }),
            target: id
        });

        const locate = document.createElement('div');
        locate.className = 'ol-control ol-unselectable ol-locate-buttons';
        locate.innerHTML = '<button title="Stoppen" class="ol-locate-stop">&times;</button><button title="Mijn locatie" class="ol-locate-start"><div>&#9678;</div></button>';
        locate.querySelector('.ol-locate-start').addEventListener('click', () => {
            this.startGeolocating();
        });
        locate.querySelector('.ol-locate-stop').addEventListener('click', () => {
            this.stopGeolocating();
        });
        this.openlayersMap.addControl(
            new Control({
                element: locate,
            })
        );

        // Hack to allow cutout alt-key behaviour
        this.openlayersMap.getInteractions().forEach(function(interaction) {
            if (interaction instanceof DragPan) {
                // @ts-ignore
                const origCondition = interaction.condition_;
                // @ts-ignore
                interaction.condition_ = function(mapBrowserEvent: MapBrowserEvent) : boolean {
                    return origCondition(mapBrowserEvent) || altKeyOnly(mapBrowserEvent);
                }
            }
        });

        const mouseOverCutouts = [];
        let mouseOverRoute: Route = null;
        let mouseOverLocation: Location = null;
        const mouseOverPixel = (pixel, forceChange = false, altKeyOnly = false) => {
            let cutout = null;
            if (
                !this.userInterface.isLocked()
                && this.userInterface.getRouteCollection().hasFocusedRoute() === altKeyOnly
            ) {
                cutout = this.cutoutsAtPixel(pixel)[0];
            }

            let changed = forceChange;
            if(cutout && mouseOverCutouts.indexOf(cutout) === -1) {
                cutout.mouseover(false);
                document.body.style.cursor = 'grab';
                mouseOverCutouts.push(cutout);
                changed = true;
            }

            for(let i = mouseOverCutouts.length - 1; i >= 0; i--) {
                const mouseOverCutout = mouseOverCutouts[i];
                if(mouseOverCutout !== cutout) {
                    mouseOverCutout.mouseout(false);
                    changed = true;
                    mouseOverCutouts.splice(i, 1);
                }
            }

            let newMouseOverLocationFeature = null;
            if (this.userInterface.getRouteCollection().getRouTechSelectedRoute()?.getEditMode() !== 'intermediate') {
                newMouseOverLocationFeature = this.openlayersMap.forEachFeatureAtPixel(pixel, function (feature, layer) {
                    if (feature && feature.get('location')) {
                        return feature;
                    }
                }, {
                    layerFilter: (layer) => {
                        if (this.userInterface.isLocked()) {
                            return false;
                        }

                        let mapLayer = this.userInterface.getLocationCollection().mapLayer;
                        mapLayer = isProxy(mapLayer) ? toRaw(mapLayer) : mapLayer;
                        return layer == mapLayer;
                    }
                });
            }

            if(mouseOverLocation && (!newMouseOverLocationFeature || mouseOverLocation !== newMouseOverLocationFeature.get('location'))) {
                mouseOverLocation.mouseout();
                mouseOverLocation = null;
                this.hasMouseOverLocation_ = false;
            }

            if(newMouseOverLocationFeature && mouseOverLocation !== newMouseOverLocationFeature.get('location')) {
                const location: Location = newMouseOverLocationFeature.get('location');
                location.mouseover();
                document.body.style.cursor = 'grab';
                mouseOverLocation = location;
                this.hasMouseOverLocation_ = true;
            }

            const newMouseOverRouteFeature = this.openlayersMap.forEachFeatureAtPixel(pixel, function (feature, layer) {
                if (feature && feature.get('route')) {
                    return feature;
                }
            }, {
                layerFilter: (layer) => {
                    if (this.userInterface.isLocked()) {
                        return false;
                    }

                    let mainLayer = this.userInterface.getRouteCollection().mainLayer;
                    mainLayer = isProxy(mainLayer) ? toRaw(mainLayer) : mainLayer;
                    return layer == mainLayer;
                }
            });

            if(mouseOverRoute && (!newMouseOverRouteFeature || mouseOverRoute !== newMouseOverRouteFeature.get('route'))) {
                mouseOverRoute.mouseout();
                mouseOverRoute = null;
            }

            if(newMouseOverRouteFeature && mouseOverRoute !== newMouseOverRouteFeature.get('route')) {
                const route: Route = newMouseOverRouteFeature.get('route');
                route.mouseover();
                document.body.style.cursor = 'pointer';
                mouseOverRoute = route;
            }

            if(mouseOverCutouts.length === 0 && mouseOverLocation === null && mouseOverRoute === null) {
                document.body.style.cursor = 'default';
            }

            if(changed) {
                this.openlayersVectorLayer.changed();
            }
        };

        this.openlayersMap.on('pointermove', (e) => {
            if(e.dragging) {
                return;
            }

            mouseOverPixel(e.pixel, false, altKeyOnly(e));
        });

        const ctrlClickCutoutStatus = {
            pixel: null,
            zIndex: null,
            reset: false,
        };
        this.openlayersMap.on('singleclick', (e) => {
            if (platformModifierKeyOnly(e)) {
                // ctrl+click on cutout = change cutout zIndex ordering

                if (
                    ctrlClickCutoutStatus.pixel === null
                    || (e.pixel[0] !== ctrlClickCutoutStatus.pixel[0] && e.pixel[1] !== ctrlClickCutoutStatus.pixel[1])
                ) {
                    ctrlClickCutoutStatus.reset = true;
                }

                const cutoutsAtPixel = this.cutoutsAtPixel(e.pixel);

                if (cutoutsAtPixel.length === 0) {
                    return;
                }

                e.stopPropagation();

                let cutout = null;
                if (cutoutsAtPixel.length === 1) {
                    cutout = cutoutsAtPixel[0];
                } else {
                    if (ctrlClickCutoutStatus.reset) {
                        ctrlClickCutoutStatus.pixel = e.pixel;
                        ctrlClickCutoutStatus.zIndex = cutoutsAtPixel.shift().getZIndex();
                        ctrlClickCutoutStatus.reset = false;
                    }

                    for (const cutoutAtPixel of cutoutsAtPixel) {
                        if (cutoutAtPixel.getZIndex() < ctrlClickCutoutStatus.zIndex) {
                            cutout = cutoutAtPixel;
                            break;
                        }
                    }

                }

                if (!cutout || cutout === cutoutsAtPixel[cutoutsAtPixel.length - 1]) {
                    ctrlClickCutoutStatus.reset = true;

                    if (!cutout) {
                        return;
                    }
                }

                cutout.moveToFront();

                setTimeout(() => {
                    mouseOverPixel(e.pixel, cutoutsAtPixel.length === 1);
                });
            }
        });

        this.openlayersMap.getViewport().addEventListener('contextmenu', (evt) => {
            evt.preventDefault();

            const feature = this.openlayersMap.forEachFeatureAtPixel(
                this.openlayersMap.getEventPixel(evt),
                function (feature, layer) {
                    if (feature && feature.get('cutout')) {
                        return feature;
                    }
                }
            );
            if (feature && feature.get('cutout')) {
                feature.get('cutout').openWorkspaceDropdownMenu(evt);
            } else if (!this.userInterface.isLocked()) {
                this.userInterface.openGeneralDropdownMenu(evt);
            }
        });
    }

    getOpenlayersVectorSource(): VectorSource<any> {
        return this.openlayersVectorSource;
    }

    getOpenlayersVectorLayer(): VectorLayer<any> {
        return this.openlayersVectorLayer;
    }
    getOpenlayersMap(): OlMap {
        return this.openlayersMap;
    }

    hasMouseOverLocation(): boolean {
        return this.hasMouseOverLocation_;
    }

    getCenter(): WGS84 {
        return (new WGS84System()).fromOpenLayersCoordinate(this.openlayersMap.getView().getCenter());
    }

    getBoundingPolygon(): WGS84[] {
        const extend = this.openlayersMap.getView().calculateExtent(this.openlayersMap.getSize());

        const wgs84System = new WGS84System();

        return <WGS84[]>[
            wgs84System.fromOpenLayersCoordinate(olExtent.getBottomLeft(extend)),
            wgs84System.fromOpenLayersCoordinate(olExtent.getBottomRight(extend)),
            wgs84System.fromOpenLayersCoordinate(olExtent.getTopRight(extend)),
            wgs84System.fromOpenLayersCoordinate(olExtent.getTopLeft(extend)),
        ];
    }

    fitTo(cutouts: Cutout<Coordinate & OLConvertibleCoordinate, any, any>[], routes: Route[], locations: Location[]): void {
        routes = routes.filter(route => route.getGeometry().getCoordinates().length > 0);

        if(cutouts.length === 0 && routes.length === 0 && locations.length === 0) {
            return;
        }

        let extent = olExtent.createEmpty();

        if (cutouts.length > 0) {
            let minLat = null, maxLat = null, minLng = null, maxLng = null;

            for(const cutout of cutouts) {
                for(const wsCoordinate of cutout.mapPolygonWorkspace) {
                    if(minLat === null || wsCoordinate.getY() < minLat) {
                        minLat = wsCoordinate.getY();
                    }
                    if(maxLat === null || wsCoordinate.getY() > maxLat) {
                        maxLat = wsCoordinate.getY();
                    }

                    if(minLng === null || wsCoordinate.getX() < minLng) {
                        minLng = wsCoordinate.getX();
                    }
                    if(maxLng === null || wsCoordinate.getX() > maxLng) {
                        maxLng = wsCoordinate.getX();
                    }
                }
            }

            const lowerLeft = fromLonLat([minLng, minLat]);
            const upperRight = fromLonLat([maxLng, maxLat]);

            olExtent.extend(extent, [lowerLeft[0], lowerLeft[1], upperRight[0], upperRight[1]]);
        }

        for (const route of routes) {
            olExtent.extend(extent, route.getGeometry().getExtent());
        }

        for (const location of locations) {
            olExtent.extend(extent, location.getGeometry().getExtent());
        }

        this.openlayersMap.getView().fit(extent, {
            padding: [50, 50, 50, 50],
            maxZoom: 20,
        });
    }

    fitToCoordinate(coordinate: Coordinate): void {
        const olConvCoord = <OLConvertibleCoordinate>CoordinateConverter
            .convert(coordinate, CoordinateConverter.getCoordinateSystem('EPSG:4326'));

        const extent = olExtent.createEmpty();

        olExtent.extendCoordinate(extent, olConvCoord.toOpenLayersCoordinate());

        this.openlayersMap.getView().fit(extent, {
            padding: [50, 50, 50, 50],
            maxZoom: 20,
        });
    }

    fitToExtent(extent: Extent): void {
        this.openlayersMap.getView().fit(extent, {
            padding: [50, 50, 50, 50],
            maxZoom: 20,
        });
    }

    /**
     * Call callback for each cutout under pixel, in descending order of cutout.zIndex. callback will
     * be called until it returns a truthy value for a cutout, in which case the function will return
     * this value. In other cases, it returns undefined
     * @param pixel
     * @param callback
     */
    forEachCutoutAtPixel<T>(pixel, callback: (cutout: Cutout<any, any, any>, feature: Feature<any>, layer: Layer<any>) => T): (T|unknown) {
        const features = [];

        this.openlayersMap.forEachFeatureAtPixel(pixel, function (feature, layer) {
            if (feature && feature.get('cutout')) {
                features.push([feature, layer]);
            }
        }, {
            layerFilter: (layer) => {
                return layer == this.openlayersVectorLayer;
            }
        });

        features.sort(([featureA, layerA], [featureB, layerB]) => featureB.get('cutout').zIndex - featureA.get('cutout').zIndex);

        for (const featureArray of features) {
            let result = callback(featureArray[0].get('cutout'), featureArray[0], featureArray[1]);
            if (result) {
                return result;
            }
        }
    }

    cutoutsAtPixel(pixel): Cutout<any, any, any>[] {
        const cutouts: Cutout<any, any, any>[] = [];
        this.forEachCutoutAtPixel(pixel, function (cutout) {
            cutouts.push(cutout);
        });
        return cutouts;
    }

    geolocate(): Promise<Geolocation> {
        // https://openlayers.org/en/latest/examples/geolocation.html
        // https://openlayers.org/workshop/en/mobile/geolocation.html

        if (!this.geolocation) {
            this.geolocation = new Geolocation({
                // enableHighAccuracy must be set to true to have the heading value.
                trackingOptions: {
                    enableHighAccuracy: true,
                },
                tracking: true,
                projection: this.openlayersMap.getView().getProjection(),
            });

            this.geolocationPromise = new Promise((resolve, reject) => {
                let handled = false;
                this.geolocation.once('change:position', () => {
                    if (handled) {
                        return;
                    }

                    handled = true;
                    resolve(this.geolocation);
                });

                this.geolocation.on('error', (error) => {
                    if (handled) {
                        return;
                    }

                    let additionalInfo = '';
                    if (isIOS()) {
                        additionalInfo = ' - Als je een iPhone gebruikt, zorg er dan voor dat Safari locatie gegevens mag gebruiken onder Instellingen > Privacy > Safari'
                    }

                    handled = true;
                    alert(error.message + additionalInfo);
                    this.geolocationPromise = null;
                    this.geolocation = null;
                    reject(error);
                });
            });
        }

        return this.geolocationPromise;
    }

    startGeolocating(): void {
        let loaded = false;
        setTimeout(() => {
            if (!loaded) {
                document.querySelector('.ol-locate-start > div').classList.add('animate-size');
            }
        }, 100);

        const loadedFn = () => {
            loaded = true;
            document.querySelector('.ol-locate-start > div').classList.remove('animate-size');
        };

        this.geolocate().then((geolocation) => {
            loadedFn();

            if (!this.geolocatorUnsetter) {
                let geomCallback, posCallback;

                const accuracyFeature = new Feature();
                geolocation.on('change:accuracyGeometry', geomCallback = () => {
                    accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
                });
                accuracyFeature.setStyle(new Style({
                    image: new Circle({
                        fill: new Fill({
                            color: 'rgba(255,255,255,0.4)',
                        }),
                        stroke: new Stroke({
                            color: '#3399CC',
                            width: 1.25,
                        }),
                        radius: 5,
                    }),
                    fill: new Fill({
                        color: 'rgba(255,255,255,0.4)',
                    }),
                    stroke: new Stroke({
                        color: 'rgb(51,153,204,0.6)',
                        width: 1.25,
                    }),
                }));

                const positionFeature = new Feature();
                positionFeature.setStyle(
                    new Style({
                        image: new CircleStyle({
                            radius: 6,
                            fill: new Fill({
                                color: '#3399CC',
                            }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 3,
                            }),
                        }),
                    })
                );

                geolocation.on('change:position', posCallback = () => {
                    const coordinates = geolocation.getPosition();
                    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
                });

                geomCallback();
                posCallback();

                this.openlayersVectorSource.addFeature(accuracyFeature);
                this.openlayersVectorSource.addFeature(positionFeature);
                document.querySelector('.ol-locate-buttons').classList.add('ol-locate-active');

                this.geolocatorUnsetter = () => {
                    geolocation.un('change:accuracyGeometry', geomCallback);
                    geolocation.un('change:position', posCallback);
                    this.openlayersVectorSource.removeFeature(accuracyFeature);
                    this.openlayersVectorSource.removeFeature(positionFeature);
                    document.querySelector('.ol-locate-buttons').classList.remove('ol-locate-active');
                };
            }

            this.openlayersMap.getView().fit(geolocation.getAccuracyGeometry(), {
                maxZoom: 18,
                duration: 500,
            });
        }).catch(loadedFn);
    }

    stopGeolocating(): void {
        if (this.geolocatorUnsetter) {
            this.geolocatorUnsetter();
            this.geolocatorUnsetter = null;
        }
    }

    markerDownloaded() {
        return Object.keys(this.coloredMarkerImageCache).length > 0;
    }

    private getMarkerSvgTextPromise(markerType: MarkerType): Promise<string> {
        if (typeof this.markerSvgTextPromises[markerType] === 'undefined') {
            this.markerSvgTextPromises[markerType] = new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', getMarkerImage(markerType), true);
                xhr.responseType = 'blob';
                xhr.onload = (e) => {
                    const blob = xhr.response;

                    const fr = new FileReader();
                    fr.onload = (e) => {
                        // @ts-ignore
                        this.markerSvgText = fr.result;
                        // @ts-ignore
                        resolve(fr.result);
                    };
                    fr.readAsText(blob);
                };
                xhr.send(null);
            });
        }

        return this.markerSvgTextPromises[markerType];
    }

    getImmediateColoredMarkerImage(markerType: MarkerType, color: string = null, highlight: boolean = false): [HTMLImageElement, Size] {
        const cacheKey = ['markerType_' + markerType, color === null ? 'null' : 'color_' + color, highlight ? 'hl_true' : 'hl_false'].join('_');
        if (this.coloredMarkerImageCache[cacheKey] !== undefined) {
            return this.coloredMarkerImageCache[cacheKey];
        }

        throw new UserError('Er is iets misgegaan bij intekenen van punten');
    }

    getColoredMarkerImage(markerType: MarkerType, color: string = null, highlight: boolean = false, removeHighlight: boolean = false): Promise<[HTMLImageElement, Size]> {
        const cacheKey = ['markerType_' + markerType, color === null ? 'null' : 'color_' + color, highlight ? 'hl_true' : 'hl_false'].join('_');
        if (this.coloredMarkerImageCache[cacheKey] !== undefined) {
            return Promise.resolve(this.coloredMarkerImageCache[cacheKey]);
        }

        return this.mustUseAlternativeSvgDraw().then((mustUseCanvg: boolean) => {
            return this.retrieveColoredMarkerImage(markerType, color, highlight, mustUseCanvg, removeHighlight).then(([img, markerSize]) => {
                return this.coloredMarkerImageCache[cacheKey] = [img, markerSize];
            });
        });
    }

    private retrieveColoredMarkerImage(markerType: MarkerType, color: string|null, highlight: boolean, useCanvg: boolean, removeHighlight: boolean = false) : Promise<[HTMLImageElement, Size]>
    {
        return this.getMarkerSvgTextPromise(markerType).then((svgText) => {
            return new Promise<[HTMLImageElement, Size]>((resolve, reject) => {
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');

                const svgTag = svgDoc.documentElement;
                const markerSize = [parseFloat(svgTag.getAttribute('width')), parseFloat(svgTag.getAttribute('height'))];

                if (markerType === MarkerType.LocationCircle) {
                    const mainCircle = svgDoc.getElementById('circle854');
                    const highlightCircle = svgDoc.getElementById('circle844');

                    if (color !== null) {
                        mainCircle.style.stroke = color;
                        highlightCircle.style.stroke = color;
                    }

                    if (highlight) {
                        highlightCircle.style.strokeWidth = (0.6 * parseFloat(mainCircle.style.strokeWidth)) + 'px';
                        highlightCircle.style.stroke = '#ffffff';
                        highlightCircle.style.strokeOpacity = '0.5';
                        mainCircle.style.strokeWidth = (2 * parseFloat(mainCircle.style.strokeWidth)) + 'px';
                    }
                } else if (markerType === MarkerType.LocationCross) {
                    const cross = svgDoc.getElementById('rect850');
                    const innerCross = svgDoc.getElementById('rect842');

                    if (color !== null) {
                        cross.style.fill = color;
                        innerCross.style.fill = color;
                    }

                    if (highlight) {
                        cross.style.stroke = color;
                        cross.style.strokeWidth = '2px';

                        innerCross.style.display = 'inline';
                        svgDoc.getElementById('path925').style.display = 'inline';
                        innerCross.style.fill = '#ffffff';
                        innerCross.style.fillOpacity = '0.4';
                    }

                } else if (markerType === MarkerType.LocationTriangle) {
                    const main = svgDoc.getElementById('path848');
                    const highlightLarge = svgDoc.getElementById('path872');
                    const highlightInner = svgDoc.getElementById('path876');

                    if (color !== null) {
                        main.style.fill = color;
                    }

                    if (highlight) {
                        highlightLarge.style.display = 'inline';
                        highlightInner.style.display = 'inline';

                        highlightLarge.style.fill = color;

                        highlightInner.style.fill = '#ffffff';
                        highlightInner.style.fillOpacity = '0.4';

                        svgDoc.getElementById('path875').style.display = 'inline';
                        svgDoc.getElementById('path867').style.display = 'inline';
                    }

                } else {
                    if (color !== null) {
                        const path = svgDoc.getElementById('path1424');
                        path.style.fill = color;
                    }

                    if (highlight) {
                        const circle = svgDoc.getElementById('circle889');
                        circle.style.fill = '#999999';
                    } else if (removeHighlight) {
                        for (const element of ['circle889', 'circle847', 'path2035', 'circle899']) {
                            const circle = svgDoc.getElementById(element);
                            circle.parentNode.removeChild(circle);
                        }
                    }
                }

                const newSvgText = new XMLSerializer().serializeToString(svgDoc);

                if (useCanvg) {
                    this.makeMarkerHtmlImageElementUsingCanvg(newSvgText, markerSize).then((img) => {
                        resolve([img, markerSize]);
                    });
                } else {
                    const blob = new Blob([newSvgText], {type: 'image/svg+xml'});
                    const objectUrl = URL.createObjectURL(blob);
                    let img = document.createElement('img');
                    img.src = objectUrl;
                    img.addEventListener('load', () => {
                        URL.revokeObjectURL(objectUrl);
                        resolve([img, markerSize]);
                    }, {once: true});
                }
            });
        });
    }

    private mustUseAlternativeSvgDraw_: boolean = null;
    private mustUseAlternativeSvgDraw(): Promise<boolean> {
        if (this.mustUseAlternativeSvgDraw_ !== null) {
            return Promise.resolve(this.mustUseAlternativeSvgDraw_);
        }

        return this.retrieveColoredMarkerImage(MarkerType.MapMarker, '#ff0000', false, false).then(([img, markerSize]) => {
            return new Promise<boolean>((resolve, reject) => {
                const canvas = document.createElement('canvas');
                canvas.width = markerSize[0];
                canvas.height = markerSize[1];
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const data = ctx.getImageData(Math.floor(markerSize[0]/2), Math.floor(markerSize[1]/2), 1, 1).data;
                const [r, g, b] = [data[0], data[1], data[2]];

                // If r === 0 in the center of our #ff0000 red image, the color has not been correctly drawn
                // (on e.g. old Android phones). Therefore, when then return true to use the alternative method
                resolve(this.mustUseAlternativeSvgDraw_ = (r === 0));
            });
        })
    }

    private makeMarkerHtmlImageElementUsingCanvg(svgText: string, markerSize: Size): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const canvas = document.createElement('canvas');
            canvas.width = markerSize[0];
            canvas.height = markerSize[1];
            const ctx = canvas.getContext('2d');

            import('canvg').then(({Canvg: Canvg}) => {
                const v = Canvg.fromString(ctx, svgText);
                v.start();
                v.ready().then(() => {
                    const objectUrl = canvas.toDataURL('image/png');

                    v.stop();

                    let img = document.createElement('img');
                    img.src = objectUrl;
                    img.addEventListener('load', () => {
                        resolve(img);
                    }, {once: true});
                });
            });
        });
    }

    getMapMarker(markerType: MarkerType, color: string = null, highlight: boolean = false) {
        return this.getColoredMarkerImage(markerType, color, highlight).then(([img, markerSize]) => {
            let anchor;
            if (markerType === MarkerType.LocationCircle || markerType === MarkerType.LocationCross) {
                anchor = [markerSize[0] / 2, markerSize[1] / 2];
            } else {
                anchor = [markerSize[0] / 2, markerSize[1]];
            }

            return new Icon({
                anchor: anchor,
                anchorXUnits: 'pixels',
                anchorYUnits: 'pixels',
                scale : 1,
                opacity: 1,
                img: img,
            });
        });
    }

    getCoordinatePanelMarker() {
        return this.getColoredMarkerImage(MarkerType.MapMarker, Map.coordinatePanelMapMarkerColor, null, true)
            .then(([img, markerSize]) => {
                return new Icon({
                    anchor: [markerSize[0] / 2, markerSize[1]],
                    anchorXUnits: 'pixels',
                    anchorYUnits: 'pixels',
                    scale : 0.75,
                    opacity: 1,
                    img: img,
                });
            });
    }
}
